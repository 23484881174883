.winter-hero {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #d9e7f5;
    position: relative;
    margin-bottom: ($grid * 1.5);

    &__overlay-image {
        display: none;

        @include media-query(desktop) {
            display: block;
            align-self: flex-end;
            padding-right: 0;
            width: 50%;
        }
    }

    &__content {
        padding: $grid;
        width: 100%;

        @include media-query(desktop) {
            padding: 70px;
            padding-right: 0;
        }
    }

    &__speech-bubble {
        position: relative;
        font-size: 30px;
        font-weight: 700;
        background-color: $color--primary;
        color: $color--white;
        width: 90%;
        padding: 32px 40px;
        line-height: 1.3em;
        max-width: 530px;

        @include media-query(tablet) {
            width: 100%;
            font-size: 36px;
        }

        height: max-content;
        align-items: center;
        justify-content: center;
        display: flex;

        p {
            margin: 0;
            text-wrap: balance;
        }
    }

    &__speech-bubble::before {
        position: absolute;
        display: block;
        width: 0;
        content: "";
        border: 15px solid transparent;
        border-top-color: $color--primary;
        border-bottom: 0;
        bottom: -14px;
        left: $grid * 1.5;
    }

    &__description {
        font-size: 19px;
        font-weight: 500;
        color: $color--black;
        padding: 35px 20px 20px 0;
        @include media-query(mobile-small) {
            padding-left: 42px;
        }
        text-wrap: pretty;
        width: 100%;
    }

    &__link {
        font-size: 22px;
        color: $color--black;
        padding: 20px 20px 20px 0;
        @include media-query(mobile-small) {
            padding-left: 39px;
        }
        font-weight: 700;
        text-decoration: none;
        gap: ($grid * 0.5);

        &:hover {
            color: $color--nhs-dark-pink;
        }
    }
}
