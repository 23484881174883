@use "sass:math";

.charity {
    .pages-listing {
        $root: &;

        &--feed {
            @include site-content-container;
            padding: ($grid);
            @include media-query(desktop) {
                padding: ($grid * 1.5);
         }
        }
        &__list {
            display: flex;
            flex-direction: column;
            margin: 0;

            @include media-query(mobile) {
                margin: -($grid * 0.5);
            }
        }
        &__hero-item {
            background: $color--charity-pink-light;
            margin-left: -30px;
            margin-right: -30px;
            margin-bottom: 50px;
            flex: 100% 1 0;

            .pages-listing__link {
                padding: 0;
                flex-direction: column;
                @include media-query(mobile) {
                    flex-direction: row;
                }
                .pages-listing__img {
                    width: 100%;
                    height: 400px;
                    margin: 0;
                    flex: auto 0 0;
                    border: 0;
                    object-fit: cover;
                    @include media-query(mobile) {
                        width: 50%;
                        height: auto;
                    }
                }

                .pages-listing__content {
                    padding: $grid * 1.5;

                    .pages-listing__title {
                        @include charity-font-size(l);
                        color: $color--white;
                        font-weight: 700;
                        flex: auto 1 1;
                    }

                    .pages-listing__date{
                        @include charity-font-size(xs);
                        color: $color--white;
                    }
                }

                &:hover {
                    .pages-listing__title {
                        text-decoration: none;
                    }
                }
            }
        }
        &__item {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 100%;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            margin-bottom: 60px;

            .pages-listing__link {
                column-gap: $grid * 1.5;
                justify-content: space-between;
            }

            &:nth-of-type(n + 1) img{
                border-color: $color--charity-pink-light;
            }
            &:nth-of-type(2n + 2) img{
                border-color: $color--charity-blue-dark;
            }
            &:nth-of-type(3n + 3) img{
                border-color: #69B657;
            }
            &:nth-of-type(4n + 4) img{
                border-color: $color--charity-accent;
            }
            &:nth-of-type(5n + 5) img{
                border-color: $color--nhs-bright-blue;
            }
            &:nth-of-type(6n + 6) img{
                border-color: $color--charity-pink-light;
            }
        }
        &__link {
            width: 100%;
            display: flex;
            text-decoration: none;
            padding: 0;
            flex-direction: column-reverse;
            @include media-query(mobile) {
                flex-direction: row;
            }

            &:hover {
                .pages-listing__title {
                    color: $color--charity-link-hover;
                    text-decoration: none;
                }
            }
        }
        &__img {
            display: block;
            flex: auto 0 0;
            width: 100%;
            height: 250px;
            object-fit: cover;
            border-right: 10px solid black;
            @include media-query(mobile) {
                width: 230px;
                height: 100%;
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
        }
        &__title {
            @include charity-font-size(s);
            color: $color--charity-link;
            text-decoration: underline;
            text-decoration-skip: ink;
            font-weight: 500;
            transition: color $default-transition-speed ease 0s;
            will-change: color;
            margin-top: 10px;


            @include media-query(mobile) {
                margin: 0;
            }
        }
        &__body {
            display: none;
            margin: 0;
            flex: auto 1 1;
            color: $color--charity-text;
            font-size: 18px;
            #{$root}__link & {
                color: $color--charity-text;
                text-decoration: none;
            }
            @include media-query(tablet) {
                display: block;
            }
        }
        &__date {
            display: none;
            @include charity-font-size(xs);
            color: $color--charity-text;
            @include media-query(mobile) {
                display: block;
            }
        }
    }

    .events-listing {

        &__list {
            display: flex;
            flex-flow: row wrap;
            gap: $grid;
            margin: 0;

            .events-listing__item {
                width: 100%;
                @include media-query(mobile) {
                    width: calc((100% / 2) - ($grid * .5));
                }
            }

            .events-listing__link {
                background-color: $color--charity-grey-light;
                border-bottom: 2px solid $color--charity-blue-dark;
                text-decoration: none;

                display: block;
                position: relative;
                min-height: 400px;
                margin-top: $grid;

                .events-listing__img {
                    width: 100%;
                    height: 200px;
                    object-fit: cover;
                    border: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                }

                .events-listing__content {
                    padding: 220px 25px 30px 25px;
                    color: $color--charity-grey;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    height: 400px;

                    .events-listing__title {
                        background-color: $color--charity-blue-dark;
                        color: #fff;
                        text-decoration: underline;

                        position: absolute;
                        top: -$grid;
                        left: 0;
                        width: auto;
                        max-width: 350px;
                        padding: 25px;
                        z-index: 2;
                        line-height: 1.5;
                    }

                    .events-listing__body {
                        margin-bottom: $grid * .5;
                        flex-grow: 1;
                        flex-shrink: 0;
                        max-height: 80px;
                        overflow: hidden;
                        font-size: 18px;
                    }

                    .events-listing__date,
                    .events-listing__location {
                        font-weight: 600;
                        display: block;
                        flex-grow: 0;
                        flex-shrink: 1;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}