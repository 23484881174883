.charity {

    .slideshow {

        &__item {

            &::after {
                border-radius: $charity-border-radius;
            }

        }

        &__image {
            border-radius: $charity-border-radius;
        }

    }

}
