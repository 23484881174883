.split-cta {
    $root: &;
    border-top: 0;

    &__container {
        display: flex;
        flex-direction: column;
        background-color: $color--secondary;
        padding: 0;

        @include media-query(tablet) {
            flex-direction: row;
        }

    }

    &__item {
        flex-basis: 100%;
        min-width: 100%;
        padding-top: ($grid * 1.5);
        padding-bottom: ($grid * 1.5);
        background-position: center;
        background-size: cover;

        @include media-query(tablet) {
            flex-basis: 50%;
            min-width: 50%;
            padding-top: ($grid * 3);
            padding-bottom: ($grid * 3);
        }

        &:first-child {
            min-height: ($grid * 10);

            @include media-query(tablet) {
                min-height: auto;
            }

        }

    }

    &__item-container {
        text-align: left;
        padding-left: ($grid);
        padding-right: ($grid);

        @include media-query(tablet) {
            padding-left: ($grid * 2);
            padding-right: ($grid * 2);
        }

    }

    &__body {
        margin-bottom: ($grid);
    }

    &__form {
        margin-bottom: ($grid * 0.5);
    }

    &__form-container {
        position: relative;
    }

    &__form-value {
        @include font-size(s);
        position: absolute;
        left: ($grid * 0.5);
        top: 17px;
        color: $color--quinary;
        font-weight: 700;
        line-height: 1;
    }

    &__form-input {
        padding-top: ($grid * 0.5);
        padding-bottom: ($grid * 0.5);
        padding-left: ($grid);
        margin-bottom: ($grid * 0.5);
        width: 100%;

        @include media-query(tablet) {
            width: auto;
            max-width: ($grid * 5);
        }

    }

}
