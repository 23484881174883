.logo {
    transition: width $default-transition-speed ease 0s;

    .site-header & {
        width: 80px;
        height: 32px;

        .intranet & {
            width: 200px;
            height: 58px;

            @include media-query(laptop) {
                width: 200px;
                height: 76px;
            }
    
        }

    }

    .site-header--formulary & {
        width: 160px;
        height: 85px;
        margin-right: ($grid * 0.5);
    }

}

.logo-text {
    display: block;
    font-weight: bold;
}

.logo-organisation-name {
    font-size: 22px;
    letter-spacing: .2px;
    line-height: 23px;
    margin-top: 2px;
    white-space: nowrap;
}

.logo-organisation-descriptor {
    font-size: 15px;
    line-height: 21px;
}
