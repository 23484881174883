.charity {
    .site-header {
        .logo {
            width: 155px;
            height: 42px;
            @include media-query(mobile) {
                width: 200px;
                height: 70px;
            }
        }

        .logo-full {
            width: 420px;
            height: 70px;
            display: none;
            @include media-query(mobile) {
                display: block;
            }
        }

        .logo-small {
            display: block;
            @include media-query(mobile) {
                display: none;
            }
        }

    }
}
