.social {
    $root: &;
    margin-bottom: ($grid);
    text-align: center;
    width: 100%;

    @include media-query(tablet) {
        width: auto;
        text-align: left;
        border-right: 2px dotted $color--border;
        padding-right: ($grid * 2);
        margin-right: ($grid * 2);
        margin-bottom: ($grid * 2);
    }

    &__content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: ($grid * 0.5) 0;
        text-align: center;
        width: 100%;

        @include media-query(tablet) {
            justify-content: flex-start;
            width: auto;
            text-align: left;
        }

    }

    &__item {
        margin-right: ($grid);
        margin-bottom: ($grid * 0.5);

        @include media-query(tablet) {
            margin-bottom: 0;
        }

    }

    &__icon {
        fill: $color--white;
        margin-right: ($grid * 0.5);

        &--twitter {
            fill: $color--twitter;
        }

        &--facebook {
            fill: $color--facebook;
        }

        &--youtube {
            fill: $color--youtube;
        }

        &--flickr {
            fill: $color--flickr;
        }

    }

    &__link {
        display: flex;
        align-items: center;

        &:hover {

            #{$root}__label {
                color: $color--public-interact;
            }

        }

    }

    &__label {
        @include font-size(s);
        color: $color--white;
    }


}
