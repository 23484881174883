/*------------------------------------*\
    $MAIN MENU
\*------------------------------------*/

.charity {
    .charity-menu-main {
        $root: &;

        &__container {
            display: flex;
            flex-direction: row;
            align-items: baseline;
        }

        .charity-menu-main__dropdown {
            max-width: 100%;
            margin: 10px auto 0;
            background: #FDF7FB;
            padding: 0;
            transition: none;
        }

        .mobile-secondary-menu {
            display: none;
        }

        &__item {
            padding: ($grid * 0.5) ($grid * 0.75) $grid;
            border: 0;
            margin-bottom: 8px;

            // Dropdown initialisation
            &.active-menu {
                background: $color--charity-pink-light;


                .charity-menu-main__dropdown {
                    opacity: 1;
                    transform: translate3d(0, 0%, 0);
                    // sass-lint:disable indentation
                    transition: none;
                    // sass-lint:enddisable
                    pointer-events: all;
                }

                .charity-menu-main__icon {
                    transform: rotate(0.5turn);
                }

                .charity-menu-main__link {
                    color: #ffffff;

                    .charity-menu-main__label {
                        border-bottom: 3px solid $color--charity-pink-light;
                        padding-bottom: 0px;
                    }

                    .charity-menu-main__icon {
                        fill: #ffffff;
                    }

                    &:focus{
                        background-color: $color--charity-pink-light;
                        box-shadow: none;
                        color: #ffffff;
                        outline:none;
                        text-decoration: none;
                    }
                }
            }
        }
        &__link {
            all:unset;
            font-weight: 700;
            font-size: 16px;
            font-family: $font--charity-primary;
            color: $color--charity-pink-light;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;

            &:hover {
                color: $color--charity-pink-dark;

                .charity-menu-main__label {
                    border-bottom: 3px solid $color--charity-pink-dark;
                    padding-bottom: 0px;
                }

                .charity-menu-main__icon {
                    fill: $color--charity-pink-dark;
                }
            }

            &:focus{
                background-color: $color--nhs-light-yellow;
                box-shadow: 0 -2px $color--nhs-light-yellow, 0 4px #212b32;
                color: #212b32;
                outline: 4px solid transparent;
                text-decoration: none;
            }

        }
        &__label {
            border-bottom: 1px solid $color--charity-pink-light;
            padding-bottom: 2px;
        }
        &__icon {
            display: inline;
            fill: $color--charity-pink-light;
        }

        &__dropdown {
            position: absolute;
            z-index: 4;
            left: 0;
            right: 0;
            opacity: 0;
            padding-top: 25px;
            max-width: $max-width;
            margin: 0 auto;
            pointer-events: none;
            // sass-lint:disable indentation
            transition: opacity $default-transition-speed ease 0.15s;
            // sass-lint:enddisable
        }
    }
}
