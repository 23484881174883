.tabs {
    $root: &;
    position: relative;
    z-index: 3;
    width: 100%;
    transition: position $default-transition-speed ease 0s;
    margin-bottom: ($grid);

    &.stuck {
        position: fixed;
        top: 40px;
    }

    &--center {

        #{$root}__container {
            justify-content: center;
        }

    }

    &--a-z {

        #{$root}__container {
            padding-left: 0;
            padding-right: 0;
            margin-right: -5px;
            justify-content: flext-start;

            &::after {
                content: '';
                display: block;
                flex-grow: 1;

                @media only screen and (min-width: 1246px) {
                    display: none;
                }
            }
        }

        #{$root}__item {
            font-size: 1.375rem;
            justify-content: center;
            border-radius: $default-border-radius;
            padding: 8px 16px 0;
            margin-right: 5px;
            margin-bottom: 5px;
            color: $color--primary;
            border-bottom: 0;
            height: 44px;
            width: 24px;
            transition: all 0.2s ease-out;

            &--all {
                margin-right: 13px;
            }

            &--0-9 {
                width: 64px;
                margin-right: -5px;
                margin-left: -12px;
            }

            &--disabled {
                color: $color--muted;
                pointer-events: none;
                cursor: none;
                text-decoration: none;
            }

            &--active {
                color: $color--black;
                font-weight: 700;
                cursor: default;
            }

            &--active:hover,
            &:hover {
                color: $color--nhs-dark-pink;
                text-decoration: none;
            }

            &:visited {
                color: $color--nhs-purple;
            }

            &:visited:hover {
                color: $color--nhs-dark-pink;
            }

            &:focus {
               border-radius: 0;
               color: $color--black;
            }
        }

        #{$root}__label {
            color: $color--primary;
        }
    }

    &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        padding-left: ($grid);
        padding-right: ($grid);
    }

    &__item {
        font-size: 1.375rem;
        display: flex;
        padding: ($grid * 0.75) 0 ($grid * 0.5);
        margin-right: ($grid);
        color: $color--white;
        border-bottom: 5px solid transparent;

        &:hover {
            color: $color--nhs-dark-pink;
        }

        &--active {
            text-decoration: none;
            color: #002f5c !important;
            border-bottom: 5px solid $color--octonary;
        }

        span {
            position: relative;
            top: -2px; // Offset font-weight height different
            color: $color--off-white;
            font-weight: 500;
            padding-left: ($grid * 0.25);
        }
    }

    &__content {
        display: none;

        &--current {
            display: inherit;
        }

    }

    &__label {
        font-size: 1.375rem;
        display: none;
        position: relative;
        top: -2px;
        color: $color--white;
        margin-right: ($grid);

        @include media-query(tablet) {
            display: block;
        }
    }

    .page-header + & {
        margin-top: -($grid);
        margin-bottom: ($grid);
    }
}
