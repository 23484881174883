.charity {
    .page-body {
        @include site-content-container;
        margin-bottom: 80px;

        padding: 0 30px;

        @include media-query(mobile) {
            padding: 0 ($grid * 2);
        }

        .rich-text {
            font-size: 18px;

            h1 {
                @include charity-font-size(xl);
            }
            h2 {
                font-size: 30px;
            }
            h3 {
                @include charity-font-size(m);
                color: $color--charity-grey;
            }
            h4 {
                @include charity-font-size(s);
                color: $color--charity-grey;
            }

            h2, h3, h4 {
                margin-bottom: 20px;
            }

            p + h2, p + h3, p + h4 {
                margin-top: 50px;
            }
        }
    }
}

.template-charity-standard-page,
.template-charity-detail-page,
.template-charity-embed-page,
.template-charity-event-index-page {
    color: $color--charity-text;

    .page-hero {
        background: $color--charity-blue-dark;
        color: $color--white;
        margin-left: -30px;
        margin-right: -30px;
        margin-bottom: 60px;
        padding: 30px;
        @include media-query(mobile) {
            margin-left: -50px;
            margin-right: -50px;
            padding: $grid * 2;
        }

        display: flex;
        flex-direction: row;
        column-gap: 50px;

        &__img {
            display: none;
            max-width: 50%;
            object-fit: cover;
            margin: (-$grid * 4) (-$grid * 2) (-$grid * 2) 0;
            @include media-query(tablet) {
                display: block;
            }
        }
        &__mobile-img {
            display: block;
            max-width: calc(100% + $grid * 4);
            object-fit: cover;
            margin: 0 (-$grid * 2);
            @include media-query(tablet) {
                display: none;
            }
        }

        .page-hero__content {
            width: 100%;
            display: flex;
            flex-direction: column;

            h1 {
                color: $color--white;
                hyphens: auto;
            }

            span {
                @include charity-font-size(s);
                hyphens: auto;
                flex-grow: 1;
                flex-shrink: 0;
                margin: $grid 0;

                &.page-hero__date,
                &.page-hero__location {
                    font-weight: 700;
                    display: block;
                    flex-grow: 0;
                    flex-shrink: 1;
                    margin: 0;
                }
            }
        }
    }

    .page-body {
        margin-bottom: $grid * 2;
        h2 {
            @include charity-font-size(l);
            color: $color--charity-text;
            font-weight: 700;
        }

        &__in-this-section {
            border-top: 1px solid $color--nhs-mid-grey;
            border-left: none;
            padding: $grid 0 20px 0;

            @include media-query(laptop) {
                border-top: none;
                border-left: 1px solid $color--nhs-mid-grey;
                padding: 0 $grid 0 20px;
            }

            &__header {
                @include charity-font-size(s);
                font-weight: 700;
                margin-bottom: $grid * 0.5;
            }

            a, li {
                @include charity-font-size(xs);
            }

            li {
                margin-bottom: 20px;
            }
        }
    }

    .charity-cta.charity-cta--content-feed {
        background: $color--charity-grey-light;
    }
}
