.supporting-info {

    &.stuck {
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background: $color--septenary;
        padding: ($grid * 0.5);

        @include media-query(tablet) {
            padding: ($grid * 0.5) ($grid);
        }

    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: $max-width;
        margin: 0 auto;

        @include media-query(mobile) {
            flex-direction: row;
            justify-content: space-between;
        }

    }

}
