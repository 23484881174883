.charity {
    .owl-dot {
        &.active {
            background-color: $color--charity-link;
        }
    }
    .owl-next,
    .owl-prev {
        background-color: $color--charity-link;
        &.disabled {
            background-color: $color--charity-link;
            &:hover {
                background-color: $color--charity-link;
            }
        }
        &:hover {
            background-color: $color--charity-link-hover;
        }
    }
}