/*------------------------------------*\
    $HERO
\*------------------------------------*/

.hero {
    $root: &;
    position: relative;
    height: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: ($grid * 1.5);
    margin-top: 0;

    @include media-query(tablet) {
        height: 30vh;
        min-height: 400px;
    }

    &--small {
        height: 300px;
        min-height: 0;
    }

    &--banner {
        height: 100px;
        min-height: 0;
    }

    // If page has filters on it
    &--filters {
        padding-bottom: ($grid * 2);
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        height: 100%;
        position: relative;
        padding-right: ($grid);

        .template-campaign-landing-page & {
            @include ie11 {
                display: block;
                padding-top: 50px;
            }
        }
    }

    &__heading {
        @include font-size(l);
        display: inline-block;
        float: left;
        flex-grow: 0;
        font-weight: 500;
        padding: ($grid * 0.5) ($grid);
        color: $color--white;
        margin-bottom: 0;
        letter-spacing: -1px;
        line-height: 1;
        background-color: darken($color--quinary, 10%);

        @include media-query(tablet) {
            @include font-size(xl);
        }

        @include media-query(laptop) {
            padding: ($grid * 0.5) ($grid * 1.5);
        }

        .intranet & {
            background-color: $color--senary;
        }

        .template-campaign-landing-page & {
            @include font-size(l);
            background-color: transparent;
            margin: auto 0;
            max-width: 700px;
            font-weight: 600;

            @include media-query(tablet) {
                font-size: 80px;
            }
        }

        > img {
            display: block;
            margin-bottom: 20px;
        }

    }

    &__meta {
        @include font-size(s);
        margin-top: ($grid * 0.75);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        color: $color--white;
    }

    &__meta-item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: ($grid * 0.25);
    }

    &__icon {
        fill: $color--white;
        margin-right: ($grid * 0.25);
    }

}
