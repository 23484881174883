.cards {
    $root: &;
    margin-bottom: ($grid * 2);

    &__container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @include ie11 {
            flex-direction: row;
        }

        &.js-filterable {
            display: block;

            #{$root}__item {
                min-width: auto;
            }

        }

    }

    &__item {
        flex-basis: 100%;
        max-width: 100%;
        margin-bottom: ($grid);
        border-left: 2px solid $color--border;
        padding-left: ($grid * 0.75);
    }

    &__item-container {
        display: flex;
        height: 100%;
        flex-direction: row;
        text-decoration: none;
        justify-content: space-between;

        &:hover {

            #{$root}__video-icon {
                transform: scale(1.2);
                fill: $color--white;
            }

            #{$root}__heading {
                color: $color--link-hover;
            }

        }

    }

    &__image-container {
        margin-bottom: ($grid * 0.5);
    }

    &__image {
        display: block;
        width: 100%;
        height: auto;
    }

    &__text-container {
        text-align: left;
    }

    &__heading {
        @include font-size(s);
        display: flex;
        align-items: center;
        line-height: 1.2;
        text-decoration: underline;
        text-decoration-skip: ink;
        color: $color--link;
        margin-bottom: 0;
        transition: color $default-transition-speed ease 0s;
    }

    &__description {
        @include font-size(xs);
        color: $color--default;
        margin-bottom: 0;
    }

    &__icon {
        fill: $color--primary;
    }

    &__action {
        @include font-size(m);
        display: flex;
        align-items: center;
        color: $color--black;

        &:hover {

            #{$root}__action-arrow {
                fill: $color--black;
                transform: scale(1.2);
            }

        }

    }

    &__action-arrow {
        fill: $color--octonary;
        // sass-lint:disable indentation
        transition: fill $default-transition-speed ease 0s,
                    transform $default-transition-speed ease 0s;
        // sass-lint:enddisable
    }

    &__meta {
        display: flex;
        padding: ($grid * 0.25) 0;
        justify-content: center;

        @include media-query(mobile) {
            justify-content: flex-start;
        }

    }

    &__meta-item {
        display: flex;
        align-items: center;
        line-height: 1;
        margin-right: ($grid * 0.5);
    }

    &__meta-icon {
        margin-right: ($grid * 0.25);
        fill: $color--octonary;
    }

    &__meta-label {
        @include font-size(s);
    }

}
