.department {

    &__content {
        order: 2;
        margin-bottom: ($grid);

        @include media-query(laptop) {
            order: 1;
        }

    }

    &__profile {
        order: 1;
        margin-bottom: ($grid);

        @include media-query(laptop) {
            order: 2;
        }

    }

}
