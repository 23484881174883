// Overrides for the service manual components

// The nhs service manual uses white to emphasis components
// on a light grey background.
// We currently have a white background, so reverse this.
$nhsuk-component-background: #f0f4f5;

.nhsuk-do-dont-list,
.nhsuk-inset-text {
    background-color: $nhsuk-component-background;
}

.nhsuk-do-dont-list .rich-text:last-child p,
.nhsuk-inset-text .rich-text:last-child p  {
    margin-bottom: 0;
}

.nhsuk-inset-text p {
    @include nhsuk-typography-responsive(19);
}

// update colours on nhsuk-card styling for the main site {
a.nhsuk-card__link {
    text-decoration: none;
}
.nhsuk-card {
    &__link {

        &:visited,
        &:hover {
            color: $color--nhs-blue;

            h2 {
                text-decoration: none;
            }
        }
        &:hover {
            h2 {
                color: $color--nhs-dark-pink;
            }
        }

        h2 {
            color: $color--nhs-blue;
            text-decoration: underline;
        }

        &:focus {
            .nhsuk-card__content {
                background-color: $color--nhs-light-yellow;
                border-bottom:  4px solid #212b32;
                color: #212b32;
                outline: 4px solid transparent;
                
                h2 {
                    text-decoration: underline;
                }
            }
        }
    }

    &__description {
        @include nhsuk-typography-responsive(19);
        color:  $nhsuk-text-color;
    }

    &--clickable {
        &:hover {
            .nhsuk-card__link {
                color: $color--nhs-blue;
            }
        }
    }
}
