/*------------------------------------*\
    $CUSTOM CHECKBOX
\*------------------------------------*/

// Hide checkbox input from view
.checkbox {
    input {
        padding: 0;
        opacity: 0;
    }
}

.checkbox-label {
    @include font-size(s);
    position: relative;
    display: block;
    padding: 3px 0 0 35px;
    margin-top: -8px;
    color: #000;
    cursor: pointer;

    // Background border
    &::before {
        position: absolute;
        top: 1px;
        left: 0;
        display: inline-block;
        width: 22px;
        height: 22px;
        border: 2px solid #000;
        content: '';
    }

    // Tick
    &::after {
        position: absolute;
        top: 3px;
        left: 7px;
        display: inline-block;
        width: 8px;
        height: 14px;
        border: solid #000;
        border-width: 0 2px 2px 0;
        content: none;
        transform: rotate(45deg);

        // sass-lint:disable force-element-nesting
        .checkbox input:checked + & {
            content: '';
        }
    }
}
