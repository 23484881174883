/*------------------------------------*\
    $SITE FOOTER
\*------------------------------------*/

.site-footer {
    $root: &;
    display: none;
    width: 100%;
    padding: ($grid * 2) ($grid);
    background-color: $color--secondary;

    @include media-query(laptop) {
        display: block;
    }

    @include media-query(desktop) {
        padding-right: ($grid * 1.5);
        padding-left: ($grid * 1.5);
    }

    &__container {
        display: flex;
        margin-left: -($grid * 0.5);
        margin-right: -($grid * 0.5);
    }

    &__heading {
        @include font-size(s);
        font-weight: 700;
        margin-bottom: ($grid * 0.5);
    }

    &__column {
        padding: 0 ($grid * 0.5);
        flex-basis: 20%;
        min-width: 20%;
    }

    &__list-item {
        margin-bottom: 0;
    }

    &__list-link {
        @include font-size(xs);
        font-weight: 500;
        color: #4c6272;
        position: relative;
        display: block;
        padding: 5px 0 5px 0;

        text-decoration: underline;
        text-decoration-skip: ink;
        transition: color ease $default-transition-speed;
    
        &:hover {
            color: $color--link-hover;
        }
    
        &:visited {
            color: $color--nhs-purple;
        }
    }

    &__social-container {
        display: flex;
        flex-direction: column;
    }

    &__social-item {
        margin-bottom: 0;
    }

    &__social-link {
        @include font-size(xs);
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #4c6272;
        transition: background-color $default-transition-speed ease;
        padding: 5px 0 5px 0;

        &:hover {
            #{$root}__social-link-icon {
                fill: $color--nhs-dark-pink;
            }
        }

        span {
            @include hidden;
        }
    }

    &__social-link-icon {
        width: 18px;
        height: 18px;
        margin-right:0.75rem;
        fill: #4c6272;
    }
}
