.richtext-image {

    &.right {
        @include media-query(tablet) {
            float: right;
            max-width: 61.5%;
            height: auto;
            margin: 0 0 ($grid) ($grid);
        }

    }

    &.left {
        @include media-query(tablet) {
            float: left;
            max-width: 61.5%;
            height: auto;
            margin: 0 ($grid) ($grid) 0;
        }

    }

    &.full-width {
        display: block;
        width: 100%;
        height: auto;
    }

}
