/*------------------------------------*\
    $TABLE OF CONTENTS
\*------------------------------------*/

/**
 *
 * Config ---------------------------
 * .....Variables
 *
 * Vendor ---------------------------
 * .....Normalize
 * .....Grid
 *
 * Abstracts ------------------------
 * .....Mixins
 * .....Functions
 * .....Base
 * .....Type
 * .....Patternlab
 *
 * Atoms ----------------------------
 * Molecules ------------------------
 * Organisms ------------------------
 * Templates ------------------------
 * Pages ----------------------------
 * Themes ---------------------------

 */

/*------------------------------------*\
    $CONFIG
\*------------------------------------*/
@import "config/variables";

/*------------------------------------*\
    $SERVICE-MANUAL-COMPONENTS
    Styles for service manual patterns
    https://github.com/nhsuk/nhsuk-frontend/blob/master/docs/installation/installing-with-npm.md#importing-styles
    https://beta.nhs.uk/service-manual/styles-components-patterns

    All partials are individually included apart from the following as they override custom site styles
    - nhsuk-frontend/packages/core/styles/typography
\*------------------------------------*/

// Settings
@import "../../node_modules/nhsuk-frontend/packages/core/settings/all";

// Tools
@import "../../node_modules/nhsuk-frontend/packages/core/tools/all";

// Generic
@import "../../node_modules/nhsuk-frontend/packages/core/generic/box-sizing";
@import "../../node_modules/nhsuk-frontend/packages/core/generic/font-face";
//@import 'node_modules/nhsuk-frontend/packages/core/generic/ie';
article,
aside,
footer,
header,
nav,
section {
    display: block;
}

// Elements
@import "../../node_modules/nhsuk-frontend/packages/core/elements/forms";
@import "../../node_modules/nhsuk-frontend/packages/core/elements/links";
@import "../../node_modules/nhsuk-frontend/packages/core/elements/page";
@import "../../node_modules/nhsuk-frontend/packages/core/elements/table";

// Objects
@import "../../node_modules/nhsuk-frontend/packages/core/objects/form-group";
@import "../../node_modules/nhsuk-frontend/packages/core/objects/grid";
@import "../../node_modules/nhsuk-frontend/packages/core/objects/main-wrapper";
@import "../../node_modules/nhsuk-frontend/packages/core/objects/width-container";

// Styles
@import "../../node_modules/nhsuk-frontend/packages/core/styles/icons";
@import "../../node_modules/nhsuk-frontend/packages/core/styles/lists";
@import "../../node_modules/nhsuk-frontend/packages/core/styles/section-break";

// Utilities
@import "../../node_modules/nhsuk-frontend/packages/core/utilities/clearfix";
@import "../../node_modules/nhsuk-frontend/packages/core/utilities/grid-widths";
@import "../../node_modules/nhsuk-frontend/packages/core/utilities/link-nowrap";
@import "../../node_modules/nhsuk-frontend/packages/core/utilities/reading-width";
@import "../../node_modules/nhsuk-frontend/packages/core/utilities/spacing";
@import "../../node_modules/nhsuk-frontend/packages/core/utilities/typography";
@import "../../node_modules/nhsuk-frontend/packages/core/utilities/visually-hidden";
@import "../../node_modules/nhsuk-frontend/packages/core/utilities/widths";

// Components
@import "../../node_modules/nhsuk-frontend/packages/components/do-dont-list/do-dont-list";
@import "../../node_modules/nhsuk-frontend/packages/components/inset-text/inset-text";
@import "../../node_modules/nhsuk-frontend/packages/components/card/card";
@import "../../node_modules/nhsuk-frontend/packages/components/button/_button";
@import "../../node_modules/nhsuk-frontend/packages/components/header/_header";

/*------------------------------------*\
$VENDOR
\*------------------------------------*/
@import "vendor/nhsukfrontend";
@import "vendor/normalize";
@import "vendor/fancybox";
@import "vendor/owl.carousel";
@import "vendor/owl.theme.default";
@import "vendor/pikaday";

/*------------------------------------*\
    $ABSTRACTS
\*------------------------------------*/
@import "abstracts/mixins";
@import "abstracts/base";
@import "abstracts/functions";
@import "abstracts/grid";
@import "abstracts/patternlab";
@import "abstracts/type";
@import "abstracts/rich-text";

/*------------------------------------*\
    $ATOMS
    Ordered alphabetically
\*------------------------------------*/
@import "atoms/article";
@import "atoms/action-link";
@import "atoms/back-to-top";
@import "atoms/buttons";
@import "atoms/checkbox";
@import "atoms/colour-strip";
@import "atoms/forms";
@import "atoms/iframe";
@import "atoms/images";
@import "atoms/links";
@import "atoms/lists";
@import "atoms/logo";
@import "atoms/meta";
@import "atoms/owl-carousel";
@import "atoms/resource-download";
@import "atoms/resource-page";
@import "atoms/skip-link";
@import "atoms/svg";
@import "atoms/swatches";
@import "atoms/tables";
@import "atoms/cqc-widget";
@import "atoms/header-overflow-image";

/*------------------------------------*\
    $MOLECULES
    Ordered alphabetically
\*------------------------------------*/
@import "molecules/at-a-glance";
@import "molecules/autocomplete";
@import "molecules/accordion";
@import "molecules/author";
@import "molecules/actions";
@import "molecules/beta-banner";
@import "molecules/breadcrumb";
@import "molecules/browse";
@import "molecules/cta";
@import "molecules/cards";
@import "molecules/cookie";
@import "molecules/count";
@import "molecules/current-status";
@import "molecules/department";
@import "molecules/diagnosis";
@import "molecules/expertise";
@import "molecules/featured-casestudy";
@import "molecules/filters";
@import "molecules/footer-credits";
@import "molecules/glossary";
@import "molecules/hero";
@import "molecules/hero-action";
@import "molecules/hero-form";
@import "molecules/hero-search";
@import "molecules/home-block";
@import "molecules/image-grid";
@import "molecules/isotope";
@import "molecules/introduction";
@import "molecules/inline-teaser";
@import "molecules/inline-image";
@import "molecules/intranet-block";
@import "molecules/intranet-bar";
@import "molecules/intranet-section-page";
@import "molecules/key";
@import "molecules/keyboard";
@import "molecules/listing";
@import "molecules/listing-header";
@import "molecules/latest";
@import "molecules/load-more";
@import "molecules/login";
@import "molecules/menu-main";
@import "molecules/menu-secondary";
@import "molecules/menu-dropdown";
@import "molecules/menu-mobile";
@import "molecules/message";
@import "molecules/modal";
@import "molecules/multi-option";
@import "molecules/multi-address";
@import "molecules/newsletter";
@import "molecules/our-locations";
@import "molecules/page-header";
@import "molecules/page-filters";
@import "molecules/page-nav";
@import "molecules/pagination";
@import "molecules/people-gallery";
@import "molecules/progress-bar";
@import "molecules/profile";
@import "molecules/related";
@import "molecules/related-files";
@import "molecules/section-header";
@import "molecules/section-footer";
@import "molecules/social";
@import "molecules/social-block";
@import "molecules/search";
@import "molecules/section";
@import "molecules/sharing";
@import "molecules/slideshow";
@import "molecules/split-cta";
@import "molecules/sticky-info";
@import "molecules/sticky-action";
@import "molecules/smallprint";
@import "molecules/sponsors";
@import "molecules/supporting-info";
@import "molecules/tabs";
@import "molecules/teaser";
@import "molecules/teaser-block";
@import "molecules/twitter";
@import "molecules/value-bar";
@import "molecules/video-highlights";
@import "molecules/quick-links";
@import "molecules/quote";
@import "molecules/waiting-times";
@import "molecules/winter-hero";
@import "molecules/opening-times";
@import "molecules/video-player";

/*------------------------------------*\
    $ORGANISMS
\*------------------------------------*/
@import "organisms/site-header";
@import "organisms/site-footer";
@import "organisms/page-body";

/*------------------------------------*\
    $TEMPLATES
\*------------------------------------*/

/*------------------------------------*\
    $PAGES
\*------------------------------------*/

/*------------------------------------*\
    $THEMES
\*------------------------------------*/
/* CHARITY SITE */
// CONFIG
@import "themes/charity/variables";
// ABSTRACTS
@import "themes/charity/mixins";
@import "themes/charity/base";
@import "themes/charity/type";
// ATOMS
@import "themes/charity/links";
@import "themes/charity/logo";
@import "themes/charity/owl-carousel";
// MOLECULES
@import "themes/charity/breadcrumb";
@import "themes/charity/browse";
@import "themes/charity/buttons";
@import "themes/charity/contact-block";
@import "themes/charity/cta";
@import "themes/charity/donate";
@import "themes/charity/featured-casestudy";
@import "themes/charity/hero";
@import "themes/charity/image-grid";
@import "themes/charity/inline-image";
@import "themes/charity/inline-teaser";
@import "themes/charity/menu-main";
@import "themes/charity/menu-dropdown";
@import "themes/charity/menu-secondary";
@import "themes/charity/menu-mobile";
@import "themes/charity/newsletter";
@import "themes/charity/page-body";
@import "themes/charity/page-header";
@import "themes/charity/pages-listing";
@import "themes/charity/pagination";
@import "themes/charity/slideshow";
@import "themes/charity/teaser";
@import "themes/charity/quote";
@import "themes/charity/twitter";
@import "themes/charity/forms";
@import "themes/charity/introduction";
@import "themes/charity/embed";

// ORGANISMS
@import "themes/charity/site-header";
@import "themes/charity/site-footer";
// TEMPLATES
// PAGES
