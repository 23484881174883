label {
    @include font-size(xs);
    display: block;
    color: $color--black;
    margin-bottom: ($grid * 0.25);
}

input,
select,
textarea {
    width: 100%;
    max-width: 300px;
}

input {
    @include font-size(xs);
    padding: 10px ($grid * 0.5);
    line-height: 1;
    border: 1px solid rgba($color--black, 0.2);
}

textarea {
    @include font-size(xs);
    padding: 10px ($grid * 0.5);
    min-width: 100%;
    min-height: 150px;
    border: 1px solid rgba($color--black, 0.2);
    transition: border-color $default-transition-speed ease 0s;

    @include media-query(laptop) {
        min-width: 600px;
    }
}

select {
    height: 41px;
    border: 1px solid rgba($color--black, 0.2);
    background: $color--white;

    @include media-query(mobile) {
        max-width: 100%;
        min-width: 300px;
        width: auto;
    }
}

[type='search'],
[type='text'],
[type='email'],
[type='file'],
[type='date'],
[type='number'],
[type='time'],
[type='tel'],
[type='password'],
[type='url'],
textarea {
    box-shadow: inset 1px 1px 5px 0 rgba($color--black, 0.2);
    transition: border-color $default-transition-speed ease 0s;

    &:focus {
        outline: 0;
        border-color: $color--nonary;
        box-shadow: inset 0 1px 1px rgba($color--black, 0.2), 0 0 8px rgba($color--black, 0.2);
    }

}

[type='search'] {
    // sass-lint:disable no-vendor-prefixes
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    // // sass-lint:enddisable
}

[type='radio'],
[type='checkbox'] {
    zoom: 1.4;
    // transform: scale(1.4);
    margin: 0 4px;
    width: auto;
}

fieldset {
    display: block;
    border: 2px dotted $color--border;
    margin: 0;
    margin-bottom: ($grid);
    padding: ($grid);
}

legend {
    @include font-size(m);
    font-weight: 700;
    float: left;
    width: 100%;
    display: table;
    color: $color--black;
    margin: 0;
    margin-bottom: ($grid);
}

// Return fieldsets to have no styling
body {

    // sass-lint:disable no-vendor-prefixes
    &:not(:-moz-handler-blocked) {

        fieldset {
            display: table-cell;
        }

    }
    // sass-lint:enddisable

}

// Custom styles

.form {

    &__group {
        margin-bottom: ($grid * 0.5);
    }

    &__group-heading {
        @include font-size(xs);
        color: $color--black;
        text-transform: uppercase;
        letter-spacing: 3px;
    }

    &__item {
        margin-bottom: ($grid * 0.5);
        clear: both;

        &--heading {
            margin-bottom: 0;
        }

        &--select_multiple {
            select {
                box-shadow: inset 1px 1px 5px 0 rgba($color--black, 0.2);
                width: 300px;
                max-width: 100%;
                height: auto;
                max-height: 130px;
                overflow: scroll;
            }

            option {
                padding: 4px 14px;
            }
        }

        &--multiple_choice_field,
        &--radio_select {
            label {
                cursor: pointer;
            }
        }
    }

    &__action {
        margin-top: ($grid);
        margin-bottom: ($grid * 2);
    }

    &__item-heading {
        @include font-size(s);
        margin-bottom: 0;
    }

    &__input {
        min-width: 300px;

        &--xsmall {
            width: 50px;
        }

        &--small {
            width: 200px;
        }

        &--medium {
            width: 400px;
        }

        &--large {
            width: 100%;
        }

    }

    &__checkbox-container {
        display: flex;
        flex-wrap: wrap;
    }

    &__radio-item,
    &__checkbox-item {
        @include font-size(xs);
        display: flex;
        align-items: center;
        color: rgba($color--black, 0.7);
        margin-bottom: ($grid * 0.25);

        [type='radio'],
        [type='checkbox'] {
            margin-right: 5px;
        }

    }

    &__checkbox-item {
        flex-basis: 100%;
        min-width: 100%;
        margin-bottom: ($grid * 0.25);

        @include media-query(laptop) {
            flex-basis: 33.333%;
            min-width: 33.333%;
            margin-bottom: ($grid * 0.5);
        }

    }

    &__required {
        font-weight: 700;
        color: $color_nhsuk-red;
    }

    &__description {
        @include font-size(xs);
        margin-bottom: ($grid * 0.25);
        color: $color--muted;

        &--important {
            font-weight: 700;
        }

    }

    // GNHS-131 Update form fields and form page template
    &__btn {
        padding: 12px 16px;
        line-height: 1.5;
        font-weight: 600;
        font-size: 1.1875rem;
        text-decoration: none;
        border: 2px solid rgba(0, 0, 0, 0);
        border-radius: 4px;
        color: #fff;
        background-color: #007f3b;
        box-shadow: 0 4px 0 #00401e;
        position: relative;
        transition: none;

        &::before {
            background: rgba(0, 0, 0, 0);
            bottom: -6px;
            content: "";
            display: block;
            left: -2px;
            position: absolute;
            right: -2px;
            top: -2px;
        }

        &:active::before  {
            top: -6px;
        }

        &:hover {
            background-color: #00662f;
        }

        &:focus {
            background: #ffeb3b;
            box-shadow: 0 4px 0 #212b32;
            color: #212b32;
            outline: 4px solid rgba(0, 0, 0, 0);
        }

        &:active {
            background: #00401e;
            box-shadow: none;
            color: #fff;
            top: 4px;
        }
    
    }

}

.inline-search {
    width: 100%;

    @include media-query(laptop) {
        width: auto;
    }

    &__container {
        position: relative;
    }

    &__label {
        display: none;
    }

    &__input {

        font-weight: 300;

        .page-filters & {
            width: 100%;

            @include media-query(laptop) {
                min-width: ($grid * 11);
            }

        }

    }

    &__icon {
        position: absolute;
        z-index: 1;
        right: ($grid * 0.5);
        top: 15%;
        background-color: transparent;
        border: 0;
        margin: 0;
        padding: 0;

        &:hover {
            fill: $color--nhs-dark-pink;
            cursor: pointer;

            svg {
                fill: $color--nhs-dark-pink;
                cursor: pointer;
            }

        }

    }

}
