/*------------------------------------*\
    $KEYBOARD
\*------------------------------------*/

// Construct a responsive circle with text
$keyboard-letter-width: 10%;
$keyboard-border-width: 2px;
$keyboard-padding: ($keyboard-letter-width * 0.5);

.keyboard {
    $root: &;
    margin: 15px -11px;

    &__letter {
        position: relative;
        display: inline-block;
        width: calc(#{$keyboard-letter-width} + #{$keyboard-border-width * 2});
        height: 0;
        padding: $keyboard-padding 0;
        margin: 1.5%;
        color: #fff;
        cursor: pointer;
        background-color: transparent;
        border: $keyboard-border-width solid rgba(255, 255, 255, 0.45);
        border-radius: 50%;
        transition: background-color $default-transition-speed ease, border-color $default-transition-speed ease, color $default-transition-speed ease;

        @include media-query(laptop) {
            width: 40px;
            height: 40px;
            padding: 0;
            margin: 6px;
        }

        &--active,
        &:hover {
            color: $color--primary;
            background-color: #e3ecf5;
            border-color: #e3ecf5;
        }

        &:focus {
            outline: 0;
        }

        &--disabled {
            color: #457fb5;
            cursor: initial;
            border-color: #457fb5;

            &:hover {
                color: #457fb5;
                background-color: transparent;
                border-color: #457fb5;
            }
        }
    }

    &__letter--active {
        color: $color--primary;
        background-color: #fff;
    }

    &__letter-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;

        &::before {
            display: inline-block;
            width: 0;
            height: 100%;
            vertical-align: middle;
            content: '';
        }

        span {
            display: inline-block;
            vertical-align: middle;
        }

        @include media-query(laptop) {
            position: static;
        }
    }
}
