/*------------------------------------*\
    $BASE DEFAULTS
\*------------------------------------*/

// Prevent text size change on orientation change.
// sass-lint:disable no-vendor-prefixes
.charity {
    font-family: $font--charity-primary;
    max-width: 100%;
    .site-content__container {
        position: relative;

        > * {
            z-index: 1; //stacking context: .site-content__container
        }
    }
}

body.charity {
    padding: 0;
    font-size: 18px;
    @include media-query(mobile) {
        font-size: 16px;
    }
}