.section-footer {
    $root: &;
    margin: $grid auto ($grid * 2);

    &__link {
        display: flex;
        text-align: center;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: $color--black;

        &:hover {

            #{$root}__icon {
                transform: scale(1.2);
            }

            #{$root}__label {
                color: $color--octonary;
            }

        }

    }

    &__label {
        @include font-size(m);
        transition: color $default-transition-speed ease 0s;
    }

    &__icon {
        fill: $color--octonary;
        // sass-lint:disable indentation
        transition: fill $default-transition-speed ease 0s,
                    transform $default-transition-speed ease 0s;
        // sass-lint:enddisable
    }

}
