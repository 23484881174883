.sticky-info {
    $root: &;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, -100%, 0);
    // sass-lint:disable indentation
    transition: transform $default-transition-speed ease 0s,
                opacity ($default-transition-speed * 0.5) ease 0.15s;
    // sass-lint:enddisable

    &.stuck {
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(0, 0%, 0);
    }

}
