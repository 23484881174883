/*------------------------------------*\
    $NEWSLETTER SIGNUP
\*------------------------------------*/

.newsletter-signup {
    padding: ($grid * 2) ($grid) ($grid * 2.5);
    background-color: $color--senary;

    &__container {
        margin: 0 auto;
        max-width: ($grid * 25);
        text-align: center;
    }

    &__heading {
        @include font-size(s);
        font-weight: 500;
        line-height: 1.3;
        color: $color--white;
        margin-bottom: ($grid * 0.25);
        padding-left: 5px;

        @include media-query(mobile) {
            @include font-size(m);
        }

    }

    &__subheading {
        @include font-size(xs);
        font-weight: 300;
        color: $color--white;
        margin-bottom: ($grid * 0.5);
        padding-left: 5px;

        @include media-query(mobile) {
            @include font-size(s);
        }

    }

    &__form {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: ($grid * 0.5);
        margin: 0 auto;

        @include media-query(tablet) {
            flex-direction: row;
        }

        @include media-query(laptop) {
            flex-wrap: nowrap;
        }

    }

    &__form-item {
        padding: 0 5px;
    }

    &__form-action {
        padding: 0 5px;
    }

    &__label {
        @include hidden;
        color: $color--white;
    }

    &__input {

        @include placeholder-text {
            color: #a9b3b9;
        }

        display: block;
        width: 100%;
        max-width: ($grid * 25);
        padding: 10px 15px;
        color: #fff;
        vertical-align: top;
        background-color: transparent;
        border: 1px solid #8a9398;
        margin-bottom: ($grid * 0.5);
        transition: background-color $default-transition-speed ease;

        @include media-query(laptop) {
            margin-bottom: 0;
        }

        &:focus {
            background-color: #24343e;
        }

    }

    &__submit {
        width: 100px;
        color: #31434f;
        cursor: pointer;
        background-color: #fff;
        border: 2px solid #fff;
        transition: background-color $default-transition-speed ease, border-color $default-transition-speed ease;

        &:hover,
        &:focus {
            background-color: #d2d2d2;
            border-color: #d2d2d2;
        }

    }

    .theme-children & {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            z-index: 0;
            top: -($grid);
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.1;
            background-image: url('../images/children/icon-grid.png');
            background-size: 25%;
            background-repeat: repeat;
        }

    }

}
