/*------------------------------------*\
    $MAIN MENU
\*------------------------------------*/

.menu-main {
  $root: &;

  .header__menu-toggle {
    background: transparent;
    border: 0;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    border-radius: 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: none;
    font-size: 16px;
    height: 53px;
    line-height: 1.71429;
    margin: 0;
    padding: 12px 16px;
    position: relative;
    text-align: center;
    text-decoration: underline;
    width: auto;
    z-index: 1;

    .icon__chevron-down {
      fill: #fff;
      height: 24px;
      transform: rotate(90deg);
    }

    &:active,
    &:focus {
      background-color: #ffeb3b;
      border-bottom: 4px solid #212b32;
      border-radius: 0;
      border-top: 4px solid #ffeb3b;
      box-shadow: none;
      box-sizing: border-box;
      color: #212b32;
      line-height: 1.71429;
      outline: 4px solid transparent;
      outline-offset: 4px;
      overflow: hidden;
      text-decoration: none;

      .icon__chevron-down {
        fill: #000000;
        transform: rotate(270deg);
      }
    }
  }

  .header__menu-toggle--visible {
    align-items: center;
    display: flex;
    visibility: visible;

    .icon__chevron-down {
      fill: #fff;
    }
  }

  .header__drop-down {
    background-color: $color--white;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-decoration: underline;

    .menu-main__item {
      border-bottom: 1px solid #f0f4f5;
      color: $color--nhs-blue;

      .menu-main__link {
        color: $color--nhs-blue;
        height: 56px;
        padding: 12px 16px;
        text-decoration: underline;
        text-decoration-color: $color--nhs-blue;

        &:active,
        &:focus {
          color: #212b32;
          text-decoration: none;

          .icon__chevron-right {
            fill: #212b32;
          }
        }
      }
    }
  }

  .header__drop-down--hidden {
    display: none;
  }

  &__container {
    align-items: center;
    display: flex;
    height: 53px;
    padding: 14px 28px 14px 12px;
    width: 100%;

    @include media-query(tablet) {
      padding: 0;
    }

    @include media-query(desktop) {
      padding: 0 42px 0 42px;
    }

    .intranet & {
      justify-content: flex-end !important;
      padding-left: 20px;
    }
  }

  &__item {
    line-height: 1;
    margin-bottom: 0;
    padding-left: 0;

    &:last-of-type {
      @include media-query(tablet) {
        padding-right: ($grid);
      }
    }

    &:first-of-type {
      @include media-query(tablet) {
        padding-left: ($grid);
      }
    }

    &.active-menu {
      .menu-main__label {
        color: $color--white;
      }

      .intranet & {
        .menu-main__label {
          color: #212b32;
        }
      }

      .menu-main__dropdown {
        color: $color--public-interact;
        pointer-events: auto;
        opacity: 1;
        transform: translate3d(0, 0%, 0);
        // sass-lint:disable indentation
        transition: none;
        // sass-lint:enddisable
      }

      .menu-main__icon {
        transform: rotate(0.5turn);
      }
    }

    // Custom intranet menu
    .intranet & {
      padding: 0 0 10px;
      border-right: 1px dotted $color--border;

      .menu-main__link {
        padding: 12px 12px;
      }

      // Custom styling for departments
      &:nth-child(3) {
        .dropdown-menu__link-label {
          max-width: 155px;
        }

        .dropdown-menu__links {
          flex-basis: 100%;
          min-width: 100%;
        }

        .dropdown-menu__item {
          flex-basis: 20%;
          min-width: 20%;
        }

        &:active,
        &:focus {
            background-color: $color--nhs-light-yellow;
            box-shadow: none;
            border-top: 4px solid $color--nhs-light-yellow;
            border-bottom: 4px solid #212b32;
            color: #212b32;
            outline: 4px solid transparent;
            outline-offset: 4px;
            text-decoration: none;
            min-height: 53px;
        }
        
        .dropdown-menu__sub-menu {
          border-bottom: 0;
        }

        .dropdown-menu__sub-menu-item {
          border-bottom: 0;
        }

        .dropdown-menu__sub-menu-link {
          @include font-size(xxs);
          padding: 5px 0;
          border: 0;
        }
      }

      &:last-of-type {
        padding-right: 0;
        border-right: 0;
      }
    } // End custom intranet menu

    // Active state of items
    &--active {
      #{$root}__link {
        .intranet & {
          color: $color--octonary;
        }
      }
    }

    .menu-main__link {
        &:hover {
            color: #fff;
            text-decoration: none;
          }
    }
    
    .menu-main__link:visited {
      text-decoration-color: $color--white;
      color: $color--white;

      &:active,
      &:focus {
        color: #212b32;
      }
    }

    // Dropdown initialisation
    @include media-query(tablet) {
      #{$root}__dropdown {
        visibility: hidden;
      }

      &:hover {
        #{$root}__dropdown {
          visibility: visible;
          pointer-events: auto;
          opacity: 1;
          transform: translate3d(0, 0%, 0);
          transition: opacity ($default-transition-speed * 0.5) ease 0s;
        }
      }

      &:focus-within {
        #{$root}__dropdown {
          visibility: visible;
          pointer-events: auto;
          opacity: 1;
        }
      }
    }
  }

  &__link {
    align-items: center;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    color: $color--white;
    display: flex;
    flex-direction: row;
    font-family: $font--primary;
    font-size: 16px;
    font-weight: 500;
    height: 53px;
    line-height: 1.71429;
    margin-bottom: 0;
    padding: 0 16px 0 16px;
    text-decoration: underline;
    text-wrap: none;
    white-space: nowrap;

    .intranet & {
      font-size: 19px;
    }

    @include media-query(laptop) {
      min-height: 21px; // height of icon
    }

    &:active,
    &:focus {
      background-color: #ffeb3b;
      box-shadow: none;
      border-top: 4px solid #ffeb3b;
      border-bottom: 4px solid #212b32;
      color: #000;
      outline: 4px solid transparent;
      outline-offset: 4px;
      text-decoration: none;

      .menu-main__label {
        color: #212b32;
      }

      .menu-main__icon {
        fill: #212b32;
      }
    }

    .intranet & {
      color: #231f20;
      text-decoration: none;

      &:visited {
        color: #231f20;
      }
      &:hover {
        color: $color--intranet-interact;
      }
    }
  }

  &__icon {
    display: none;
    fill: $color--white;
    margin-left: 5px;
    vertical-align: middle;
    transition: fill $default-transition-speed ease 0s;

    @include media-query(desktop) {
      display: inline;
    }
  }

  .intranet &__icon {
    fill: $color--tertiary;
  }

  &__dropdown {
    pointer-events: none;
    position: absolute;
    left: 0;
    margin: 0 auto;
    max-width: $max-width;
    opacity: 0;
    right: 0;
    transition: opacity $default-transition-speed ease 0.15s;
    z-index: 4;

    .intranet & {
      padding-top: 14px;
    }
  }

  // Mobile secondary menu
  .mobile-secondary-menu__more-button-container {
    align-items: center;
    border: 0;
    border-radius: 0;
  }

  .mobile-secondary-menu__more-button {
    align-items: center;
    background-color: transparent;
    border: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    display: flex;
    height: 59.42px;
    justify-content: space-between;
    padding-right: 0;
    width: 100%;

    &:active,
    &:focus {
      background-color: #ffeb3b;
      box-shadow: none;
      border: 0;
      border-top: 4px solid #ffeb3b;
      border-bottom: 4px solid #212b32;
      color: #000;
      outline: 4px solid transparent;
      outline-offset: 4px;
      text-decoration: none;
    }
  }

  .icon__chevron-right {
    display: inline-block;
    fill: #aeb7bd;
    margin-right: 6px;
    vertical-align: middle;
  }

  .mobile-secondary-menu {
    display: none;

    .menu-main__link {
      background-color: white;
      text-decoration: none;

      &:active,
      &:focus {
        background-color: #ffeb3b;
        border: 0;
        border-top: 4px solid #ffeb3b;
        border-bottom: 4px solid #212b32;
        box-shadow: none;
        color: #000;
        outline: 4px solid transparent;
        outline-offset: 4px;
        text-decoration: none;
      }
    }

    .menu-main__button-text {
      color: #212b32;
      font-weight: bold;
      padding: 0 0 0 30px;
    }

    .mobile-secondary-menu-chevron {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      height: 40px;
      left: 8px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      top: 8px;
      white-space: nowrap;
      width: 40px;
    }

    &--visible {
      display: block;
    }
  }
}
