.inline-image {
    $root: &;
    margin-bottom: ($grid * 2);

    &__container {
        display: block;
        margin: 0;
        text-decoration: none;

        &:hover {

            #{$root}__full-size-label {
                color: $color--nhs-dark-pink;

                .intranet & {
                    color: $color--intranet-interact;
                }
            }

            #{$root}__full-size-icon {
                fill: $color--nhs-dark-pink;
            }
        }
    }

    &__item {
        display: block;
        height: auto;
        width: 100%;
    }

    &__caption {
        @include font-size(s);
        border-top: 6px solid $color--quinary;
        color: $color--black;
        font-weight: 700;
        padding-top: ($grid * 0.5);
    }

    &__caption-text {
        border-right: 1px dotted $color--border;
        margin-right: ($grid * 0.5);
        padding-right: ($grid * 0.5);
    }

    &__full-size {
        @include font-size(xs);
        align-items: center;
        display: flex;
        font-weight: 500;
        text-decoration: none;
    }

    &__full-size-icon {
        fill: $color--nhs-blue;
        margin-right: ($grid * 0.25);
        transition: fill $default-transition-speed ease 0s;

        .intranet & {
            fill: $color--intranet-interact;
        }

    }

    &__full-size-label {
        color: $color--nhs-blue;
        display: inline-block;
        transition: color $default-transition-speed ease 0s;
    }

}
