.slideshow {
    width: 100%;
    overflow: hidden;
    margin-bottom: ($grid);

    &__container {
        position: relative;
    }

    &__item {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            z-index: 2;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: ($grid * 15);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0) 54%, rgba(0, 0, 0, 0.8) 100%);
        }

    }

    &__image {
        display: block;
        width: 100%;
        height: auto;
    }

    &__caption {
        @include font-size(s);
        position: absolute;
        z-index: 4;
        bottom: ($grid);
        left: 0;
        padding-left: ($grid);
        padding-right: ($grid);
        max-width: 100%;
        color: $color--white;

        @include media-query(tablet) {
            // bottom: ($grid * 2);
            // left: ($grid * 12);
            bottom: ($grid);
            left: ($grid);
            padding: 0;
            padding-right: ($grid * 4);
            right: auto;
            max-width: 800px;
        }

    }

    .col__article & {
        // margin-left: -($grid);
        // margin-right: -($grid);
        //
        // @include media-query(tablet) {
        //     margin-left: -($grid * 12);
        //     margin-right: -($grid * 17);
        // }

    }

}
