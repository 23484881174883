/*------------------------------------*\
    $BASE DEFAULTS
\*------------------------------------*/

// Box Sizing
*,
*::before,
*::after {
    box-sizing: border-box;
}

// Prevent text size change on orientation change.
// sass-lint:disable no-vendor-prefixes
html {
    font-family: $font--primary;
    -webkit-text-size-adjust: 100%;
    background-color: $color--page-background;
}

body {
    overflow-x: hidden;
    padding-bottom: 0;
    max-width: $max-width;
    margin: 0 auto;
    background-color: $color--white;

    &.fixed {
        overflow: hidden;
    }
}

// Prevent empty space below images appearing
img,
svg {
    vertical-align: top;
}

img {
    max-width: 100%;
    height: auto;
}

// sass-lint:disable single-line-per-selector
button,
input,
select,
textarea {
    font-family: inherit;
}
// sass-lint:enddisable

ul,
ol {
    padding: 0;
    margin: 0;
    list-style: none;
}

hr {
    border-top: 0;
    border-bottom: 1px solid #90a8ad;
    margin: 10px 0;
    clear: both;
}
