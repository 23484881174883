.accordion {
    $root: &;
    margin-bottom: ($grid);

    @include media-query(laptop) {
        margin-bottom: ($grid * 1.5);
    }

    &__item {
        border-bottom: 1px dotted darken($color--border, 20%);

        &:hover {

            #{$root}__heading {
                color: $color--nhs-dark-pink;

                .intranet & {
                    color: $color--intranet-interact;
                }

            }

        }

        &:first-child {
            border-top: 1px dotted darken($color--border, 20%);
        }

        &.open {

            #{$root}__icon {
                transform: rotate(180deg);
            }

            #{$root}__content {
                max-height: 9999px;
                height: auto;
                opacity: 1;
                pointer-events: auto;
                visibility: visible;
                transition: visibility 0s;
            }

            #{$root}__heading {
                color: $color--nhs-blue;

                .intranet & {
                    color: $color--intranet-interact;
                }

            }

        }

    }

    &__content {
        max-height: 0;
        pointer-events: none;
        background-color: $color--card;
        overflow: hidden;
        height: auto;
        opacity: 0;
        visibility: hidden;
        // sass-lint:disable indentation
        transition: visibility 0ms ease $default-transition-speed,
                    max-height $default-transition-speed ease 0s,
                    opacity ($default-transition-speed) ease 0s;
        // sass-lint:enddisable

        .js-map {
            margin-bottom: ($grid);
        }

    }

    &__content-container {
        padding: ($grid) ($grid) 0;
        overflow: hidden;
    }

    &__header {
        display: flex;
        align-items: center;
        padding: ($grid * 0.5) 0;
        cursor: pointer;
    }

    &__heading {
        @include font-size(m);
        font-weight: 700;
        margin-bottom: 0;
        color: $color--black;
        transition: color $default-transition-speed ease 0s;
    }

    &__heading-meta {
        font-weight: 500;
    }

    &__icon {
        fill: $color--link;
        margin-right: ($grid * 0.25);
    }

    &__action {
        margin-bottom: ($grid);
    }

    .expertise & {

        #{$root}__heading {
            @include font-size(s);
        }

    }

}
