.charity {
    .featured-casestudy {
        $root: &;
        &__item-image {
            border-radius: 0;

            @include media-query(laptop) {
                height: 100%;
                width: auto;
                object-fit: cover;
            }
        }

        &__container {
            margin-bottom: 40px;
            @include media-query(laptop) {
                margin-bottom: ($grid * 1.5);
            }
        }
        &__hero {
            margin-bottom: $grid;
            @include media-query(laptop) {
                margin-bottom: 0;
            }
        }
        &__item-heading {
            margin-bottom: 0;
            @include media-query(laptop) {
                margin: 0 0 ($grid * 0.75);
            }
        }

        &.col__halves {
            margin-bottom: 0;

            .featured-casestudy__hero {
                width: 100%;
                height: 100%;

                .featured-casestudy__container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    margin: 0;

                    .featured-casestudy__item-image {
                        object-fit: cover;
                        margin-bottom: 0;
                    }

                    .featured-casestudy__item-heading{
                        margin-bottom: 0;
                    }
                }
            }
        }

        &__item-summary {
            display: none;

            @include media-query(laptop) {
                display: block;
            }
        }
    }
}
