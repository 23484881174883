/*------------------------------------*\
    $BUTTONS
\*------------------------------------*/

.charity {
    .btn {
        @include charity-font-size(xs);
        display: inline-block;
        padding: ($grid * 0.5) ($grid);
        overflow: hidden;
        font-weight: bold;
        background-color: $color--white;
        color: $color--charity-link;
        text-align: center;
        vertical-align: top;
        text-decoration: none;
        cursor: pointer;
        border: 0;
        // sass-lint:disable indentation
        transition: background-color $default-transition-speed ease 0s,
        border-color $default-transition-speed ease 0s;
        // sass-lint:enddisable
        &:focus {

            background-color: $color--nhs-light-yellow;
            color: #212b32;
        }
        &--primary {
            background-color: $color--charity-pink-light;
            color: $color--white;
            position: relative;
            margin-left: 20px;
            overflow: visible;
            z-index: 1;

            &::before {
                content: "";
                width: 12px;
                height: 100%;
                background-color: $color--charity-accent;
                display: block;
                position: absolute;
                top: 0;
                left: -20px;
                z-index: 1;

                transition: width 0.3s;
            }
            &::after {
                content: "";
                height: 100%;
                background-color: rgba($color--charity-pink-light, 0.25);
                display: block;
                position: absolute;
                top: 6px;
                left: -10px;
                right: -5px;
                z-index: -1;
            }

            &:hover {
                color: $color--white;
                background-color: $color--charity-pink-dark;
                text-decoration: underline;
                &::before {
                    width: 20px;
                }
                &::after {
                    background-color: rgba($color--charity-pink-dark, 0.5);
                }
            }
        }
        &--secondary {
            background-color: $color--charity-pink-light;
            color: $color--white;
            border-radius: 0;
            border: none;
            font-weight: 700;

            &:hover {
                background-color: $color--charity-pink-dark;
                text-decoration: underline;
            }
        }
    }

    .search__btn {
        background-color: $color--charity-link;
    }

    .charity-campaign__link {
        &, &:hover {
            text-decoration: underline;
        }

        img {
            margin-right: 10px;
        }
    }
}
