.iframe {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    margin-bottom: ($grid);

    &__item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

}

.responsive-object {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    margin-bottom: ($grid);

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

}
