.resource-page {
    .col__three-quarters {
        margin-bottom: 20px;
    }

    .resource-details {
        @include media-query(tablet-max) {
            display: none;
        }

        background-color: $color--secondary;
        padding: ($grid * 0.75) ($grid * 0.5);

        &__item {
            &:not(:last-child) {
                margin-bottom: 20px;   
            }
        }

        &__label {
            display: block;
            color: $color--quinary;
            font-weight: 700;
        }
    }

    .resource-document {
        border-top: 1px solid $color--resource-border;
        padding-top: 50px;

        &::after {
            content: "";
            display: block;
            clear: both;
        }

        &__icon-container {
            float: left;
            padding: 0 0 0 5px;
        }

        // Lifted from https://components.publishing.service.gov.uk/component-guide/attachment/with_number_of_pages
        &__icon {
            margin-right: 30px;
            width: auto;
            max-width: 99px;
            height: 140px;
            border: rgba(11,12,12,0.1);
            outline: 5px solid rgba(11,12,12,0.1);
            background: #fff;
            box-shadow: 0 2px 2px rgb(11 12 12 / 40%);
            fill: #b1b4b6;
            stroke: #b1b4b6;
        }

        &__details {
            margin-top: -8px;
            padding-left: 134px;

            span {
                position: relative;
                margin-bottom: 15px;
                overflow-wrap: break-word;
                display: block;
            }

            a {
                font-weight: 700;
            }
        }
    }
}