.page-filters {
    $root: &;
    display: block;
    position: relative;
    z-index: 4;
    margin-left: ($grid);
    margin-right: ($grid);
    margin-bottom: ($grid);

    @include media-query(laptop) {
        display: inline-block;
    }

    &__container {
        margin-left: auto;
        margin-right: auto;
        padding: ($grid);
        background-color: $color--quinary;
        max-width: $max-width;

        @include media-query(laptop) {
            position: absolute;
            top: 0;
            left: 0;
            margin-left: 0;
        }

        @include media-query(desktop) {
            padding-left: ($grid * 1.5);
        }

        .intranet & {
            background-color: lighten($color--senary, 10%);
        }

    }

    &__items {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include media-query(laptop) {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }

    }

    &__link {
        @include font-size(s);
        display: inline-block;
        color: $color--white;
        font-weight: 500;
        background-color: rgba($color--octonary, 0.8);
        border-radius: $default-border-radius;
        padding: 5px 10px;
        // sass-lint:disable indentation
        transition: color $default-transition-speed ease 0s,
                    background-color $default-transition-speed ease 0s;
        // sass-lint:enddisable

        &:hover {
            background-color: rgba($color--black, 0.4);
            color: $color--white;
        }
    }

    &__toggle {
        display: flex;
        color: $color--white;
        margin-bottom: 0;

        @include media-query(laptop) {
            display: none;
        }

        &:hover {
            color: $color--octonary;
        }

        &--on {
            display: none;
        }

    }

    &__toggle-label {
        padding-left: ($grid * 0.25);
    }

    &__toggle-icon {
        fill: $color--white;
        transition: transform $default-transition-speed ease 0s;
    }


    .col__three-quarters & {
        position: sticky;
        top: 0;
        margin-right: -($grid); // So it's fliush against the edge of the page
        margin-left: -($grid); // So it's fliush against the edge of the page

        @include media-query(laptop) {
            min-height: 98px; // height of element with dropdowns in, to stop flicker when sticky
        }

        @include media-query(desktop) {
            margin-right: 0;
            margin-left: -($grid * 1.5);
        }

    }

    // // When stuck
    // &.stuck {
    //
    //     #{$root}__placeholder {
    //
    //         @include media-query(laptop) {
    //             display: block;
    //             min-height: 98px; // height of element with dropdowns in, to stop flicker when sticky
    //         }
    //
    //     }
    //
    //     #{$root}__container {
    //         position: fixed;
    //         z-index: 1;
    //         top: 0;
    //         left: 0;
    //         right: 0;
    //         // sass-lint:disable no-important
    //         margin-left: 0 !important;
    //         // sass-lint:enddisable
    //         transition: top $default-transition-speed ease 0s;
    //         padding: ($grid / 2) ($grid);
    //
    //         @include media-query(laptop) {
    //             left: auto;
    //             right: auto;
    //             padding: ($grid);
    //         }
    //
    //     }
    //
    // }

    // Mobile toggle
    &.active {

        #{$root}__container {
            padding-bottom: ($grid);
        }

        #{$root}__items {
            display: flex;
        }

        #{$root}__toggle {
            padding-bottom: ($grid);

            &--on {
                display: inline;
            }

            &--off {
                display: none;
            }

        }

        #{$root}__toggle-icon {
            transform: rotate(180deg);
        }

    }

    // &.active.stuck {
    //
    //     #{$root}__toggle {
    //         padding-bottom: ($grid / 2);
    //     }
    //
    // }

    select {
        zoom: 1.4;
        margin-bottom: ($grid * 0.5);

        @include media-query(laptop) {
            margin-bottom: 0;
            margin-right: ($grid * 0.5);
        }

    }

}
