.twitter {

    &__container {
        margin-bottom: ($grid);
    }

    &__heading {
        @include font-size(s);
        display: block;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: ($grid * 0.75);
        padding-bottom: ($grid * 0.75);
        border-bottom: 1px dotted $color--border;
    }

}
