.listing-header {
    $root: &;
    margin-bottom: ($grid * 0.5);

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__label {
        @include font-size(m);
    }

    &__label-total {
        font-weight: 700;
    }

    &__item-container {
        display: none;

        @include media-query(desktop) {
            display: flex;
        }

    }

    &__item {
        display: flex;
        align-items: center;
        margin-right: ($grid * 0.5);
        opacity: 1;

        &:hover {

            #{$root}__item-label {
                color: $color--link-hover;
            }

            #{$root}__item-icon {
                fill: $color--link-hover;
            }

        }

        &--active {
            text-decoration: none;
            opacity: 0.7;
            cursor: default;

            #{$root}__item-label {
                color: $color--black;
            }

            #{$root}__item-icon {
                fill: $color--black;
            }

            &:hover {

                #{$root}__item-label {
                    color: $color--black;
                }

                #{$root}__item-icon {
                    fill: $color--black;
                }

            }

        }

        &:last-child {
            margin-right: 0;
        }

    }

    &__item-icon {
        fill: $color--link;
        pointer-events: none; // Prevent js classes being added to this on click
    }

    &__item-label {
        padding-left: 5px;
        pointer-events: none; // Prevent js classes being added to this on click
    }

}
