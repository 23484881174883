.js-filters, .filters {
    $root: &;
    position: relative;
    z-index: 2;
    width: 100%;

    @include media-query(laptop) {
        margin-right: 0;
        width: auto;
    }

    &--large {

        #{$root}__options-container {
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;

            @include media-query(laptop) {
                width: ($grid * 25);
            }

        }

        #{$root}__options {
            width: 100%;

            @include media-query(laptop) {
                width: ($grid * 25);
            }

        }

        #{$root}__option {
            flex-basis: 100%;
            min-width: 100%;

            @include media-query(tablet) {
                flex-basis: 50%;
                min-width: 50%;
            }

            @include media-query(laptop) {
                flex-basis: 33.3333%;
                min-width: 33.3333%;
            }

            &:first-child {
                flex-basis: 100%;
                min-width: 100%;
            }

        }

    }

    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include media-query(tablet) {
            width: auto;
        }

        @include media-query(laptop) {
            flex-direction: row;
        }

    }

    &__item {
        position: relative;
        z-index: 1;
        width: 100%;
        margin-bottom: ($grid * 0.5);

        @include media-query(laptop) {
            width: auto;
            min-width: 250px;
            margin-right: ($grid * 0.5);
            margin-left: 0;
            margin-bottom: 0;
        }

        &.active {

            @include media-query(tablet-max) {

                #{$root}__options-container {
                    pointer-events: auto;
                    opacity: 1;
                    transform: translateY(0);

                    #{$root}__icon {
                        fill: $color--black;
                    }

                }

            }

        }

        &:hover {

            @include media-query(laptop) {
                #{$root}__options-container {
                    pointer-events: auto;
                    opacity: 1;
                    transform: translateY(0);
                }

                #{$root}__icon {
                    fill: $color--black;
                }
            }

        }

    }

    &__header {
        position: relative;
        z-index: 3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        width: 100%;
        background-color: $color--white;
        padding: ($grid * 0.25) ($grid * 0.5);

        @include media-query(laptop) {
            width: auto;
            min-width: 250px;
        }
    }

    &__heading {
        @include font-size(xs);
        margin-bottom: 0;
        line-height: 1;
    }

    &__options {
        position: absolute;
        z-index: 2;
        height: 900px;
        width: 400px;
        pointer-events: none;
        margin-left: -($grid); // Allow for left shadow on container
        padding-left: ($grid); // Allow for left shadow on container
        // sass-lint:disable no-vendor-prefixes
        -webkit-mask-image: linear-gradient(to bottom, rgba($color--black, 1) 0%, rgba($color--black, 1) 100%);
        // sass-lint:enddisable
        mask-image: linear-gradient(to bottom, rgba($color--black, 1) 0%, rgba($color--black, 1) 100%);
        overflow: hidden;

        .filters__item.active & {

            @include media-query(tablet-max) {
                position: relative;
                height: 100%;
                width: auto;
                pointer-events: auto;
                mask-image: none;
                // sass-lint:disable no-vendor-prefixes
                -webkit-mask-image: none;
                // sass-lint:enddisable
            }

        }

    }

    &__options-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 2;
        opacity: 0;
        border: 1px solid $color--border;
        background-color: $color--white;
        // box-shadow: 0 0 10px rgba($color--black, 0.5);
        pointer-events: none;
        transform: translateY(-100%);
        max-height: 500px;
        overflow-y: scroll;

        @include media-query(laptop) {
            width: ($grid * 10);
            // sass-lint:disable indentation
            transition: transform $default-transition-speed ease 0s,
                        opacity ($default-transition-speed * 0.25) ease 0s;
            // sass-lint:enddisable

        }

        @include media-query(tablet-max) {
            position: relative;
            max-height: 40vh;
            overflow-y: scroll;
        }

    }

    &__icon {
        fill: $color--tertiary;
        border-left: 2px dotted $color--border;
        padding-left: ($grid * 0.25);
        margin-left: ($grid * 0.5);
    }

    &__option {
        @include font-size(xxs);
        background-color: transparent;
        padding: ($grid * 0.25) ($grid * 0.5);
        border: 0;
        border-bottom: 1px dotted $color--border;
        text-align: left;
        // sass-lint:disable indentation
        transition: background-color $default-transition-speed ease 0s,
                    color $default-transition-speed ease 0s;
        // sass-lint:enddisable

        &:hover,
        &.active {
            background-color: $color--octonary;
            color: $color--white;
        }

    }

}
