/*------------------------------------*\
    $TYPOGRAPHY
\*------------------------------------*/

/* ============================================
    Base font
*/
html {
    font-size: $base-font-size;
    line-height: $base-line-height;
    color: $color--default;
    // sass-lint:disable no-vendor-prefixes
    -webkit-font-smoothing: antialiased;
    // sass-lint:enddisable
}

/*------------------------------------*\
    $FONTS
    https://www.england.nhs.uk/nhsidentity/identity-guidelines/fonts/
\*------------------------------------*/

@font-face {
    font-family: "Frutiger";
    src: url("../fonts/FrutigerLight.woff") format("woff");
    font-weight: 300;
}

@font-face {
    font-family: "Frutiger";
    src: url("../fonts/FrutigerRoman.woff") format("woff");
    font-weight: 500;
}

@font-face {
    font-family: "Frutiger";
    src: url("../fonts/FrutigerBold.woff") format("woff");
    font-weight: 700;
}

/* ============================================
    Families – one mixin per typeface
    :: For each font mixin defined here, make sure each property is negated (set
    :: to its default value, usually `inherit`) in all other font mixins.
*/
@mixin heading-text {
    font-family: $font--primary;
    font-style: inherit;
    font-weight: 700;
    line-height: 1.2;
}

@mixin body-text {
    font-family: $font--primary;
    font-style: inherit;
    font-weight: 500;
    text-transform: inherit;
}

@mixin quote-text {
    font-family: $font--secondary;
    font-style: italic;
    font-weight: 500;
    text-transform: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading-text {
    @include heading-text;
    color: $color--black;
    margin: 0 0 ($grid * 0.75);
}

html,
.body-text {
    @include body-text;
}

blockquote {
    @include quote-text;
}

p {
    margin: 0;
    margin-bottom: $grid;
}

/* ============================================
    Default sizes
*/
h1 {
    @include font-size(xl);
}
h2 {
    @include font-size(m);
}
h3 {
    @include font-size(s);
}
h4 {
    @include font-size(xs);
}
h5 {
    @include font-size(xs);
}

small {
    @include font-size(xxs);
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
