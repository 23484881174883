/*------------------------------------*\
    $VARIABLES
\*------------------------------------*/

/* ============================================
   Colours
   https://www.england.nhs.uk/nhsidentity/identity-guidelines/colours/
*/

/* Level 1: NHS Blues */
$color--nhs-blue: #005eb8;
$color--nhs-dark-blue: #003087;
$color--nhs-bright-blue: #0072ce;
$color--nhs-light-blue: #41b6e6;
$color--nhs-aqua-blue: #00a9ce;

/* Level 2: NHS Neutrals */
$color--nhs-dark-grey: #425563;
$color--nhs-mid-grey: #768692;
$color--nhs-pale-grey: #e8edee;

/* Level 3: NHS Support greens */
$color--nhs-dark-green: #006747;
$color--nhs-green: #009639;
$color--nhs-light-green: #78de20;
$color--nhs-aqua-green: #00a499;

$color_nhsuk-green: #007f3b;
$color_nhsuk-red: #d5281b;

/* Level 4: NHS Highlights */
$color--nhs-purple: #330072;
$color--nhs-dark-pink: #7c2855;
$color--nhs-pink: #ae2573;
$color--nhs-dark-red: #8a1538;
$color--emergency-services-red: #da291c;
$color--nhs-orange: #ed8b00;
$color--nhs-warm-yellow: #ffb81c;
$color--nhs-yellow: #fae100;

/* For accessibility (GNHS-134) */
$color--nhs-light-yellow: #ffeb3b;

/* standard colours */
$color--white: #fff;
$color--black: #231f20;
$color--off-white: rgba($color--white, 0.8);
$color--off-black: rgba($color--black, 0.8);

// Theme colours
$color--primary: $color--nhs-blue;
$color--secondary: $color--nhs-pale-grey;
$color--tertiary: $color--nhs-bright-blue;
$color--quaternary: $color--nhs-green;
$color--quinary: $color--nhs-dark-pink;
$color--senary: $color--nhs-dark-grey;
$color--septenary: $color--nhs-mid-grey;
$color--octonary: $color--nhs-aqua-green;
$color--nonary: $color--nhs-light-yellow;
$color--denary: $color--nhs-blue;

// Type colours
$color--default: $color--black;
$color--link: $color--primary;
$color--link-hover: darken($color--link, 15%);
$color--meta: $color--quinary;

// Social
// https://simpleicons.org/
$color--facebook: #3b5998;
$color--twitter: #1da1f2;
$color--flickr: #0063dc;
$color--youtube: #cd201f;

// Misc
$color--border: darken($color--nhs-pale-grey, 10%);
$color--border-dark: lighten($color--nhs-mid-grey, 30%);
$color--resource-border: #d8dde0;
$color--page-background: #f0f4f5;
$color--card: rgba($color--secondary, 0.5);
$color--feed-roundel: #f8f8f8;
$color--muted: #4c6272;

// Public interactions
$color--public-interact: $color--nonary;
$color--public-hover: darken($color--nonary, 10%);

// Intranet interactions
$color--intranet-interact: $color--octonary;
$color--intranet-hover: darken($color--octonary, 10%);

// Button colours
$button-colours: (
    primary: (
        bg: $color--white,
        color: $color--black,
        border: $color--border,
        hover-color: $color--white,
    ),
    secondary: (
        bg: $color--nhs-dark-grey,
        color: $color--white,
        border: $color--nhs-dark-grey,
        hover-color: $color--white,
    ),
    tertiary: (
        bg: $color--tertiary,
        color: $color--black,
        border: $color--tertiary,
        hover-color: $color--white,
    ),
    quaternary: (
        bg: $color--quaternary,
        color: $color--white,
        border: $color--quaternary,
        hover-color: $color--white,
    ),
    quinary: (
        bg: $color--quinary,
        color: $color--white,
        border: $color--quinary,
        hover-color: $color--white,
    ),
    senary: (
        bg: $color--senary,
        color: $color--white,
        border: $color--senary,
        hover-color: $color--white,
    ),
    septenary: (
        bg: $color--septenary,
        color: $color--black,
        border: $color--septenary,
        hover-color: $color--white,
    ),
    octonary: (
        bg: $color--octonary,
        color: $color--black,
        border: $color--octonary,
        hover-color: $color--white,
    ),
    nonary: (
        bg: $color--nonary,
        color: $color--white,
        border: $color--nonary,
        hover-color: $color--white,
    ),
    denary: (
        bg: $color--denary,
        color: $color--black,
        border: $color--denary,
        hover-color: $color--white,
    ),
);

/* ============================================
   Typography
   :: Default to system font stack
*/
$font--primary: "Frutiger", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$font--secondary: "Frutiger", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$font--tertiary: "Frutiger", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

$base-font-size: 16px;
$base-line-height: 1.6;
$font-sizes: (
    // standard sizes
    xxl: 68px,
    xl: 42px,
    l: 28px,
    m: 24px,
    s: 18px,
    xs: 16px,
    xxs: 14px,
    xxxs: 12px,
    xxxxs: 11px,
    xxxxxs: 9px
);

/* ============================================
    Wrappers and grids
*/
$grid: 28px;
$max-width: 1280px;

/* ============================================
   Breakpoints
*/
$breakpoints: (
    "wide" "(min-width: 1300px)",
    "desktop" "(min-width: 1100px)",
    "laptop" "(min-width: 900px)",
    "tablet" "(min-width: 800px)",
    "mobile" "(min-width: 650px)",
    "mobile-small" "(min-width: 440px)",
    "tablet-max" "(max-width: 899px)"
);

/* ============================================
    Transition speeds
*/
$default-transition-speed: 300ms;

/* ============================================
    Border radius
*/

$default-border-radius: 3px;

/* ============================================
    Custom select menu styling
*/
$select-height: 30px;

/* ============================================
    Unfortunate magic numbers
*/

// mobile nav
$mobile-menu-height: 55px;

// site wide search bar
$header-search-height: 90px;
