/*------------------------------------*\
    $HERO
\*------------------------------------*/
.charity {
    .charity-hero {
        $root: &;
        position: relative;

        margin-bottom: 0;
        margin-top: 0;

        @include media-query(laptop) {
            height: 30vh;
            min-height: 500px;
        }

        &__image {
            height: 300px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin-bottom: 0;
            margin-top: 0;
            @include media-query(laptop) {
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            .charity-cta__link {
                display: inline-block;
                position: relative;
                top: -26px;
                left: $grid;

                @include media-query(laptop) {
                    display: none;
                }
                @include media-query(mobile) {
                    left: $grid * 2;
                }
            }
        }
        &__container {
            @include site-content-container;
            height: 100%;
            overflow: hidden;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-end;
        }
        &__heading-wrapper {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            background: rgba($color--white, 0.96);
            padding: 30px;
            z-index: 1;
            @include media-query(laptop) {
                max-height: 90%;
                width: 40%;
                max-width: 60%;
                min-width: 40%;
                flex: auto 1 1;
            }
            @include media-query(mobile) {
                padding: $grid * 2;
            }

            .charity-cta__link {
                display: none;
                @include media-query(laptop) {
                    display: inline-block;
                }
            }
        }
        &__heading {
            font-size: 40px;
            color: $color--charity-pink-light;
            letter-spacing: 0;
            line-height: 1.1;
            @include media-query(mobile) {
                font-size: 3.2rem;
            }
        }
        &__intro {
            @include charity-font-size(s);
            line-height: 30px;
            color: $color--charity-text;
            letter-spacing: 0;
            margin-bottom: $grid;
        }
    }
}
