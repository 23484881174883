// sass-lint:disable no-vendor-prefixes
.progress-bar {
    position: relative;
    vertical-align: top;
    width: 100%;
    height: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    background-color: transparent;

    &::-webkit-progress-bar {
        background-color: transparent;
    }

    &::-webkit-progress-value {
        background-color: $color--primary;
    }

    &__container {
        width: 100%;
        background-color: transparent;
        position: fixed;
        top: 0;
        left: 0;
        display: block;
    }

    &__bar {
        background-color: $color--primary;
        width: 50%;
        display: block;
        height: inherit;
    }

}
// sass-lint:enddisable
