.latest {
    $root: &;

    &__container {
        margin-bottom: ($grid);

        @include media-query(tablet) {
            margin-bottom: ($grid);
        }
    }

    &__heading {
        @include font-size(s);
        display: block;
        margin-bottom: ($grid * 0.75);
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin-left: -($grid * 0.5);
        margin-right: -($grid * 0.5);

        @include media-query(tablet) {
            flex-direction: row;
            align-items: flex-start;
        }
    }

    &__news-and-feature-wrapper {
        padding-right: $grid;
        padding-left: ($grid);
        min-width: 75%;
    }

    &__news-container {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        min-width: 100%;
        margin-bottom: ($grid * 2);

        @include media-query(tablet) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__twitter-container {
        margin-bottom: ($grid);

        @include media-query(tablet) {
            flex-basis: 25%;
            min-width: 25%;
        }
    }

    &__item {
        flex-basis: 100%;
        min-width: 100%;
        max-width: 100%; // IE11 fix
        align-items: stretch;
        padding-right: ($grid * 0.5);

        @include media-query(tablet) {
            flex-basis: 50%;
            min-width: 50%;
            max-width: 50%; // IE11 fix
            padding-right: $grid;
        }

        &--twitter {
            flex-basis: 100%;
            min-width: 100%;
            max-width: 100%; // IE11 fix
            padding-left: ($grid * 0.5);
            padding-right: ($grid * 0.5);

            &:nth-child(2) {
                border-top: 0;
            }

            &:first-child {
                #{$root}__item-container {
                    border-top: 0;
                }
            }

            #{$root}__item-container {
                background-color: $color--primary;
                padding: ($grid * 0.75) ($grid) ($grid);
                margin-bottom: 0;
                border-bottom: 0;
            }

            #{$root}__item-description {
                @include font-size(s);
                color: $color--white;
            }

            #{$root}__icon {
                fill: rgba($color--white, 0.4);
                width: ($grid * 1.5);
                height: auto;
            }

            #{$root}__action-label {
                color: $color--white;
            }
        }
    }

    &__item-container {
        display: block;
        text-decoration: none;
        border-bottom: 1px dotted $color--border;
        padding: ($grid * 0.5) 0;

        &:hover {
            #{$root}__item-heading {
                color: $color--link-hover;
            }

            #{$root}__action-arrow {
                transform: scale(1.2);
            }
        }
    }

    &__item-heading {
        @include font-size(s);
        color: $color--link;
        margin-bottom: 0;
        text-decoration: underline;
        text-decoration-skip: ink;
        transition: color $default-transition-speed ease 0s;
    }

    &__item-meta {
        color: $color--quinary;
        margin-bottom: 0;
        font-weight: 300;
    }

    &__item-description {
        @include font-size(xxs);
        color: $color--default;
        text-decoration: none;
        margin-bottom: 0;
    }

    &__action {
        display: flex;
    }

    &__action-arrow {
        transition: transform $default-transition-speed ease 0s;
    }

    &__twitter-actions {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    .intranet-block & {
        #{$root}__container {
            padding-left: 0;
            padding-right: 0;
        }

        #{$root}__content {
            margin-right: -14px;
            margin-left: -14px;
        }

        #{$root}__heading {
            margin-bottom: 0;
            padding-bottom: ($grid * 0.75);
        }

        #{$root}__item-container {
            border-bottom: 0;
        }

        #{$root}__item {
            padding: 0 ($grid * 0.5);

            &:first-child,
            &:nth-child(2) {
                border-top: 0;

                #{$root}__item-container {
                    border-top: 0;
                }
            }
        }
    }
}
