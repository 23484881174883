/*------------------------------------*\
    $TYPOGRAPHY
\*------------------------------------*/

/* ============================================
    Base font
*/
.charity {
    color: $color--charity-text;
}

/*------------------------------------*\
    $FONTS
\*------------------------------------*/
/*------------------------------------*\
    $FONTS
    https://www.england.nhs.uk/nhsidentity/identity-guidelines/fonts/
\*------------------------------------*/

@font-face {
    font-family: 'Urbane';
    src: url('../fonts/Urbane-Thin.woff') format('woff');
    font-weight: 100;
}

@font-face {
    font-family: 'Urbane';
    src: url('../fonts/Urbane-Light.woff') format('woff');
    font-weight: 300;
}

@font-face {
    font-family: 'Urbane';
    src: url('../fonts/Urbane-Medium.woff') format('woff');
    font-weight: 500;
}

@font-face {
    font-family: 'Urbane';
    src: url('../fonts/Urbane-DemiBold.woff') format('woff');
    font-weight: 600;
}

@font-face {
    font-family: 'Urbane';
    src: url('../fonts/Urbane-Bold.woff') format('woff');
    font-weight: 700;
}

/* ============================================
    Default sizes
*/
h1 {
    font-size: 40px;
    @include media-query(mobile) {
        @include charity-font-size(xl);
    }
}
h2 { @include charity-font-size(m); }
h3 { @include charity-font-size(s); }
h4 { @include charity-font-size(xs); }
h5 { @include charity-font-size(xs); }


/* ============================================
    Families – one mixin per typeface
    :: For each font mixin defined here, make sure each property is negated (set
    :: to its default value, usually `inherit`) in all other font mixins.
*/
@mixin charity-heading-text {
    font-family: $font--charity-primary;
    font-style: inherit;
    font-weight: 700;
    line-height: 1.2;
}

@mixin charity-body-text {
    font-family: $font--charity-primary;
    font-style: inherit;
    font-weight: 400;
    text-transform: inherit;
}

@mixin charity-quote-text {
    font-family: $font--charity-primary;
    font-style: inherit;
    font-weight: 700;
    text-transform: inherit;
}

.charity {
    @include charity-body-text;
    .body-text {
        @include charity-body-text;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .heading-text {
        @include charity-heading-text;
        color: $color--charity-heading;
    }

    blockquote {
        @include charity-quote-text;
    }

    .featured-casestudy__item {
        text-decoration: none;
        width: 100%;
        &:focus {
            img, h2 {
                background-color: $color--nhs-light-yellow;
                box-shadow: 0 -2px $color--nhs-light-yellow, 0 4px #212b32;
                color: #212b32;
                outline: 4px solid transparent;
                text-decoration: none;
            }
        }
    }

    h2.featured-casestudy__item-heading {
        font-size: 20px;
        color: $color--charity-blue-dark;
        line-height: 30px;
    }

    a.featured-casestudy__item .featured-casestudy__item-heading {
        text-decoration: underline;

        &:hover {
            color: $color--charity-link-hover;
            text-decoration: none;
        }
    }

    a .featured-casestudy__item-summary {
        color: #444;
        margin: 0;
    }
}
