.video-highlights {

    &__header {
        margin-bottom: ($grid);
    }

    &__heading {
        color: $color--white;
        position: relative;
        z-index: 2;
    }

    &__introduction {
        position: relative;
        z-index: 1;
        top: -($grid * 0.5);
        display: inline-block;
        background-color: $color--primary;
        color: $color--white;
        padding: ($grid * 0.25) ($grid * 0.5);
    }

}
