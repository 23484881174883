/*------------------------------------*\
    $TEASER
\*------------------------------------*/
.charity {
    .teaser {
        &__container {
            background-color: $color--charity-purple-dark;
            &:hover {
                background-color: darken($color--charity-purple-dark, 5%);
            }
        }
        &__heading {
            color: $color--white;
        }
        &__icon {
            fill: $color--white;
        }
        /* I have commented this out rather than delete because I suspect I will
         * have to come back to it at some point
         */
        // .teaser-block & {
        //     flex-basis: 100%;
        //     min-width: 100%;
        //     margin-bottom: ($grid / 2);
        //
        //     @include media-query(mobile) {
        //         flex-basis: 50%;
        //         min-width: 50%;
        //         max-width: 50%; // IE11 fix
        //         align-items: stretch;
        //         padding-left: ($grid / 4);
        //         padding-right: ($grid / 4);
        //     }
        //
        //     @include media-query(laptop) {
        //         flex-basis: 25%;
        //         min-width: 25%;
        //         max-width: 25%; // IE11 fix
        //     }
        //
        //     #{$root}__heading {
        //         font-weight: 500;
        //     }
        //
        //     // Homepage specific
        //     .template-home-page & {
        //
        //         #{$root}__container {
        //
        //             @include media-query(laptop) {
        //                 min-height: ($grid * 6.5);
        //             }
        //
        //         }
        //
        //         &:nth-child(n+7) {
        //
        //             #{$root}__heading {
        //                 color: $color--senary;
        //             }
        //
        //             #{$root}__icon {
        //                 fill: $color--senary;
        //             }
        //
        //             #{$root}__container {
        //                 background-color: $color--secondary;
        //
        //                 &:hover {
        //                     background-color: darken($color--secondary, 10%);
        //                 }
        //
        //             }
        //
        //         }
        //
        //     }
        //
        // }
    }
}
