.diagnosis {
    $root: &;

    &__description {
        margin-bottom: ($grid);
    }

    &__heading {
        @include font-size(m);
        margin-bottom: ($grid);
    }

    &__group {
        padding: ($grid) ($grid * 0.75);
        margin-bottom: ($grid);
        margin-top: ($grid);
        border: 1px dotted $color--border;
    }

    &__sub-heading {
        @include font-size(m);
        font-weight: 500;
        color: $color--black;
        margin-bottom: ($grid);
    }

    &__sub-sub-heading {
        @include font-size(xs);
        color: $color--senary;
        font-weight: 700;
        margin-bottom: ($grid * 0.5);
        margin-top: ($grid);

        &--standout {
            @include font-size(s);
            color: #000;
        }

    }

    &__list {
        margin-bottom: ($grid);
    }

    &__list-item {
        display: flex;
        margin-bottom: ($grid * 0.5);
        padding: ($grid * 0.5) ($grid * 0.75);
        background-color: rgba($color--secondary, 0.4);

        &--opaque {
            background-color: rgba($color--secondary, 1);
        }

        &:last-child {
            margin-bottom: 0;
        }

        #{$root}__list--recommended & {
            background-color: rgba($color--nhs-mid-grey, 0.8);
            font-weight: 700;
            color: $color--white;
        }

        &--transparent {
            background-color: transparent;
            padding: ($grid * 0.5) 0;
        }

    }

    &__list-item-icon-container {
        margin-right: ($grid * 0.5);
    }

    &__list-item-heading {
        @include font-size(xs);
        font-weight: 500;
        margin-bottom: 0;
        margin-top: 20px;

        &:first-of-type {
            margin-top: 0;
        }

        #{$root}__list--recommended & {
            @include font-size(s);
            margin-top: 20px;
            font-weight: 700;
            color: $color--white;

            &:first-of-type {
                margin-top: 0;
            }
        }
    }

    &__list-item-text {
        @include font-size(xxs);
        flex-basis: 100%;
        margin-bottom: 0;

        p,
        ul,
        ol,
        h3,
        h4,
        h5 {
            margin-bottom: 0;
        }

    }

}
