.sharing {
    margin-left: 0px;
    border-top: 5px solid #e7e7e9;
    padding-top: 20px;
    padding-bottom: 40px;

    .share-item {
        margin: 0 40px 15px 0;
        font-size: 14px;

        &:last-child {
            margin-bottom: 0;
        }

        @include media-query(laptop) {
            margin-right: 40px;

            &:last-child {
                margin-right: 0;
            }
        }

        a {
            color: #595959;
            font-weight: 600;
        }

        img {
            margin-right: 5px;
        }
    }
}
