/*------------------------------------*\
    $ARTICLE
    Default rich text styles
\*------------------------------------*/

.article {
    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }
}
