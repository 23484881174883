.section-header {
    margin-bottom: ($grid);

    @include media-query(tablet) {
        margin-bottom: ($grid * 2);
    }

    &__container {
        text-align: center;
        max-width: ($grid * 28);
        margin: 0 auto;
    }

    &__heading {
        @include font-size(m);
        line-height: 1;
        color: $color--black;
        letter-spacing: 3px;
        text-transform: uppercase;
        margin-bottom: ($grid * 0.25);

        @include media-query(tablet) {
            @include font-size(l);
        }

    }

    &__introduction {
        margin-bottom: 0;
    }

}
