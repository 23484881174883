.charity {
    .browse {
        // Setting $root to & will not work as expected, as it compiles to:
        // .charity .browse__item-container .charity .browse__heading:before
        // due to the nesting of .charity > .browse above
        $root: '.browse';

        &.level-one {
            margin: 0;

            .col__container {
                row-gap: $grid;
                @include media-query(mobile) {
                    row-gap: 70px;
                }
            }
        }

        &__section-heading {
            color: $color--charity-heading;
        }
        &__item {
            border: 0;
            border-radius: 0;
            transition: none;
        }
        &__item-container {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: start;
            padding: 0;

            img {
                max-height: 210px;
                width: 100%;
                object-fit: cover;
                margin-bottom: $grid * 0.5;
            }
            &:hover {
                #{$root}__heading {
                    color: $color--charity-link-hover;
                    text-decoration: none;
                }
            }
            &:focus {
                background-color: $color--nhs-light-yellow;
                box-shadow: 0 -2px $color--nhs-light-yellow, 0 4px #212b32;
                color: #212b32;
                outline: 4px solid transparent;
                text-decoration: none;
            }
        }
        &__text-container {
            padding: 0;
        }
        &__heading {
            @include charity-font-size(l);
            color: $color--charity-link;
            font-weight: 500;
        }
        &__description {
            @include rem-font-size(18px);
            color: $color--charity-text;
        }
        &__icon {
            fill: $color--charity-link;
        }
        &__action {
            color: $color--charity-link;
            &:hover {
                #{$root}__action-arrow {
                    fill: $color--charity-link-hover;
                }
            }
        }
        &__action-arrow {
            fill: $color--charity-link;
        }
        &__meta-icon {
            fill: $color--charity-link;
        }
    }
}
