/*------------------------------------*\
    $NEWSLETTER SIGNUP
\*------------------------------------*/

.charity-contact-block {
    padding: ($grid * 2) ($grid);
    background-color: $color--charity-purple-dark;
    &__container {
        margin: 0 auto;
        max-width: ($grid * 25);
        text-align: center;
    }
    &__heading {
        @include charity-font-size(s);
        font-weight: 500;
        line-height: 1.3;
        color: $color--white;
        margin-bottom: ($grid);
        @include media-query(mobile) {
            @include charity-font-size(m);
        }
    }
    &__subheading {
        @include charity-font-size(xs);
        font-weight: 300;
        color: $color--white;
        margin-bottom: ($grid);
        @include media-query(mobile) {
            @include charity-font-size(s);
        }
    }
}
