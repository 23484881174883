.multi-option {

    &__container {
        display: flex;
        padding: ($grid) ($grid * 2);
        justify-content: center;
    }

    &__item {
        display: flex;
        justify-content: center;
        flex-basis: 100%;
    }

    &__label {
        margin-right: ($grid * 0.5);
        line-height: 2;
    }

}
