.hero-form {
    height: 550px;
    margin-bottom: ($grid * 2);
    background-size: contain;
    background-position: top right;
    background-repeat: no-repeat;

    @include media-query(mobile) {
        height: 750px;
    }

    @include media-query(tablet) {
        max-height: 400px;
        background-size: cover;
        background-position: centre;
    }

    @include media-query(laptop) {
        max-height: 500px;
        background-position: centre;
    }

    @include media-query(desktop) {
        max-height: 600px;
        background-position: centre;
    }

    &__container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        height: 100%;
        padding: 0;

        @include media-query(tablet) {
            padding: ($grid * 2) ($grid);
        }

        @include media-query(laptop) {
            padding: ($grid * 2);
        }

    }

}
