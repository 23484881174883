.intranet-bar {
    transform: translate3d(0, 0, 0);
    transition: transform $default-transition-speed ease 0s;

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 ($grid * 0.5);
        background-color: $color--page-background;

        @include media-query(desktop) {
            padding-right: ($grid * 1.5);
            padding-left: ($grid * 1.5);
        }
    }

    &__label {
        @include font-size(xxs);
        font-weight: 700;
        padding: ($grid * 0.25) 0;
        text-transform: uppercase;
        color: $color--black;
    }

    &__nav {
        display: none;

        @include media-query(tablet) {
            display: block;
        }
    }

    .site-header--unpinned &,
    .site-header--pinned & {
        transform: translate3d(0, -100%, 0);
    }

    // When at the top
    .site-header--top & {
        transform: translate3d(0, 0, 0);
    }

    .menu-secondary {
        background-color: inherit;
        display: flex;

        &__item {
            padding: 0 ($grid * 0.25);
            border-right: 1px dotted $color--border;

            @include media-query(desktop) {
                padding: 0 ($grid * 0.5);
            }

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                padding-right: 0;
                border-right: 0;
            }

            &:nth-last-child(2) {
                border-right: 2px solid $color--border;
            }
        }

        &__link {
            font-weight: 300;
            color: $color--black;
        }
    }
}
