.introduction {
  $root: &;
  margin-bottom: ($grid);

  &__container {
    max-width: ($grid * 30);
  }

  &__text {
    line-height: 1.4;
    font-weight: 300;
    text-rendering: optimizeLegibility;
    // sass-lint:disable no-vendor-prefixes
    -webkit-font-smoothing: subpixel-antialiased;
    // sass-lint:enddisable
    @include nhsuk-typography-responsive(24);

    display: block;
    margin-top: 0;

    @include nhsuk-responsive-margin(5, "bottom");

    a {
      .home & {
        @include font-size(m);
        font-weight: 500;
      }
    }
  }

  &--left-aligned {
    #{$root}__container {
      text-align: left;
      margin: 0;
    }
  }

  &--block-bg {
    #{$root}__text {
      @include font-size(m);
      display: inline-block;
      padding: ($grid * 0.25) ($grid * 0.5);
      background-color: $color--secondary;
      color: $color--default;
    }

    #{$root}__container {
      // sass-lint:disable no-important
      padding-top: 0 !important;
      // sass-lint:endisable
    }
  }

  // If in standard page content column
  .col__article & {
    margin-bottom: ($grid);

    @include media-query(tablet) {
      margin-right: -($grid * 15);
    }

    #{$root}__container {
      max-width: none;
    }
  }

  // If inside hero
  .hero & {
    margin-bottom: ($grid);

    @include media-query(tablet) {
      margin-bottom: 0;
    }

    #{$root}__container {
      text-align: left;
      margin: 0;
      color: $color--white;
      padding-top: ($grid * 0.5);
    }

    #{$root}__text {
      margin-bottom: 0;
    }
  }

  // if inside hero--carousel
  .hero--carousel & {
    #{$root}__container {
      margin-left: 0;
      padding-top: 0;
    }
  }
}
