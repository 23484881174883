.back-to-top {
    display: none;
    margin-bottom: ($grid);

    @include media-query(laptop) {
        position: fixed;
        display: block;
        z-index: 2;
        bottom: 0;
        right: ($grid);
        margin-bottom: 0;
        opacity: 0;
        transform: translate3d(0, 100%, 0);
        // sass-lint:disable indentation
        transition: transform $default-transition-speed ease 0s,
                    opacity $default-transition-speed ease 0s;
        // sass-lint:enddisable
    }

    &.stuck {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    &__container {
        @include font-size(xxs);
        display: flex;
        align-items: center;
        background-color: $color--senary;
        border: 1px solid rgba($color--white, 0.2);
        border-bottom: 0;
        padding: ($grid * 0.25) ($grid * 0.5);
        text-decoration: none;
        color: $color--white;
        transition: background-color $default-transition-speed ease 0s;

        &:hover {
            color: $color--black;
            background-color: $color--public-interact;

            .back-to-top__icon {
                fill: $color--black;
            }

            .intranet & {
                background-color: $color--intranet-interact;
            }

        }

    }

    &__icon {
        fill: $color--white;
        margin-left: ($grid * 0.25);
    }
}
