.search {
  $root: &;

  &__container {
    position: relative;

    .menu-main__search & {
      padding: ($grid);
    }
  }
  .hero-search__search &__input::placeholder {
    white-space: pre-line;
  }

  &__input {
    @include font-size(xxs);
    line-height: 1em;
    min-width: 100%;
    padding: ($grid * 0.5) ($grid * 2) 10.5px ($grid * 0.5);
    width: 100%;
    resize: vertical;
    min-height: 43px;
    height: 43px;
    align-items: center;

    @include media-query(laptop) {
      @include font-size(s);
      min-width: 100%;
      height: 48px;
      resize: none;
      overflow-y: hidden;
    }

    .hero-search__search & {
      height: 47px;
      padding: ($grid * 0.5) ($grid * 2) ($grid * 0.5) ($grid * 0.5);
      border-radius: $default-border-radius;

      @include media-query(tablet) {
        padding: ($grid * 0.75) ($grid * 2) ($grid * 0.75) ($grid * 0.75);
      }

      @include media-query(laptop) {
        height: 62px;
      }
    }
  }

  &__btn {
    position: absolute;
    height: 43px;
    right: 0;
    top: 0;
    bottom: 0;
    padding-left: ($grid * 0.5);
    padding-right: ($grid * 0.5);
    background-color: $color--public-interact;
    border: 0;
    cursor: pointer;

    @include media-query(laptop) {
      height: 48px;
    }

    &:hover {
      background-color: $color--public-hover;

      .intranet & {
        background-color: darken($color--senary, 5%);
      }
    }

    .intranet & {
      background-color: $color--senary;
    }

    .menu-main__search & {
      right: 0;
    }

    .hero-search__search & {
      height: 33px;
      top: ($grid * 0.25);
      bottom: ($grid * 0.25);
      right: ($grid * 0.25);
      border-radius: $default-border-radius;
      padding-left: ($grid * 0.25);
      padding-right: ($grid * 0.25);

      @include media-query(tablet) {
        padding-left: ($grid * 0.5);
        padding-right: ($grid * 0.5);
      }

      @include media-query(laptop) {
        height: 48px;
      }
    }
  }

  &__icon {
    fill: $color--primary;
    transform: rotate(90deg);
  }
}
