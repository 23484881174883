.intranet-block {
    @include ie11 {
        flex-direction: row-reverse;
    }

    .col__container {
       @include ie11 {
           flex-direction: row-reverse;
       } 
    }
}
