.charity {

  .inline-teaser {

      &__container {

          &:hover {

              .inline-teaser__image {
                  opacity: 0.9;
              }

              .inline-teaser__header {
                  background-color: $color--charity-purple-dark;
              }

              .inline-teaser__heading {
                  color: $color--white;
              }

          }

      }

      &__image {
          opacity: 1;
          transition: opacity $default-transition-speed ease 0s;
          will-change: opacity;
      }

      &__header {
          background-color: $color--charity-purple-bg;
          transition: background-color $default-transition-speed ease 0s;

          .inline-teaser__heading {
              color: $color--off-white;
          }
      }

      &__heading {
          color: $color--off-white;
      }

  }

}
