.inline-teaser {
    $root: &;
    margin-bottom: ($grid);

    @include media-query(mobile) {
        margin-left: ($grid);
        float: right;
        width: ($grid * 11);
    }

    // Talent Dev
    &--td {
        float: none;
        margin-left: 0;
        width: 100%;
    }

    &--td-standard {
        img {
            display: block;
            margin: 0;
            width: 100%;
            height: auto;
        }
    }

    &__container {
        text-decoration: none;

        &:hover {

            #{$root}__heading {
                color: $color--public-hover;

                intranet & {
                    color: $color--intranet-hover;
                }

            }

        }

        // Talent Dev
        &--td {
            &:hover {
                #{$root}__heading {
                    color: #d6d6d6;
                }
            }

            #{$root}__header {
                background-color: #435562;

                &--td-external {
                    background-color: #984590;
                }
            }
        }

    }

    &__image-container {
        position: relative;
        background-color: $color--black;
    }

    &__image {
        display: block;
        margin: 0;
        width: 100%;
        height: auto;
    }

    &__header {
        padding: ($grid * 0.5) ($grid);
        background: $color--black;
    }

    &__heading {
        @include font-size(xs);
        font-weight: 700;
        margin: 0;
        color: $color--white;
        text-decoration: none;
        transition: color $default-transition-speed ease 0s;
    }

}
