.page-body {
  padding-right: ($grid);
  padding-left: ($grid);
  min-height: 400px;

  @include media-query(desktop) {
    padding-right: ($grid * 1.5);
    padding-left: ($grid * 1.5);
  }

  &__footer {
    margin-bottom: ($grid);
    margin-top: ($grid * 2);

    @include media-query(laptop) {
      margin-top: ($grid * 3);
    }
  }

  .template-intranet-home & {
    @include media-query(desktop) {
      padding-right: ($grid * 0.5);
    }
  }

  .rich-text {
    %nhsuk-heading-xl {
      @include nhsuk-typography-responsive(48);

      display: block;
      font-weight: $nhsuk-font-bold;
      margin-top: 0;

      @include nhsuk-responsive-margin(7, "bottom");
    }

    h1,
    .nhsuk-heading-xl {
      @extend %nhsuk-heading-xl;
      margin-bottom: 0;
    }

    %nhsuk-heading-l {
      @include nhsuk-typography-responsive(32);

      display: block;
      font-weight: $nhsuk-font-bold;
      margin-top: 0;

      @include nhsuk-responsive-margin(4, "bottom");
    }

    h2,
    .nhsuk-heading-l {
      @extend %nhsuk-heading-l;
    }

    %nhsuk-heading-m {
      @include nhsuk-typography-responsive(24);

      display: block;
      font-weight: $nhsuk-font-bold;
      margin-top: 0;

      @include nhsuk-responsive-margin(4, "bottom");
    }

    h3,
    .nhsuk-heading-m {
      @extend %nhsuk-heading-m;
    }

    %nhsuk-heading-s {
      @include nhsuk-typography-responsive(22);

      display: block;
      font-weight: $nhsuk-font-bold;
      margin-top: 0;

      @include nhsuk-responsive-margin(4, "bottom");
    }

    h4,
    .nhsuk-heading-s {
      @extend %nhsuk-heading-s;
    }

    %nhsuk-heading-xs {
      @include nhsuk-typography-responsive(19);

      display: block;
      font-weight: $nhsuk-font-bold;
      margin-top: 0;

      @include nhsuk-responsive-margin(4, "bottom");
    }

    h5,
    .nhsuk-heading-xs {
      @extend %nhsuk-heading-xs;
    }

    %nhsuk-heading-xxs {
      @include nhsuk-typography-responsive(19);

      display: block;
      font-weight: $nhsuk-font-bold;
      margin-top: 0;

      @include nhsuk-responsive-margin(4, "bottom");
    }

    h6,
    .nhsuk-heading-xxs {
      @extend %nhsuk-heading-xxs;
    }
  }
}
