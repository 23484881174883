.browse {
    $root: &;
    margin-bottom: ($grid * 2);
    margin-top: ($grid * 1);

    // Control width of columns on different levels of content
    &.level-one {

        #{$root}__column {

            &.first-column {
                @include media-query(mobile) {
                    flex-basis: 100%;
                    min-width: 100%;
                }

                @include media-query(desktop) {
                    flex-basis: 33.333%;
                    min-width: 33.333%;
                }
            }
        }
    }

    &.level-two {

        #{$root}__column {
            overflow: hidden;

            #{$root}__column-heading {
                min-width: 300px;
            }

            #{$root}__item {
                min-width: 300px;
            }

            &.first-column {
                flex-basis: 0;
                min-width: 0;

                @include media-query(mobile) {
                    flex-basis: 33.333%;
                    min-width: 33.333%;
                }
            }

            &.dynamic {
                @include media-query(laptop) {
                    flex-basis: 40%;
                    min-width: 40%;
                }

                #{$root}__column-items {
                    padding-right: 0;
                }

                #{$root}__column-heading {
                    min-width: 0;
                }

                #{$root}__item {
                    min-width: 0;
                }
            }

            &,
            &.dynamic {
                @include media-query(mobile) {
                    flex-basis: 50%;
                    min-width: 50%;
                }

                @include media-query(desktop) {
                    flex-basis: 33.333%;
                    min-width: 33.333%;
                }
            }
        }
    }

    &__container {
        display: flex;
        flex-direction: row;

        &.js-filterable {
            display: block;

            #{$root}__item {
                min-width: auto;
            }
        }
    }

    &__column {
        flex-basis: 100%;
        min-width: 100%;
        transition: flex-basis $default-transition-speed ease-in-out 0s,
                    min-width $default-transition-speed ease-in-out 0s;

        @include media-query(mobile) {
            flex-basis: 33.333%;
            min-width: 33.333%;
        }

        &:last-child {
            #{$root}__column-items {
                padding-right: 0;
            }
        }

        &.dynamic {
            display: none;

            &.active {
                display: block;
            }
        }

        &.first-column {
            z-index: 1;
        }

        &.second-column {
            &.active {
                @include media-query(mobile) {
                    border-left: 2px solid $color--nhs-pale-grey;
                    margin-left: -2px;
                }
            }

            #{$root}__column-heading {
                @include media-query(mobile) {
                    margin-left: ($grid * 2);
                }
            }
        }
    }

    &__column-heading {
        @include font-size(m);
        font-weight: 500;

        @include media-query(mobile) {
            margin-left: ($grid * 1.25);
        }
    }

    &__column-items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;
        padding-bottom: ($grid * 2);
        height: calc(100% - 50px);

        @include media-query(mobile){
            padding-right: ($grid * 0.5);
        }

        .second-column & {
            @include media-query(mobile){
                padding-left: ($grid * 0.75);
            }
        }
    }

    &__item {
        border: 2px solid transparent;
        flex-basis: 100%;
        min-width: 100%;
        overflow: hidden;
        transition: border-color $default-transition-speed ease 0s;
        position: relative;

        &.active {
            border-top: 2px solid $color--nhs-pale-grey;
            border-bottom: 2px solid $color--nhs-pale-grey;
            border-left: 2px solid transparent;
            border-right: 2px solid $color--white;

            &::before {
                background-color: $color--nhs-bright-blue;
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                width: 7px;
            }

            #{$root}__heading {
                text-decoration: none;
                margin-bottom: 0;
            }

            #{$root}__description {
                font-weight: 600;
            }
        }

        &:hover {
            // sass-lint:disable no-important
            opacity: 1 !important;
            // sass-lint:enddisable
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__item-container {
        display: flex;
        height: 100%;
        flex-direction: row;
        text-decoration: none;
        align-items: center;
        justify-content: space-between;
        transition: background-color $default-transition-speed ease 0s;
        border-bottom:  4px solid transparent;
        @include media-query(mobile) {
            padding-left: ($grid * 0.5);
            padding-right: ($grid * 0.5);
        }

        &:hover {
            #{$root}__video-icon {
                transform: scale(1.2);
                fill: $color--white;
            }
        }

        &:focus {
            background-color: $color--nhs-light-yellow;
            border-bottom:  4px solid #212b32;
            color: #212b32;
            outline: 4px solid transparent;
            text-decoration: none;
        }
    }

    &__image-container {
        margin-bottom: ($grid * 0.5);
    }

    &__image {
        display: block;
        width: 100%;
        height: auto;
    }

    &__text-container {
        text-align: left;
        padding: ($grid * 0.75) 0;

        @include media-query(mobile) {
            padding: ($grid * 0.75);
        }
    }

    &__heading {
        @include font-size(s);
        line-height: 1.2;
        text-decoration: underline;
        text-decoration-skip: ink;
        color: $color--link;
        margin-bottom: 0;
        transition: color $default-transition-speed ease 0s;
    }

    &__description {
        @include font-size(xs);
        color: $color--default;
        margin-bottom: 0;
        margin-top: 5px;
    }

    &__icon {
        fill: $color--primary;
        flex-basis: ($grid * 1.25);
        min-width: ($grid * 1.25);

        @include media-query(mobile){
            margin-right: ($grid * 0.5);
        }
    }

    &__action {
        @include font-size(m);
        display: flex;
        align-items: center;
        color: $color--black;

        &:hover {

            #{$root}__action-arrow {
                fill: $color--black;
                transform: scale(1.2);
            }
        }
    }

    &__action-arrow {
        fill: $color--octonary;
        // sass-lint:disable indentation
        transition: fill $default-transition-speed ease 0s,
                    transform $default-transition-speed ease 0s;
        // sass-lint:enddisable
    }

    &__meta {
        display: flex;
        padding: ($grid * 0.25) 0;
        justify-content: center;

        @include media-query(mobile) {
            justify-content: flex-start;
        }
    }

    &__meta-item {
        display: flex;
        align-items: center;
        line-height: 1;
        margin-right: ($grid * 0.5);
    }

    &__meta-icon {
        margin-right: ($grid * 0.25);
        fill: $color--octonary;
    }

    &__meta-label {
        @include font-size(s);
    }

    &__section-heading {
        @include font-size(m);
        font-weight: 500;

        @include media-query(mobile) {
            margin: ($grid * 1.5) 0  ($grid * 1) ($grid * 1.25);
        }
    }
}
