.body,
.rich-text,
.profile__body {

    ul,
    ol {
        margin-bottom: ($grid);
        padding-left: ($grid * 0.75);
    }

    ul {
        list-style-type: square;
    }

    ol {
        list-style-type: decimal;
    }

}
