.modal-content {
    position: relative;
    z-index: 1;
    max-width: 1000px;
    margin: auto;
    box-shadow: 0 0 ($grid * 0.5) 0 rgba($color--black, 0.8);

    &__container {
        padding: ($grid * 2);
    }

}

.fancybox-bg {
    background-color: rgba($color--black, 0.6);
    background-size: cover;
}
