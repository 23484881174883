.skip-link {
    position: absolute;
    left: -9999px;
}

.nhsuk-skip-link {
    left: -9999px;
    padding: 8px;
    position: absolute;
}

.nhsuk-skip-link:active,
.nhsuk-skip-link:focus {
    left: 16px;
    top: 16px;
    z-index: 2;
}

.nhsuk-skip-link:visited {
    color: #212b32
}   