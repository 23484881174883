/*------------------------------------*\
    $MIXINS
\*------------------------------------*/


/* ============================================
    Media queries
*/
@mixin media-query($queries...) {
    @each $query in $queries {
        @each $breakpoint in $breakpoints {
            $name:        nth($breakpoint, 1);
            $declaration: nth($breakpoint, 2);

            @if $query == $name and $declaration {
                @media only screen and #{$declaration} {
                    @content;
                }
            }
        }
    }
}


/* ============================================
  Placeholder text
*/
@mixin placeholder-text {
    // sass-lint:disable no-vendor-prefixes
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
    // sass-lint:enddisable
}


/* ============================================
  Hide text
*/
@mixin hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}


/* ============================================
  iOS Native vertical scroll
*/
@mixin native-vertical-scroll {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-misspelled-properties
}


/* ============================================
    Output a rem and px fallback value for the given property
*/
@mixin rem($property, $values) {

    $px:  ();
    $rem: ();

    @each $value in $values {
        @if $value == 0 or $value == auto or $value == inherit {
            $px:  append($px, $value);
            $rem: append($rem, $value);
        } @else {
            $px:  append($px, $value);
            $rem: append($rem, rem(strip-unit($value)));
        }
    }
    // sass-lint:disable no-duplicate-properties
    #{$property}: $px;
    #{$property}: $rem;
    // sass-lint:enddisable
}


/* ============================================
    Output a `font-size: [x]rem;` declaration for the given px value
*/
@mixin rem-font-size($font-size) {
    @include rem(font-size, $font-size);
}


/* ============================================
    Font sizes
*/
@mixin font-size($keyword) {
    $size: map-get($font-sizes, $keyword);

    @if $size == null {
        @warn 'Font size ‘#{$keyword}’ does not exist';
    } @else {
        @include rem-font-size($size);
    }
}

/* ============================================
    Flex mixins
*/
@mixin flex-container {
    margin-left: 0;
    margin-right: 0;

    @include media-query(tablet) {
        margin-left: -($grid);
        margin-right: -($grid);
    }

}


/* ============================================
    Target IE 10 and IE 11
*/
@mixin ie11 {
    @media all and (-ms-high-contrast: none) {
        @content;
    }
}
