.footer-credits {
    position: relative;
    padding: 35px 0 ($grid * 0.5) 0;
    background-color: $color--page-background;
    color: $color--off-white;
    overflow: hidden;

    &__logo {
        width: 8rem;
        height: 4rem;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 6rem;
        gap: ($grid);
        margin-left: $grid;
        margin-right: 0px;
        flex-direction: column;

        @include media-query(tablet) {
            margin-left: ($grid * 0.25);
            margin-right: ($grid * 0.25);
            flex-direction: row;
        }

        @include media-query(wide) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__links {
        @include font-size(xxs);
        display: flex;
        flex-direction: column;

        @include media-query(tablet) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__item:not(:last-child) {
        color: $color--default;
        margin-right: ($grid * 0.5);
    }

    &__link {
        color: $color--black;

        &:hover {
            color: #00376c;
        }
    }
}
