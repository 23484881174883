.charity {
    .form__recaptcha-wrapper {
        transform: scale(0.7);
        transform-origin: 0 0;

        @include media_query(mobile) {
            transform: scale(1);
        }
    }
}
