@use "sass:math";

.beta-banner {
    @include font-size(xs);
    display: none; // Doesn't display unless there isn't a cookie

    // Applied if cookie just added
    &.inactive {
        display: none;
    }

    &.active {
        display: block;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #443636;
        background-color: $color--nhs-light-yellow;
        padding: math.div($grid, 2.8) $grid;

        @include media-query(desktop) {
            padding: math.div($grid, 2.8) ($grid * 1.5);
        }

        .intranet & {
            padding: math.div($grid, 2.8) ($grid * 0.5);

            @include media-query(desktop) {
                padding-right: ($grid * 1.5);
                padding-left: ($grid * 1.5);
            }
        }
    }

    &__message {
        p {
            margin-bottom: 0;
            font-weight: 500;
        }
    }

    &__link {
        color: #443636;

        &:hover {
            color: $color--nhs-dark-pink;
        }
    }

    &__btn {
        @include font-size(xxs);
        color: #443636;
        padding: 0;
        border: 0;
        line-height: 1.8;
        font-weight: 500;
        transition: color $default-transition-speed ease 0s;

        &:hover {
            color: $color--nhs-dark-pink;
        }
    }
}
