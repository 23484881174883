.actions {
    $root: &;
    margin-bottom: ($grid * 2);

    &__container {
        display: flex;
        flex-direction: row;
        border-top: 6px solid $color--border;
    }

    &__item {
        flex-basis: 50%;
        min-width: 50%;
        padding: ($grid * 0.5) ($grid * 0.75);
        border-right: 2px dotted $color--border;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            border-right: 0;
        }

    }

    &__link {
        @include font-size(xs);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        color: $color--black;

        @include media-query(tablet) {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }

        &:hover {
            color: $color--octonary;

            #{$root}__icon {
                transform: scale(1.2);
            }

        }

    }

    &__label {
        line-height: 1;
        text-align: center;

        @include media-query(tablet) {
            text-align: left;
        }

    }

    &__icon {
        margin-right: ($grid * 0.25);
        transition: transform $default-transition-speed ease 0s;
        margin-bottom: ($grid * 0.25);

        @include media-query(tablet) {
            margin-bottom: 0;
            margin-right: ($grid * 0.5);
        }

        &--facebook {
            fill: $color--facebook;
        }

        &--twitter {
            fill: $color--twitter;
        }

    }

}
