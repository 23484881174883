.charity {
    .dropdown-menu {
        border-bottom: ($grid * 0.20) solid rgba($color--charity-pink-light, 0.2);

        &__container {
            @include site-content-container;
            background: none;
            border-top: 0;
            column-gap: $grid;
            padding: ($grid * 2) ($grid * 3) ($grid * 3);
        }
        &__intro {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 200px;
            margin-bottom: -($grid * 3);
            flex: 200px 0 0;

            span {
                @include charity-font-size(s);
            }
            img {
                margin-top: $grid * 1.5;
            }
        }
        &__item {
            flex-basis: 60%;
            min-width: 60%;
        }
        &__link {
            color: $color--charity-link;
            &:hover {
                color: $color--charity-link-hover;
            }
        }
        &__link-icon {
            fill: $color--charity-link;
        }
        &__sub-menu {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: $grid;
            row-gap: $grid;
            border: 0;
            margin: 0;
        }
        &__sub-menu__item {
            max-width: calc(50% - ($grid * 2));
            min-width: 10%;
            margin: 0;


            p {
                @include charity-font-size(xs);
                line-height: 30px;
                margin: 0;
            }
        }
        &__sub-menu-link {
            @include charity-font-size(s);
            font-weight: 700;
            color: $color--charity-link;
            text-decoration: underline;
            border: 0;
            padding: 0;
        }
    }
}
