.load-more {
    display: flex;
    margin-bottom: ($grid * 2);
    text-decoration: none;

    &__container {
        display: flex;
        align-items: center;
    }

    &__icon {
        margin-left: ($grid * 0.25);
        fill: $color--white;
    }

    // If tabs comes after
    .listing__container + & {
        margin-top: ($grid);
    }

}
