.message {
    $root: &;
    position: relative;
    line-height: 1;
    transition: transform $default-transition-speed ease 0s;

    // When scrolling down
    // When scrolling down and up
    .site-header--unpinned &,
    .site-header--pinned & {
        transform: translate3d(0, -200%, 0);
    }

    // When at the top
    .site-header--top & {
        transform: translate3d(0, 0, 0);
    }

    &.inactive {
        display: none;
    }

    &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $color--white;
        background-color: $color--octonary;
        padding: ($grid * 0.25) $grid;

        @include media-query(desktop) {
            padding: ($grid * 0.25) ($grid * 1.5);
        }

        .js-message-dismiss {
            margin: 0;
            box-shadow: none;
            background-color: transparent;
            border: none;
        }
    }

    &__content {
        @include font-size(xxs);
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__icon {
        fill: $color--white;
        margin-right: ($grid * 0.25);
        cursor: pointer;

        &:hover {
            fill: $color--black;
        }

    }

    &__link {
        color: $color--white;
        text-decoration: underline;
        transition: opacity $default-transition-speed ease 0s;

        &:hover {
            opacity: 0.7;
        }
    }

    &__text {
        margin: 0;
        text-align: center;

        @include media-query(laptop) {
            text-align: left;
            margin-right: ($grid * 0.25);
            max-width: ($grid * 20);
        }

    }

    &--inline {
        margin-bottom: $grid;

        #{$root}__text {
            > a {
                color: $color--white;
                text-decoration: underline;
                transition: opacity $default-transition-speed ease 0s;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }

}
