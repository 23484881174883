.page-header {
  $root: &;
  padding-left: ($grid);
  padding-right: ($grid);
  padding-top: ($grid * 2);
  padding-bottom: ($grid * 1);
  background-color: $color--tertiary;
  margin-bottom: ($grid);

  @include media-query(desktop) {
    padding-right: ($grid * 1.5);
    padding-left: ($grid * 1.5);
  }

  .intranet & {
    background-color: $color--senary;
  }

  .template-campaign-content-page & {
    background: url("../images/intranet-information-page-hero.jpg");
    background-size: cover;

    &--overflow-image {
      padding-bottom: 120px;
    }
  }

  &__heading {
    color: $color--white;
    margin-bottom: 0;
    @extend %nhsuk-heading-xl;
  }

  // If page has filters on it
  &--filters {
    padding-bottom: ($grid * 3);
  }

  .theme-children & {
    position: relative;
    background-image: url("../images/children/page-banner.jpg");
    background-size: cover;
    background-position: left center;

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-image: url("../images/children/title-bg.png");
      background-repeat: no-repeat;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      top: -($grid);
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.5;
      background-image: url("../images/children/icon-grid.png");
      background-size: 25%;
      background-repeat: repeat;
    }

    #{$root}__heading {
      position: relative;
      z-index: 2;
    }
  }
}
