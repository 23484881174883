/*------------------------------------*\
    $SECONDARY MENU
\*------------------------------------*/
.charity {
    .menu-secondary {

        &__item {
            border-right: 0;
            padding: 0;
            &:first-of-type {
                padding: 0;
            }
            &:last-of-type {
                padding: 0;
                background-color: $color--charity-pink-dark;
                transition: background-color $default-transition-speed ease 0s;
                border-radius: 0 0 5px 5px;

                &:hover {
                    background-color: $color--white;
                }

                // has to be defined here because of .charity nesting
                .menu-secondary__link {
                    color: $color--white;

                    &:hover {
                        color: $color--charity-purple-bg;
                    }
                }
            }
            &:nth-last-child(2) {
                border-right: 0;
            }
            &--active {
                .menu-secondary__link {
                    background-color: $color--charity-purple-bg;
                    color: $color--white;
                    &:hover {
                      color: $color--white;
                    }
                }
            }
        }

        &__link {
            color: $color--charity-heading;
            padding: ($grid * 0.25) ($grid * 0.5);
            border-radius: 0 0 5px 5px;

            &:hover {
                color: $color--charity-purple-bg;
            }
        }
    }
}
