.multi-address {

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: ($grid);
        margin-bottom: ($grid);
        border-bottom: 2px dotted $color--white;
    }

    &__heading {
        font-weight: 700;
        margin-bottom: 0;
    }

    &__heading-meta {
        display: block;
        font-weight: 500;
    }

    &__item {
        padding: ($grid * 2);
        background-color: lighten($color--secondary, 20%);
        margin-bottom: ($grid * 2);
    }

    &__image-container {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-left: ($grid * 0.5);
        overflow: hidden;
    }

    &__image {
        width: 100%;
        height: auto;
    }

}
