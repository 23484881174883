.charity {
    .quote {
        &__item {
            color: $color--charity-heading;
            border-top: 7px solid $color--charity-accent;
        }
        &__author {
            color: $color--charity-text;
            text-transform: none;
            font-style: italic;
            font-size: 16px;
            font-weight: 500;
        }
    }
}
