/*------------------------------------*\
    $TEASER
\*------------------------------------*/

.teaser {
    $root: &;
    width: 100%;
    margin-bottom: ($grid * 0.5);

    .template-campaign-landing-page & {
        display: block;
        width: 100%;
        max-width: 920px;
        margin-left: auto;
        margin-right: auto;
    }

    @include media-query(tablet) {
        margin-bottom: ($grid);
    }

    // Link
    &__container {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        text-decoration: none;
        padding: ($grid * 0.75);
        background-color: $color--quinary;
        transition: background-color $default-transition-speed ease 0s;

        &:hover {
            background-color: darken($color--quinary, 5%);

            #{$root}__icon {
                transform: scale(1.2);
            }

        }

        &:focus div {
            h2 {
                color: $color--black;
            }
            svg {
                fill: $color--black;
            }
        }

        .template-campaign-landing-page & {
            width: auto;
            display: inline-block;
            background-color: #f3f6f6;
        }

    }

    &__content {
        .template-campaign-landing-page & {
            display: inline-block;
        }
    }

    &__heading {
        @include font-size(s);
        text-transform: none;
        font-weight: 300;
        color: $color--white;
        line-height: 1.4;
        transition: color $default-transition-speed ease 0s;

        .template-campaign-landing-page & {
            margin-bottom: 0;
            color: $color--quinary;
            font-weight: 600;
        }
    }

    &__icon-container {
        display: flex;
        justify-content: flex-end;
        padding-top: ($grid * 0.25);

        .template-campaign-landing-page & {
            display: inline-block;

            @include ie11 {
                padding-top: 0;
                vertical-align: -8px;
            }
        }
    }

    &__icon {
        fill: $color--white;
        transition: transform $default-transition-speed ease 0s;

        .template-campaign-landing-page & {
            fill: $color--quinary;
        }
    }

    .col__quarter & {

        &:first-child {
            margin-top: ($grid * 3);

            .intranet-block & {
                margin-top: 0;
            }

        }

    }

    .teaser-block & {
        flex-basis: 100%;
        min-width: 100%;
        margin-bottom: ($grid * 0.5);

        @include media-query(mobile) {
            flex-basis: 50%;
            min-width: 50%;
            max-width: 50%; // IE11 fix
            align-items: stretch;
            padding-left: ($grid * 0.25);
            padding-right: ($grid * 0.25);
        }

        @include media-query(laptop) {
            flex-basis: 25%;
            min-width: 25%;
            max-width: 25%; // IE11 fix
        }

        #{$root}__heading {
            font-weight: 500;
        }

        // Intranet specific
        .intranet-block & {
            margin-bottom: 0;

            &:first-child {

                #{$root}__container {
                    border-top: 1px dotted $color--border;
                }
            }

            #{$root}__container {
                flex-direction: row;
                align-items: center;
                background-color: transparent;
                padding: ($grid * 0.25) 0;
                border-bottom: 1px dotted $color--border;

                &:hover {

                    #{$root}__heading {
                        color: $color--link-hover;
                    }

                    #{$root}__icon {
                        fill: $color--link-hover;
                    }

                }

            }

            #{$root}__heading {
                @include font-size(xxs);
                font-weight: 500;
                color: $color--link;
                margin-bottom: 0;
            }

            #{$root}__icon-container {
                padding-top: 0;
            }

            #{$root}__icon {
                fill: $color--link;
                width: ($grid * 0.75);
                height: ($grid * 0.75);
            }

        }

    }

}