.value-bar {

    &__container {
        background-color: $color--primary;
        padding: ($grid * 0.5) ($grid);
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include media-query(desktop) {
            padding-right: ($grid * 1.5);
            padding-left: ($grid * 1.5);
        }

    }

    &__label {
        color: $color--white;
        font-weight: 700;
    }

}
