/*------------------------------------*\
    $SITE HEADER
\*------------------------------------*/

.charity {
    .site-header {
        position: static;
        @include site-content-container;
        background-color: $color--white;

        &__branding {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding: 30px;
            @include media-query(mobile) {
                padding: ($grid) ($grid * 2) 0 ($grid * 2);
            }
            @include media-query(tablet) {
                padding: ($grid) ($grid * 2);
            }

            .site-header__buttons {
                display: none;

                @include media-query(tablet) {
                    display: flex;
                    flex-direction: row;
                    gap: 15px;

                    .btn {
                        height: fit-content;
                    }
                }
            }
        }

        &__logo {
            padding: 4px;

            @include media-query(mobile) {
                max-width: none;
            }
        }
        &__navigation {
            display: none;
            padding: 0 ($grid);
            @include media-query(tablet) {
                display: flex;
                padding: 0 ($grid * 2);
            }
        }
        
        &__navigation-container {
            border-bottom: 4px solid $color--charity-pink-light;
        }

        &__mobile-navigation {
            padding: 0;
            .nhsuk-header__menu-toggle {
                text-decoration: none;
                padding: 7px 16px;
            }
            .nhsuk-header__menu-toggle.is-active {
                background: $color--white;
                color: $color--charity-pink-light;
                text-decoration: none;
            }
        }

        &__mobile-navigation-menu {
            left: 0;
            right: 0;
            z-index: 999;
            & .nhsuk-header__navigation {
                color: $color--charity-pink-light;
                border-top: none;
                border-bottom: ($grid * 0.2) solid
                    rgba($color--charity-pink-light, 0.2);
            }

            & .nhsuk-header__navigation-item {
                border: 0;
            }

            & .nhsuk-header__navigation-link {
                font-size: 16px;
                color: $color--charity-pink-light;
                font-weight: 700;
                text-decoration: underline;
                padding: ($grid * 0.2) 32px;
                border: 0;
            }
        }
        &__mobile-cta {
            display: flex;
            flex-wrap: nowrap;
            column-gap: $grid * 2;
            margin: $grid -16px $grid 32px;
            &--decoration {
                background: rgba($color--charity-pink-light, 0.2);
                -webkit-mask-image: url("../images/charity-pink-cross.svg");
                mask-image: url("../images/charity-pink-cross.svg");
                -webkit-mask-position: -5px center;
                mask-position: -5px center;
                -webkit-mask-repeat: repeat-x;
                mask-repeat: repeat-x;
                -webkit-mask-size: 444px 27px;
                mask-size: 444px 27px;
                flex: auto 1 1;
            }
        }
    }
}
