.charity-cta {
    position: relative;
    margin-bottom: 0;
    margin-top: 0;
    background-color: $color--charity-blue-dark;

    &__container {
        @include site-content-container;
        display: flex;
        flex-wrap: wrap;
        column-gap: $grid;
        row-gap: $grid;
        padding: ($grid * 2) 30px 0 30px;

        @include media-query(mobile) {
            column-gap: $grid * 2;
            row-gap: $grid * 2;
            padding: ($grid * 2) ($grid * 2) 0 ($grid * 2);
        }

        @include media-query(laptop) {
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            padding: ($grid * 2) ($grid * 2) 80px ($grid * 2);
        }
    }

    &__body {
        font-size: 29px;
        font-size: 1.8rem;
        color: $color--white;
        flex-basis: 100%;
        min-width: 100%;
        max-width: 100%;
        line-height: 1.4;

        @include media-query(laptop) {
            flex-basis: 600px;
            min-width: 0;
        }

        h2, h3, h4 {
            font-size: 32px;
            color: $color--white;
        }

        .rich-text {
            @include charity-font-size(s);
            a {
            color: $color--white;
            }

            h2 {
                font-size: 36px;
                @include media-query(mobile) {
                    font-size: 32px;
                }
            }
        }

        .charity-cta__link {
            @include charity-font-size(s);
            color: $color--white;
            font-weight: 700;

            &:hover {
                color: $color--white;
            }
        }

    }

    &__image-contain {
        position: relative;
        flex-basis: 100%;
        min-width: 100%;
        max-width: 100%;
        z-index: 1;

        @include media-query(laptop) {
            flex-basis: 45%;
            min-width: 45%;
            max-width: 45%;
            flex-grow: 0;
            padding: 0 ($grid);
            margin-bottom: 0;
        }

        &::after {
            content: "";
            background-image: url('../images/charity-blue-cross.svg');
            background-size: 444px 140px;
            position: absolute;
            top: 50%;
            right: -($grid * 2);
            bottom: 0;
            left: -($grid * 2);
            z-index: -1;

            @include media-query(laptop) {
                right: 0;
                bottom: 0;
                left: 0;
            }
        }
    }

    &__image {
        display: block;
        width: auto;
        max-height: 250px;
        margin: 0 auto;
    }
}

.charity-content-feed {
    position: relative;

    .charity-cta {
        background-color: $color--charity-grey-light;

        &__container {
            position: relative;
            padding: ($grid * 2) 30px;
    
            @include media-query(mobile) {
                padding: ($grid * 2) ($grid * 2) 80px ($grid * 2);
            }

            &::before {
                content: "";
                background-image: url('../images/charity-pink-cross.svg');
                background-size: 444px 27px;
                background-repeat: repeat-x;
                position: absolute;
                width: 444px;
                top: -20px;
                left: 39%;
                height: 27px;
                z-index: 1;

                @include media-query(tablet) {
                    right: ($grid * 2);
                    left: auto;
                }
            }
        }

        .featured-casestudy__hero {
            h2 {
                background: $color--charity-accent;
                padding: 20px;
                box-sizing: border-box;

                @include charity-font-size(s);
                font-weight: 700;
                color: $color--charity-text;

                @include media-query(laptop) {
                    position: absolute;
                    left: ($grid * 0.5);
                    right: 20px;
                    bottom: -24px;
                }
            }
        }

        .featured-casestudy__minor {
            border-left: 10px solid black;
            padding-left: 18px;

            h2 {
                line-height: 30px;
            }

            &:nth-child(1) {
                border-color: $color--charity-pink-light;
            }
            &:nth-child(2) {
                border-color: $color--charity-blue-dark;
            }
            &:nth-child(3) {
                border-color: #69B657;
            }
        }
    }
}

.charity-cta--content-feed {
    background-color: $color--white;

    h2 {
        @include charity-font-size(s);
        font-weight: 500;
    }

    .social-block{
        .social-block__header {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            h2 {
                font-size: 36px;
                font-weight: 700;
            }
            .social-block__see-more {
                @include charity-font-size(s);
                color: $color--charity-pink-light;
                display: none;
                @include media-query(laptop) {
                    display: block;
                }
            }
        }

        .social-block__see-more {
            @include charity-font-size(s);
            color: $color--charity-pink-light;
            display: block;
            @include media-query(laptop) {
                display: none;
            }
        }
    }

    .charity-cta {
        &__container {
            padding: ($grid * 2) 30px;

            @include media-query(mobile) {
                padding: ($grid * 2) ($grid * 2) 80px ($grid * 2);
            }
        }
    }
}