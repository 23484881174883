.quick-links {
  $root: &;
  margin-bottom: ($grid * 2);

  &__item-container {
    transition: transform $default-transition-speed ease 0s;
  }

  &__heading {
    display: flex;
    align-items: center;
    @include nhsuk-typography-responsive(32);
  }

  &__heading-label {
    @include font-size(m);
  }

  &__heading-icon {
    display: none;
    fill: $color--link;
  }

  &__link {
    @include font-size(xxs);
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-direction: row;
    text-decoration: none;
    padding: ($grid * 0.25);
    background-color: $color--card;
  }

  &__icon {
    fill: $color--black;
    margin-right: ($grid * 0.25);
    flex-basis: ($grid * 0.75);
    min-width: ($grid * 0.75);
  }

  &__item {
    margin-bottom: ($grid * 0.25);
  }

  .supporting-info & {
    display: none;
  }

  // When displayed within supporting info and stuck
  .supporting-info.stuck & {
    display: block;
    margin-bottom: ($grid * 0.5);
    flex-basis: 100%;
    min-width: 100%;

    &__heading-label {
      @include font-size(xs);
    }

    @include media-query(mobile) {
      min-width: ($grid * 6.75);
      margin-right: ($grid * 0.5);
      flex-basis: auto;
      margin-bottom: 0;
    }

    #{$root}__container {
      // Mobile only on click
      &.active {
        @include media-query(tablet-max) {
          #{$root}__item-container {
            transform: translate3d(0, 0%, 0);
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }

    #{$root}__mask {
      position: absolute;
      z-index: 2;
      height: 900px;
      width: 400px;
      pointer-events: none;
      // sass-lint:disable no-vendor-prefixes
      -webkit-mask-image: linear-gradient(
        to bottom,
        rgba($color--black, 1) 0%,
        rgba($color--black, 1) 100%
      );
      // sass-lint:enddisable
      mask-image: linear-gradient(
        to bottom,
        rgba($color--black, 1) 0%,
        rgba($color--black, 1) 100%
      );
    }

    #{$root}__heading {
      justify-content: space-between;
      margin-bottom: 0;
      background-color: $color--white;
      padding: ($grid * 0.25) ($grid * 0.5);
    }

    #{$root}__heading-icon {
      display: block;
      flex-basis: ($grid + ($grid * 0.25));
      min-width: ($grid + ($grid * 0.25));
      border-left: 2px dotted $color--border;
      padding-left: ($grid * 0.25);
      margin-left: ($grid * 0.5);
    }

    #{$root}__item-container {
      position: absolute;
      z-index: 1;
      opacity: 0;
      pointer-events: none;
      background-color: $color--white;
      padding: ($grid * 0.5);
      border: 1px solid $color--border;
      border-top: 0;
      transform: translate3d(0, -100%, 0);
    }

    &:hover {
      @include media-query(laptop) {
        #{$root}__mask {
          pointer-events: auto;
        }

        #{$root}__item-container {
          transform: translate3d(0, 0%, 0);
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
}
