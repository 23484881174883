.charity {

    .image-grid {

        &__image {
            border-radius: $charity-border-radius;
        }

    }

}
