.charity-twitter {
    $root: &;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    padding: 0 $grid $grid;
    width: 100%;

    @include media-query(tablet) {
        padding: ($grid) ($grid * 1.5);
    }

    &__item {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
        min-width: 100%;
        max-width: 100%;
        background: $color--charity-twitter-block;
        border-radius: $charity-border-radius;
        padding: ($grid);
        margin: ($grid * 0.5);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include media-query(mobile) {
            flex-basis: 0;
            min-width: 0;
        }

    }

    &__text-item {
        color: $color--charity-heading;

        a {
            text-decoration: none;
        }

    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__action-link {
        display: flex;
        align-items: center;
        text-decoration: none;

        &:hover {

            #{$root}__action-arrow {
                transform: scale3d(1.2, 1.2, 1);
                fill: $color--charity-link-hover;
            }

        }

    }

    &__action-arrow {
        fill: $color--charity-link;
        transition: transform ease $default-transition-speed;
        will-change: transform;
    }

    &__icon {
        fill: rgba($color--charity-text, 0.5);
        width: ($grid);
        height: auto;
    }

}
