.charity {
    .breadcrumb {
        $root: &;
        margin-bottom: ($grid * 0.5);
        flex-direction: row;
        flex-wrap: wrap;

        @media only screen and (max-width: 799px) {
            margin-right: 0 !important; // override inline margin-right that accounts for image sticking out over hero box in full view
        }

        &__item {
            font-size: 18px;
            color: $color--charity-link;
            &:first-child {
                color: $color--charity-link;
                display: inline;
            }
        }
        &__link {
            color: $color--charity-link;
            text-decoration: underline;
            &:hover {
                color: $color--charity-link-hover;
                text-decoration: none;
            }
        }
        &__divide {
            color: $color--charity-link;
        }
        .page-header & {
            color: $color--charity-link;
            #{$root}__item {
                color: $color--charity-link;
            }
        }
    }
}
