.waiting-times {
    $root: &;

    &__container {
        margin-bottom: ($grid);

        @include media-query(laptop) {
            padding-right: ($grid);
        }

        .template-intranet-home & {
            @include media-query(laptop) {
                padding-right: 0;
            }
        }

        .intranet-block & {
            margin-bottom: 0;

            .waiting-times__label {
                min-width: 95px;
                padding-right: 24px;
                padding-left: 16px;
            }
        }
    }

    &__heading {
        @include font-size(s);
        font-weight: bold;
        margin-bottom: ($grid * 0.75);
        color: #231f20;

        .intranet-block & {
            margin-bottom: ($grid * 0.5);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin-left: -($grid * 0.5);
        margin-right: -($grid * 0.5);
    }

    &__item {
        flex-basis: auto; // IE11
        min-width: 100%;
        max-width: 100%; // IE11
        padding-left: ($grid * 0.5);
        padding-right: ($grid * 0.5);
        margin-bottom: ($grid * 0.75);
        min-height: 150px;

        .template-intranet-home & {
            min-height: 0;
            padding-bottom: ($grid * 0.5);
        }
    }

    &__item-container {
        border-top: 1px dotted $color--border;
        padding-top: ($grid * 0.75);
    }

    &__item-heading {
        @include font-size(s);
        margin-bottom: ($grid * 0.5);
        color: #212b32;

        .home-block & {
            font-weight: 700;
            margin-bottom: ($grid * 0.25);
            color: #231f20;
        }
    }

    &__item-text {
        margin-bottom: ($grid * 0.25);

        .home-block & {
            display: flex;
            flex-direction: column;
            color: #231f20;
        }
    }

    &__icon-set {
        display: flex;

        @include media-query(laptop) {
            display: block;
        }

        @include media-query(desktop) {
            display: flex;
        }
    }
    &__icon-set--high {
        .waiting-times__label {
            box-shadow: inset -8px 0 0 0 $color--emergency-services-red;
        }
    }

    &__icon-set--medium {
        .waiting-times__label {
            box-shadow: inset -8px 0 0 0 $color--nhs-orange;
        }
    }

    &__icon-set--low {
        .waiting-times__label {
            box-shadow: inset -8px 0 0 0 #008b35;
        }
    }

    &__link {
        .intranet-block & {
            @include font-size(xxs);
            color: $color--white;

            &:hover {
                color: $color--intranet-interact;
            }
        }
    }

    &__label {
        background: $color--nhs-pale-grey;
        line-height: 1;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .home-block & {
            padding: 8px;
            padding-right: 16px;
            flex: 0 0 90px;
            border-bottom: 1px solid $color--white;
        }

        .intranet-block & {
            padding: 2px;
            flex: 0 0 70px;
        }
    }

    &__meta {
        display: flex;
        align-items: center;
        font-weight: 500;

        .home-block & {
            flex: 1 1 35%;
            padding: 12px 8px 12px 0;
            border-bottom: 1px solid $color--nhs-pale-grey;
        }

        .intranet-block & {
            flex: 1 1 35%;
            color: $color--white;
            padding: 12px 8px;
        }
    }

    &__update-info {
        margin-top: 5px;
        @include font-size(xxxs);
        font-style: italic;

        .home-block & {
            flex-basis: 100%;
            min-width: 100%;
            max-width: 100%; // IE11
        }

        .intranet-block & {
            color: $color--white;
        }
    }

    &__icon {
        margin-right: ($grid * 0.25);
        fill: $color--primary;

        .home-block & {
            height: 35px;
            width: 35px;
        }
    }

    &__stat {
        @include font-size(l);
        font-weight: 700;
    }

    &__stat_descriptor {
        @include font-size(xs);
        font-weight: 700;
    }

    .home-block & {
        padding-left: ($grid);
        padding-right: ($grid);

        @include media-query(laptop) {
            // margin-top: ($grid * 4.5);
            padding-left: 0;
            padding-right: 0;
        }
    }

    .intranet-block & {
        #{$root}__link {
            @include font-size(xxs);
            color: $color--white;

            &:hover {
                color: $color--intranet-interact;
            }
        }

        #{$root}__container {
            margin-bottom: 0;
        }

        #{$root}__item-container.unavailable {
            color: $color--white;
        }
    }

    .current-status & {
        #{$root}__heading,
        #{$root}__item-heading {
            color: $color--off-white;
        }

        #{$root}__item {
            margin-bottom: ($grid * 0.25);
        }

        #{$root}__item-heading {
            @include font-size(xs);
            margin-bottom: ($grid * 0.25);
        }

        #{$root}__item-container {
            border-top: 1px dotted rgba($color--white, 0.3);
        }

        #{$root}__icon-set {
            background-color: rgba($color--black, 0.3);
            margin-bottom: 1px;
        }
    }
}
