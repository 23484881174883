.featured-casestudy {
    $root: &;

    &__container {
        margin-bottom: ($grid);

        @include media-query(laptop) {
            margin-bottom: ($grid * 1.5);
        }

        &:hover {

            #{$root}__heading {
                color: $color--link-hover;
            }

        }

    }

    &__heading {
        @include font-size(s);
        display: block;
        margin-bottom: 0;
        padding-bottom: ($grid * 0.75);
    }

    &__item-image {
        display: block;
        margin-bottom: ($grid * 0.5);
        border-radius: 4px;
        width: 100%;

        .intranet & {
            border-radius: 0;
        }
    }

    &__item-heading {
        @include font-size(s);
        color: $color--link;
    }

}
