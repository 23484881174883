.people-gallery {
    $root: &;
    margin-bottom: ($grid * 2);

    &__container {
        display: flex;
        flex-wrap: wrap;
        margin-left: -($grid * 0.5);
        margin-right: -($grid * 0.5);
    }

    &__item {
        flex-basis: 25%;
        min-width: 25%;
        padding-right: ($grid * 0.5);
        padding-left: ($grid * 0.5);
        margin-bottom: ($grid);
    }

    &__link {
        display: block;
        text-decoration: none;

        #{$root}__item-heading {
            color: $color--link;
            text-decoration: underline;
        }

        &:hover {

            #{$root}__item-heading {
                color: $color--link-hover;
            }

        }

    }

    &__item-heading {
        @include font-size(s);
        margin-bottom: ($grid * 0.25);
    }

    &__image-container {
        margin-bottom: ($grid * 0.5);
    }

    &__no-image-container {
        background-color: $color--page-background;
    }

    &__image {
        display: block;
        width: 100%;
        height: auto;

        &--none {
            fill: $color--white;
        }
        
    }

    &__meta {
        @include font-size(xs);
        color: $color--default;
    }

}
