/*------------------------------------*\
    $SITE FOOTER
\*------------------------------------*/

.charity {
    .charity-footer {
        $root: &;
        width: 100%;
        background-color: $color--charity-grey;
        color: $color--white;
    
        &__container {
            @include site-content-container;
            padding: 30px;
        
            display: flex;
            flex-direction: column;
            @include media-query(laptop) {
                padding: ($grid * 2) ($grid * 2) ($grid * 5) ($grid * 2);
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
            }
            @include media-query(mobile) {
                padding: ($grid * 2);
            }
        }
        &__body {
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            max-width: 100%;
            @include media-query(laptop) {
                max-width: 50%;
            }
        }
        &__social-container {
            display: flex;
            flex-direction: column;
        
            column-gap: 30px;
            margin-top: 14px;
            @include media-query(laptop) {
                flex-direction: row;
            }
        }
        &__social-item {
            display: flex;
            align-items: center;
            column-gap: 10px;
            margin-bottom: 10px;
            @include media-query(mobile) {
                margin: 0;
            }
          
        }
        &__social-link-icon {
            fill: $color--charity-bg;
        }
        &__social-icon {
            display: block;
            width: 20px;
            height: 20px;
            span {
                @include hidden;
            }
            &:focus {
                svg {
                    fill: black;
                }
            }
        }
        &__social-link, &__credits-link  {
            color: $color--white;
            font-size: 16px;
            font-weight: 700;
        
            &:hover {
                color: #CECECE;
            }

            &:focus {
                color: $color--black;
            }
        }
        &__credits {
            display: flex;
            flex-direction: column;
        
            column-gap: 30px;
            @include media-query(laptop) {
                flex-direction: row;
            }
        }
        &__credits-item {
            margin-bottom: 10px;
            @include media-query(mobile) {
                margin: 0;
            }
        }
        &__copy {
            @include charity-font-size(xxxs);
        }
        &__logo {
            width: 358px;
            height: 207px;
            background: #EFF3F5;
            display: none;
            @include media-query(laptop) {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        &__mobile-logo {
            width: 100%;
            display: block;
            background: #EFF3F5;
            padding: 30px;
            @include media-query(mobile) {
                padding: ($grid * 2);
            }
        
            svg {
                display: block;
                max-width: 358px;
                width: 100%;
                height: auto;
                margin-left: auto;
            }
        
            @include media-query(laptop) {
                display: none;
            }
        }
    }
}
