.dropdown-menu {
    $root: &;
    border-bottom: ($grid * 0.25) solid rgba($color--border, 0.7);

    &__container {
        display: flex;
        background-color: $color--white;
        padding: ($grid) ($grid * 1.5) ($grid * 0.75);
        border-top: ($grid * 0.25) solid $color--nhs-dark-pink;

        .intranet & {
            border-top: ($grid * 0.25) solid $color--intranet-interact;
        }

    }

    &__links {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-basis: 80%;
        min-width: 80%;
        margin: 0;
        margin-bottom: 0;
        padding-right: $grid * 1.5;
    }

    &__item {
        flex-basis: 31%;
        min-width: 31%;
        margin-bottom: ($grid);

        // Prevent large gap between items on lists with 2 or 5 items
        &:nth-child(2),
        &:nth-child(5) {
            &:last-child {
                flex-basis: 65%;

                #{$root}__sub-menu {
                    width: 50%;
                }
            }
        }
    }

    &__item-container {
        height: 100%;
        padding: 0 ($grid * 0.5);
        margin-bottom: ($grid * 0.5);
    }

    &__link {
        @include font-size(xs);
        line-height: 1;
        display: flex;
        align-items: center;
        padding: 0;
        overflow: hidden;
        font-weight: 700;
        color: $color--link;
        text-transform: none;
        text-decoration: none;

        &:hover {
            color: $color--link;
        }
        &:hover:visited {
            color: $color--nhs-purple;
        }
    }

    &__link-icon {
        fill: $color--link;
    }

    &__link-label {
        float: left;
        display: inline-block;
    }

    // Level 3 items

    &__sub-menu {
        margin-top: ($grid * 0.5);
        margin-bottom: 0;
        border-bottom: 1px dotted rgba($color--septenary, 0.8);
    }

    &__sub-menu-item {
        margin-bottom: 0;
    }

    &__sub-menu-link {
        @include font-size(xs);
        display: block;
        padding: ($grid * 0.25) 0;
        color: $color--senary;
        text-transform: none;
        text-decoration: none;
        border-top: 1px dotted rgba($color--septenary, 0.8);

        &:hover {
            color: darken($color--link-hover, 10%);
        }

    }

    &__teasers {
        flex-basis: 20%;
        min-width: 20%;
        display: flex;
    }

    &__teaser-item {

        &:first-child {
            // sass-lint:disable no-important
            margin-top: 0 !important;
            // sass-lint:enddisable
        }

    }

}
