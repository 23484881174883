.page-nav {
    $root: &;
    margin-bottom: ($grid);
    padding-bottom: ($grid * 2);

    @include media-query(laptop) {
        margin-bottom: ($grid * 5);
    }

    &__item,
    &__levels-item {
        margin-bottom: 0;
    }

    &__levels-link {
        @include font-size(xs);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: ($grid * 0.5) ($grid * 0.5) ($grid * 0.25);
        background-color: $color--primary;
        color: $color--white;
        border-bottom: 1px solid $color--border;
        text-decoration: none;
        transition: background-color $default-transition-speed ease 0s;

        &:hover,
        &:visited,
        &:active,
        &:focus:visited {
            background-color: $color--tertiary;
            color: $color--white;

            #{$root}__link-icon {
                fill: $color--white;
            }
        }

        .intranet & {
            background-color: lighten($color--senary, 10%);

            &:hover {
                background-color: $color--senary;
            }

        }

        #{$root}__link-icon {
            fill: $color--white;
        }

    }

    &__levels-icon {
        fill: $color--white;
    }

    &__link {
        @include font-size(xxs);
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        padding: ($grid * 0.25) ($grid * 0.5);
        border-bottom: 1px solid $color--border;
        background-color: $color--white;
        transition: background-color $default-transition-speed ease 0s;

        &--child {
            padding-left: $grid * 1.5;
        }

        &:hover {
            background-color: $color--public-interact;
            color: $color--black;

            #{$root}__link-icon {
                fill: $color--black;
            }

            .intranet & {
                background-color: $color--intranet-interact;
                color: $color--white;

                #{$root}__link-icon {
                    fill: $color--white;
                }

            }

        }

        // Selected item
        &.selected {
            background-color: $color--secondary;
            color: $color--black;
            cursor: default;

            #{$root}__link-icon {
                fill: $color--black;
            }

            .intranet & {
                background-color: $color--secondary;
                color: $color--black;

                #{$root}__link-icon {
                    fill: $color--black;
                }

            }

        }

    }

    &__link-icon {
        fill: $color--primary;
        flex-basis: ($grid * 0.75);
        min-width: ($grid * 0.75);
    }

}
