.owl-carousel {
    max-width: 100%;
    // sass-lint:disable no-important
    display: block !important; // Ensure flex isn't used (owl doesn't support flex)
    // sass-lint:enddisable
}

.owl-dots {
    position: absolute;
    z-index: 3;
    top: ($grid);
    right: ($grid);

    @include media-query(tablet) {
        top: auto;
        bottom: ($grid);
        right: ($grid);
    }

    .js-slideshow--centre & {
        bottom: -($grid * 1.5);
        right: 0;
        left: 0;
        text-align: center;
    }

}

.owl-dot {
    display: inline-block;
    width: ($grid * 0.5);
    height: ($grid * 0.5);
    border-radius: 50%;
    background-color: $color--white;
    margin: 0 5px;

    .shop-item & {
        background-color: $color--secondary;
    }

    &.active {
        background-color: $color--quinary;
    }

}

.owl-next,
.owl-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-bottom: auto;
    margin-top: auto;
    border-radius: 50%;
    height: ($grid * 1);
    width: ($grid * 1);
    background-color: $color--quinary;
    background-repeat: no-repeat;
    background-position: center;
    background-size: ($grid * 1);
    text-indent: -9999px;
    overflow: hidden;
    // sass-lint:disable indentation
    transition: transform $default-transition-speed ease 0s,
                background-color $default-transition-speed ease 0s;
    // sass-lint:enddisable

    @include media-query(tablet) {
        height: ($grid * 1.5);
        width: ($grid * 1.5);
        background-size: ($grid * 1.5);
    }

    &.disabled {
        background-color: $color--primary;
        cursor: default;
        opacity: 0.6;

        &:hover {
            transform: scale(1);
            background-color: $color--primary;
        }

    }

    &:hover {
        transform: scale(1.2);
        background-color: $color--primary;
    }

}

.owl-prev {
    left: -($grid);
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDggNDgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ4IDQ4OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6I0ZGRkZGRjt9LnN0MXtmaWxsOm5vbmU7fTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMwLjgsMzIuMkwyMS43LDIzbDkuMi05LjJMMjgsMTFMMTYsMjNsMTIsMTJMMzAuOCwzMi4yeiIvPjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0wLTFoNDh2NDhIMFYtMXoiLz48L3N2Zz4=');

    .slideshow & {
        left: ($grid);
    }

    .js-carousel & {
        left: ($grid);

        @include media-query(tablet) {
            left: -($grid);
        }

    }

}

.owl-next {
    right: -($grid);
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDggNDgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ4IDQ4OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6I0ZGRkZGRjt9LnN0MXtmaWxsOm5vbmU7fTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTE3LjIsMzIuN2w5LjItOS4ybC05LjItOS4ybDIuOC0yLjhsMTIsMTJsLTEyLDEyTDE3LjIsMzIuN3oiLz48cGF0aCBjbGFzcz0ic3QxIiBkPSJNMC0wLjVoNDh2NDhIMFYtMC41eiIvPjwvc3ZnPg==');

    .slideshow & {
        right: ($grid);
    }

    .js-carousel & {
        right: ($grid);

        @include media-query(tablet) {
            right: -($grid);
        }

    }

}
