.author {
    margin-bottom: ($grid * 2);

    &__container {
        display: flex;
        align-items: center;
        border-top: 6px solid $color--border;
        padding-top: ($grid);
        padding-bottom: ($grid);
    }

    &__image-container {
        margin-right: $grid;
    }

    &__image {
        overflow: hidden;
        border-radius: 50%;
        display: block;
        width: 100%;
        height: auto;
    }

    &__heading {
        @include font-size(xs);
        color: $color--primary;
        margin-bottom: ($grid * 0.25);
    }

    &__name {
        @include font-size(m);
        margin-bottom: ($grid * 0.25);
    }

    &__details {
        @include font-size(xs);
        margin-bottom: 0;
    }

}
