.rich-text {
  h5 {
    margin-bottom: 5px;
  }

  p:empty {
    display: none;
  }

  br {
    clear: both;
  }

  ul,
  ol {
    @include font-size(xs);
  }
}
