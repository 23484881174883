.template-campaign-landing-page {
    .rich-text {
        max-width: 920px;
        margin: auto;
    }
}

.td {
    &-aligner {
        max-width: 920px;
        margin: auto; 
    }

    &-heading {
        @include font-size(m);
        font-weight: 300;
        margin: 20px 0;
    }
}