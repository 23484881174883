/*------------------------------------*\
    $SECONDARY MENU
\*------------------------------------*/

.menu-secondary {
  $root: &;
  width: 100%;
  background-color: $color--page-background;
  align-content: center;
  justify-content: end;

  &__item {
    padding: ($grid * 0.25);
    margin-bottom: 0px;

    @include media-query(desktop) {
      padding: ($grid * 0.5) $grid;
    }

    &--active {
      #{$root}__link {
        color: $color--public-interact;
      }
    }
  }

  &__link {
    @include font-size(xxs);
    display: flex;
    align-items: center;
    font-family: $font--primary;
    color: $color--primary;
    text-decoration: underline;

    &:hover {
      color: #00376c;

      .intranet & {
        color: darken($color--octonary, 10%);
      }
    }
  }

  &__icon {
    fill: $color--black;
    margin-left: 5px;
  }
}
