.pagination {
    margin: 0 auto 60px;
    text-align: center;
    font-size: 16px;

    &__icon {
        fill: #005eb8;
        width: 17px;
        height: 17px;
        min-width: 17px;
        vertical-align: -4px;
        transition: fill ease 300ms;
    }

    .current {
        padding: 0 20px;
    }
    
    .first-page,
    .next-page,
    .prev-page,
    .last-page {
        font-weight: 600;
        padding: 0 3px;
        display: inline-block;
        color: #005eb8;

        &:hover {
            color: #00376c;

            .pagination__icon {
                fill: #00376c;
            }
        }
    }
}
