.charity {

    .inline-image {

        &:first-child {
            margin-bottom: 52px;
        }

    }

}
