/*------------------------------------*\
    $GLOSSARY
\*------------------------------------*/

.glossary {
    $root: &;

    &__left,
    &__right {
        width: 100%;
        height: auto;
        min-height: 0;

        @include media-query(tablet) {
            display: inline-block;
            width: 50%;
            height: calc(100vh - 103px);
            min-height: 800px;
            vertical-align: top;
        }

    }

    &__left {
        padding: 80px;
        background-color: $color--primary;

        @include media-query(laptop) {
            padding: 80px;
        }

        @include media-query(tablet) {
            padding: 80px;
        }
    }

    &__inner {
        position: relative;
    }

    &__search-heading {
        @include font-size(l);
        margin-bottom: 15px;
        color: #fff;
    }

    &__search {
        @include font-size(s);
        @include placeholder-text {
            @include rem-font-size(18px);
            font-family: $font--primary;
            color: #fff;
            opacity: 1;
        }
        width: 100%;
        padding: 16px 60px 16px 30px;
        color: #fff;
        background-color: transparent;
        border: 2px solid rgba(255, 255, 255, 0.45);
        border-radius: 40px;

        @include media-query(laptop) {
            padding: 10px 20px;
        }
    }

    &__submit {
        position: absolute;
        top: 77px;
        right: 7px;
        width: 67px;
        height: 67px;
        cursor: pointer;
        border: 0;
        transition: opacity $default-transition-speed;

        &:hover {
            opacity: 0.7;
        }

        @include media-query(laptop) {
            right: 0;
            width: 67px;
            height: 55px;
            background: {
                size: 24px;
            }
        }
    }

    &__browse {
        @include font-size(s);
        margin-top: 50px;
        color: #fff;
    }

    &__right {
        padding: 100px;
        overflow: scroll;

        @include media-query(tablet) {
            padding: 20px;
        }
    }

    &__results-heading {
        @include rem-font-size(26px);
        margin-bottom: 20px;
        font-family: $font--secondary;
        font-weight: 600;
    }

    &__result {
        position: relative;
        padding: 30px 0;
        border-top: 1px solid #eee;
    }

    &__result-border {
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        max-width: 100%;
        background-color: #eee;
        opacity: 0;
    }

    &__result-heading {
        @include font-size(s);
        display: inline-block;
        padding-right: 50px;
        font-family: $font--secondary;
        font-weight: 600;

        @include media-query(mobile) {
            padding-right: 40px;
        }
    }

    &__result-content {
        font-size: 16px;
    }

    &__noun {
        @include rem-font-size(13px);
        display: block;
        width: 50px;
        padding: 3px 4px;
        margin-top: 3px;;
        font-family: $font--secondary;
        color: #000;
        text-align: center;
        vertical-align: 3px;
        background-color: #f9df32;
        border-radius: 4px;
    }
}
