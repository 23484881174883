.our-locations {
  $root: &;
  margin-bottom: ($grid * 2);

  &__container {
    padding: ($grid * 2) ($grid) ($grid * 3);

    @include media-query(tablet) {
      padding: ($grid * 2) ($grid * 2) ($grid * 3);
    }
  }

  &__header {
    margin-bottom: ($grid);

    @include media-query(tablet) {
      margin-bottom: ($grid * 2);
    }
  }

  &__heading {
    @include font-size(m);
    margin-bottom: 0;
    text-align: center;

    @include media-query(tablet) {
      @include font-size(l);
      text-align: left;
    }
  }

  &__set-header {
    margin-bottom: ($grid * 0.5);
  }

  &__content {
    @include flex-container;
    display: flex;
    flex-direction: column;

    @include media-query(tablet) {
      flex-direction: row;
    }
  }

  &__item {
    margin-bottom: ($grid * 0.5);
  }

  &__link {
    display: flex;
    align-items: center;
    background-color: rgba($color--white, 0.9);
    padding: ($grid) $grid;
    transition: background-color $default-transition-speed ease 0s;

    &:hover {
      background-color: $color--white;

      #{$root}__item-heading {
        color: $color--octonary;
      }
    }
  }

  &__item-header {
    display: flex;
    align-items: center;
    margin-bottom: ($grid * 0.5);
    margin-left: ($grid);
    margin-right: ($grid);
    padding-bottom: ($grid * 0.5);
    border-bottom: 1px dotted $color--border;
  }

  &__item-heading {
    @include font-size(s);
    line-height: 1;
    text-transform: uppercase;
    color: $color--black;
    letter-spacing: 3px;
    margin-bottom: 0;
    transition: color $default-transition-speed ease 0s;

    span {
      font-weight: 300;
    }
  }

  &__body {
    @include font-size(s);
    padding: 0 $grid $grid;
    margin-bottom: 0;
    color: $color--default;
  }

  &__icon {
    margin-right: ($grid * 0.5);
    fill: $color--octonary;
  }

  &__column {
    flex-basis: 100%;
    min-width: 100%;
    margin-bottom: ($grid);

    @include media-query(tablet) {
      flex-basis: 33.333%;
      min-width: 33.333%;
      padding-right: ($grid);
      padding-left: ($grid);
      margin-bottom: 0;
    }
  }

  &__primary-container {
    display: block;
    background-color: $color--white;

    &:hover {
      #{$root}__item-heading {
        color: $color--octonary;
      }
    }
  }

  &__image-container {
    margin-bottom: ($grid);
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }
}
