.smallprint {
    display: none;

    @include media-query(laptop) {
        display: block;
    }

    &__container {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        @include font-size(xxs);
        color: $color--off-white;
        margin-right: ($grid);
    }

    &__link {
        color: $color--white;

        &:hover {
            color: $color--octonary;
        }

    }

    .site-footer & {
        flex-basis: 100%;
        min-width: 100%;
    }

}
