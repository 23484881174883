.image-grid {
    margin-bottom: ($grid * 2);

    &__container {
        margin: 0;
    }

    &__item {
        display: inline-block;
        float: left;
        height: auto;
        margin-bottom: ($grid * 0.5);
        width: 50%;

        @include media-query(laptop) {
            width: calc(34% - (#{$grid} / 2));
        }

        &:nth-child(even) {
            padding-right: ($grid * 0.25);

            @include media-query(laptop) {
                padding: 0;
            }

        }

        &:nth-child(odd) {
            padding-left: ($grid * 0.25);

            @include media-query(laptop) {
                padding: 0;
            }

        }

        &--primary {
            margin-right: ($grid * 0.5);
            width: 100%;
            // sass-lint:disable no-important
            padding: 0 !important;
            // sass-lint:enddisable

            @include media-query(laptop) {
                width: 66%;
            }

        }

    }

    &__image {
        width: 100%;
        height: auto;
    }

    &__caption {
        @include font-size(s);
        clear: both;
        display: block;
        font-weight: 500;
        padding-top: 0;
        color: $color--black;
        max-width: 800px;
    }

}
