@mixin reset-inputs {
    margin: 0;
    box-shadow: none;
    border: 0;
}

.charity {
    $root: &;

    .charity-donate {
        background: $color--charity-blue-dark;
        position: relative;

        &__container {
            @include site-content-container;
            display: flex;
            flex-wrap: wrap;
            column-gap: $grid;
            row-gap: $grid;
            padding: ($grid * 2) 30px 0 30px;
    
            @include media-query(mobile) {
                column-gap: $grid * 2;
                row-gap: $grid * 2;
                padding: ($grid * 2) ($grid * 2) 0 ($grid * 2);
            }

            @include media-query(laptop) {
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;
                padding: $grid * 2;
            }
        }

        &__content {
            color: $color--white;
            flex-basis: 100%;
            min-width: 100%;
            max-width: 100%;
            line-height: 1.4;    
            @include media-query(tablet) {
                flex-basis: 600px;
                min-width: 0;
            }
        }
        &__image-wrapper {
            position: relative;
        flex-basis: 100%;
        min-width: 100%;
        max-width: 100%;
        z-index: 1;

        @include media-query(laptop) {
            flex-basis: 45%;
            min-width: 45%;
            max-width: 45%;
            flex-grow: 0;
            padding: 0 ($grid);
            margin-bottom: 0;
        }

        &::after {
            content: "";
            background-image: url('../images/charity-blue-cross.svg');
            background-size: 444px 140px;
            position: absolute;
            top: 50%;
            right: -($grid * 2);
            bottom: 0;
            left: -($grid * 2);
            z-index: -1;

            @include media-query(laptop) {
                right: 0;
                bottom: 0;
                left: 0;
            }
        }
        }

        &__image {
            display: block;
            width: auto;
            max-height: 250px;
            margin: 0 auto;
        }

        &__heading {
            @include charity-font-size(l);
            color: $color--white;
            font-weight: 700;
            line-height: 1.15;
            @include media-query(desktop) {
                max-width: 470px;
            }
        }
        &__actions-item {
            margin-bottom: ($grid * 0.5);
            @include media-query(tablet) {
                margin-bottom: 0;
            }
        }
        &__body {
            @include charity-font-size(s);

            color: $color--white;
            margin-bottom: ($grid);
            @include media-query(desktop) {
                max-width: 400px;
            }
        }
        &__cta-button-container {
            display: inline-flex;
            align-items: stretch;
        }
        &__cta-button {
            @include reset-inputs;
            @include charity-font-size(xxxs);
            color: $color-white;
            font-weight: 700;
            flex-grow: 0;
            flex-shrink: 1;
            @include media-query(mobile) {
                @include charity-font-size(xxs);
            }
            @include media-query(tablet) {
                @include charity-font-size(s);
            }

            &:hover {
                color: $color-white;
            }
        }
    }
}
