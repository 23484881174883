.hero-action {
    $root: &;
    align-self: flex-end;
    margin-bottom: 0;
    width: 100%;
    z-index: 2;

    @include media-query(tablet) {
        margin-bottom: ($grid * 2);
        width: ($grid * 10);
    }

    &__container {
        overflow: hidden;
        padding: $grid;
        margin-left: -($grid);
        margin-right: -($grid);
        border-bottom: 1px solid $color--white;
        transition: opacity $default-transition-speed ease 0s;

        @include media-query(tablet) {
            margin-right: 0;
            margin-left: 0;
            margin-bottom: ($grid * 0.25);
            border-bottom: 0;
            border-radius: $default-border-radius;
            box-shadow: 0 0 10px $color--black;
        }

        &:hover {

            #{$root}__icon {
                transform: scale(1.2);
                fill: $color--octonary;
            }

        }

    }

    &__title {
        @include font-size(m);
        display: flex;
        align-items: center;
        padding-bottom: ($grid * 0.5);
        margin-bottom: ($grid * 0.5);
        font-weight: 700;
        letter-spacing: 1px;
        color: #fff;
        border-bottom: 2px dotted rgba(255, 255, 255, 0.56);
    }

    &__description {
        @include font-size(s);
        display: block;
        margin-bottom: ($grid * 0.5);
        line-height: 1.4;
        color: $color--off-white;
    }

    &__link {
        display: flex;
        align-items: center;
        color: $color--white;
    }

    &__icon {
        fill: $color--secondary;
        // sass-lint:disable indentation
        transition: fill $default-transition-speed ease 0s,
                    transform $default-transition-speed ease 0s;
        // sass-lint:enddisable
    }

    &__secondary-link {
        @include font-size(s);
        font-weight: 500;
        display: block;
        padding: ($grid * 0.5) $grid;
        margin-left: -($grid);
        margin-right: -($grid);
        border-bottom: 1px solid $color--white;
        letter-spacing: 1px;
        color: $color--white;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.7);
        text-transform: uppercase;
        overflow: hidden;
        transition: opacity $default-transition-speed ease 0s;

        @include media-query(tablet) {
            margin-right: 0;
            margin-left: 0;
            margin-bottom: ($grid * 0.25);
            border-radius: $default-border-radius;
            border-bottom: 0;
            box-shadow: 0 0 10px $color--black;
        }

        &:hover {
            color: $color--white;

            #{$root}__secondary-icon {
                transform: scale(1.2);
                fill: $color--octonary;
            }

        }

    }

    &__secondary-icon {
        fill: $color--black;
        margin-right: ($grid * 0.25);
        transition: fill $default-transition-speed ease 0s,
                    transform $default-transition-speed ease 0s;
    }


}
