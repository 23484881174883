.teaser-block {
    $root: &;

    &__container {
        margin-bottom: 64px;
    }

    &__heading {
        @include font-size(s);
        display: none;
        text-transform: none;
        font-weight: bold;
        margin-bottom: ($grid);
    }

    &__content {
        display: flex;
        flex-direction: column;

        @include media-query(mobile) {
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: -($grid * 0.25);
            margin-right: -($grid * 0.25);
        }

        &--quick-links {
            .teaser {
                max-width: 100%;
                flex-basis: auto;
            }
        }
    }

    .home-block & {
        padding-left: ($grid);
        padding-right: ($grid);
        //
        // @include media-query(tablet) {
        //     padding-top: ($grid * 1.5);
        // }
    }

    .intranet-block & {
        padding-left: 0;
        padding-right: 0;

        #{$root}__heading {
            @include font-size(s);
            display: block;
            margin-bottom: ($grid * 0.75);
        }

        #{$root}__content {
            flex-direction: column;
        }
    }
}
