.home-block {
    position: relative;
    z-index: 1;
    margin-left: 0;
    margin-right: 0;
    flex-wrap: nowrap; // IE11 fix

    .waiting-times {
        // Hide all waiting times blocks first
        &:first-child {
            display: none;
        }
        &:nth-child(2) {
            display: block;
        }
        // Show first one on mobile
        @include media-query(laptop) {
            &:first-child {
                display: block;
            }
            &:nth-child(2) {
                display: none;
            }
        }
    }

    .teaser-block {

        .teaser {
            $root: &;
            width: 100%;
            margin-bottom: ($grid * 0.5);

            @include media-query(tablet) {
                margin-bottom: ($grid);
                flex-basis: 50%;
                min-width: 50%;
                max-width: 50%;
            }

            // Link
            &__container {
                display: flex;
                text-decoration: none;
                padding: 24px 32px;
                background-color: $color--white;
                border: 1px solid #d8dde0;
                border-bottom-width: 4px;
                &:hover {
                    .teaser__content {
                        .teaser__heading {
                            color: $color--nhs-dark-pink;
                            text-decoration: none;
                        }
                    }
                    border-color: #aeb7bd;
                }
                &:focus {
                    background-color: $color--white;
                    box-shadow: none;
                    color: inherit;
                    outline: none;

                    .teaser__content {
                        .teaser__heading {
                            background-color: $color--nhs-light-yellow;
                            box-shadow: 0 -2px $color--nhs-light-yellow, 0 4px #212b32;
                            color: #212b32;
                            outline: 4px solid rgba(0,0,0,0);
                            text-decoration: none;
                        }
                    }
                }
                &:active {
                    border-color: #aeb7bd;
                    bottom: -1px;
                }

                .teaser__heading {
                    display: flex;
                    font-size: 19px;
                    font-weight: 600;
                    transition: color $default-transition-speed ease 0s;
                    margin-bottom: 0px;
                    color: $color--link;
                    text-decoration: underline;
                    text-decoration-skip: ink;

                    &:hover {
                        color: $color--nhs-dark-pink;
                    }

                }
            }

            &__icon-container {
                display: none
            }

            .teaser-block & {
                flex-basis: 100%;
                min-width: 100%;
                margin-bottom: ($grid * 0.5);

                @include media-query(mobile) {
                    align-items: stretch;
                    padding-left: ($grid * 0.25);
                    padding-right: ($grid * 0.25);
                }

                @include media-query(laptop) {
                    flex-basis: 50%;
                    min-width: 50%;
                    max-width: 50%; // IE11 fix
                }
            }
        }

    }

}
