.current-status {
    order: 1;
    margin-bottom: ($grid);

    @include media-query(laptop) {
        padding-right: ($grid);
        order: unset;
    }

    &__container {
        background-color: $color--senary;
        padding: ($grid);
    }

}
