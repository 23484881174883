table {
    @include font-size(xs);
    margin: 0;
    margin-bottom: ($grid * 2);
    min-width: 300px; // adjust to your needs
    width: 100%;
    border-collapse: collapse;
    display: table;

    .form-item {
        margin-bottom: 0;
    }

    .form-no-label {
        label {
            @include font-size(s);
            position: static;
            clip: none;
            font-weight: bold;

            @include media-query(tablet) {
                position: absolute;
                clip: rect(1px, 1px, 1px, 1px);
            }

        }
    }

    tbody {
        width: 100%;
    }

    tr {
        width: 100%;
        border-top: 1px solid $color--border;
        border-bottom: 1px solid $color--border;
    }

    th {
        @include font-size(s);
    }

    td {
        @include font-size(xs);
    }

    th,
    td {
        text-align: left;
        padding: ($grid * 0.5) 0;

        @include media-query(tablet) {
            padding: ($grid * 0.25) ($grid * 0.5);

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }
}

.table-block {
    overflow-x: scroll;

    @include media-query(tablet) {
        overflow: auto;
    }
}
