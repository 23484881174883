.cta {
    border-top: 1px solid $color--border;
    text-align: center;

    &__container {
        padding-top: ($grid * 1.5);
        padding-bottom: ($grid * 1.5);
        padding-left: ($grid);
        padding-right: ($grid);

        @include media-query(tablet) {
            padding-top: ($grid * 3);
            padding-bottom: ($grid * 3);
        }

    }

    &__heading {
        color: $color--black;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 500;
        line-height: 1.2;
    }

    &__actions-item {
        margin-bottom: ($grid * 0.5);

        @include media-query(tablet) {
            margin-bottom: 0;
        }

    }

}
