.charity {
    .charity-page-header {
        &__container {
            @include site-content-container;
            padding: 0 30px 30px 30px;
            @include media-query(mobile) {
                padding: 80px ($grid * 2) 40px ($grid * 2);
            }
        }
        &__heading {
            color: $color--charity-pink-light;
            margin-bottom: 0;
        }
        &--news-page {
            margin-bottom: 0;
            .charity-page-header__container {
                position: relative;
                padding-bottom: 0;
            }

        }
    }
}
