.resource-download {

    &__btn {
        display: flex;
        align-items: center;
    }

    &__content {
        font-weight: 700;
        width: auto;
    }

    &__icon {
        fill: $color--public-interact;
        width: ($grid * 1.5);
        height: ($grid * 1.5);
        margin-right: ($grid * 0.5);

        .intranet & {
            fill: $color--intranet-interact;
        }

    }

}
