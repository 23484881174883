/*------------------------------------*\
    $Columns
\*------------------------------------*/

.col {

    &__container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @include media-query(laptop) {
            flex-direction: row;
            margin-left: -($grid * 0.5);
            margin-right: -($grid * 0.5);
        }

    }

    &__article {
        position: relative;
    }

    &__full-width {
        flex-basis: 100%;
        min-width: 100%;
        max-width: 100%; // IE11
    }

    &__three-quarters {
        position: relative;
        flex-basis: 100%;
        min-width: 100%;
        max-width: 100%; // IE11

        @include media-query(laptop) {
            flex-basis: 75%;
            min-width: 75%;
            max-width: 75%; // IE11
            padding-left: ($grid * 0.5);
            padding-right: ($grid * 0.5);
        }

        &--overlap {
            position: relative;
            z-index: 2;
            margin-top: -($grid * 3);
        }

        &--text {
            margin-top: -($grid * 4);
            margin-left: -($grid * 0.5);
            margin-right: ($grid * 0.5);
            padding: ($grid);
            background-color: $color--white;
        }

        .home-block & {
            background-color: $color-white;
        }

        .intranet-block & {
            order: 2;

            @include media-query(laptop) {
                order: unset;
            }

        }

    }

    &__quarter {
        position: relative;
        flex-basis: 100%;
        min-width: 100%;
        max-width: 100%; // IE11

        @include media-query(laptop) {
            flex-basis: 25%;
            min-width: 25%;
            max-width: 25%; // IE11
            padding-left: ($grid * 0.5);
            padding-right: ($grid * 0.5);

            .home-block & {
                padding-left: 0;
            }

        }

    }

    &__thirds {
        position: relative;
        flex-basis: 100%;
        min-width: 100%;
        max-width: 100%; // IE11

        @include media-query(laptop) {
            flex-basis: 33.333%;
            min-width: 33.333%;
            max-width: 33.333%; // IE11
            padding-left: ($grid * 0.5);
            padding-right: ($grid * 0.5);
        }
    }

    &__halves {
        position: relative;
        flex-basis: 100%;
        min-width: 100%;
        max-width: 100%; // IE11

        @include media-query(laptop) {
            flex-basis: 50%;
            min-width: 50%;
            max-width: 50%; // IE11

            &:nth-child(odd) {
                padding-left: ($grid * 0.5);
                padding-right: 20px;
            }
            &:nth-child(even) {
                padding-left: 20px;
                padding-right: ($grid * 0.5);
            }
        }

    }
}
