// Color variable overrides
$plyr-color-main: #0072ce;
$plyr-audio-controls-bg: #425563;
$plyr-audio-control-bg-hover: darken($plyr-audio-controls-bg, 10%);
@import "../../../node_modules/plyr/src/sass/plyr";

// Custom Styles
.video-block {
  &__container {
    margin-bottom: 35px;
    background-color: $color--secondary;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    margin: 10px 10px;
    font-weight: 500;
  }
}

.plyr {
  &--audio {
    .plyr__controls {
      color: white;
      background: #425563 !important;
    }

    .plyr__time {
      color: white;
    }
  }

  &__tab-focus {
    margin-left: 15px !important;
  }

  input[type="range"] {
    color: white !important;
    max-width: none;
  }
}
