/*------------------------------------*\
    $SECTION
\*------------------------------------*/

.section {
    padding: 50px 0;

    &--blue {
        background-color: #e6f5f8;
    }
}
