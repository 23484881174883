/*------------------------------------*\
    $VARIABLES
\*------------------------------------*/

/* ============================================
   Colours
*/
$color--charity-grey:           #3c3b3b;
$color--charity-grey-light:     #f2f6f9;
$color--charity-text:           $color--charity-grey;
$color--charity-accent:         #f5a000;
$color--charity-bg:             #f7f8f9;
$color--charity-twitter-block:  #ededed;
$color--charity-purple-bg:      #af0e65;
$color--charity-purple-dark:    #810050;
$color--charity-purple-light:   #94005e;
$color--charity-pink-dark:      #98005f;
$color--charity-pink-light:     #be0077;
$color--charity-blue:           #005eb8;
$color--charity-blue-dark:      #004590;

$color--charity-heading:        $color--charity-pink-light;
$color--charity-link:           $color--charity-blue-dark;
$color--charity-link-hover:     $color--charity-blue;


/* ============================================
   Typography
   :: Default to system font stack
*/
$font--charity-primary: 'Urbane', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
$font-charity-sizes: (
    // standard sizes
    xxl:   68px,
    xl:    48px,
    l:     28px,
    m:     24px,
    s:     20px,
    xs:    16px,
    xxs:   14px,
    xxxs:  12px,
    xxxxs:  11px,
    xxxxxs:  9px
);

/* ============================================
   Borders
*/
$charity-border-radius: 5px;