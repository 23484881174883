.charity {
    .pagination {
        margin: 60px auto;

        .step-links {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include charity-font-size(s);

            @include media-query(mobile) {
                flex-direction: row;
            }

            .hide {
                visibility: hidden;
            }

            .prev-page, .page, .next-page {
                color: $color--charity-link;
                font-weight: 500;

                &:hover {
                    color: $color--charity-link-hover;
                }
            }

            .current {
                font-weight: 700;
            }

            .current, .page {
                margin: 0 20px;
                padding: 0;
            }

        }
    }
}
