.menu-mobile {
    $root: &;
    position: fixed;
    z-index: 21; // more than header
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;

    @include media-query(laptop) {
        display: none;
    }

    &__container {
        display: flex;
        flex-direction: row;
    }

    &__item {
        display: flex;
        flex-grow: 1;
        flex-basis: 25%;
        max-width: 25%;
        margin-bottom: 0;

        &:last-child {

            #{$root}__link {
                border-right: 0;
            }

        }

        &--active,
        &.active {

            #{$root}__link {
                background-color: $color--link;

                .intranet & {
                    background-color: $color--intranet-interact;
                }

            }

            #{$root}__label {
                color: $color--white;
            }

            #{$root}__icon {
                opacity: 1;
            }

        }

    }

    &__link {
        flex-basis: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: $color--link-hover;
        border-right: 1px solid rgba($color--white, 0.4);
        padding: 5px 0 ($grid * 0.25);
        text-decoration: none;
        transition: background-color $default-transition-speed ease 0s;

        &:hover {

            #{$root}__icon {
                transform: scale(1.2);
                opacity: 1;
            }

        }

        .intranet & {
            background-color: darken($color--senary, 5%);
        }

    }

    &__icon {
        fill: $color--white;
        opacity: 0.7;
        margin-bottom: ($grid * 0.25);
        margin-top: 5px;
        width: ($grid * 0.75);
        height: ($grid * 0.75);
        // sass-lint:disable indentation
        transition: transform $default-transition-speed ease 0s,
                    opacity $default-transition-speed ease 0s;
        // sass-lint:enddisable
    }

    &__label {
        @include font-size(xxxxxs);
        font-weight: 500;
        text-transform: uppercase;
        color: $color--white;
        line-height: 1.2;

        @include media-query(tablet) {
            @include font-size(xxxs);
        }

    }

    // Expanded menu
    &__extras {
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-bottom: 75px; // Height of mobile menu
        opacity: 0;
        overflow-y: auto;
        background-color: $color--senary;
        transform: translate3d(0, 100%, 0);
        // sass-lint:disable indentation
        transition: transform $default-transition-speed ease 0s,
                    opacity $default-transition-speed ease 0s;
        // sass-lint:enddisable

        &.active {
            opacity: 1;
            bottom: 75px; // Height of mobile menu
            transform: translate3d(0, 0%, 0);

            @include media-query(tablet) {
                bottom: 85px;
            }

        }

    }

    &__extras-container {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        width: 100%;
        overflow-y: auto;
        padding: ($grid * 1.5) ($grid);

        @include media-query(tablet) {
            padding: ($grid * 2);
        }

    }

    &__secondary {
        border-top: 1px solid rgba($color--white, 0.2);
        margin-bottom: ($grid);

        @include media-query(tablet) {
            border-top: 2px solid rgba($color--white, 0.2);
            margin-bottom: ($grid * 2);
        }

    }

    &__secondary-link {
        @include font-size(xs);
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        border-bottom: 1px solid rgba($color--white, 0.2);
        padding: 5px ($grid * 0.5);

        @include media-query(tablet) {
            @include font-size(s);
            border-bottom: 2px solid rgba($color--white, 0.2);
        }

         &:hover {

            #{$root}__secondary-label,
            #{$root}__tertiary-label {
                color: $color--octonary;
            }

        }
    }

    &__secondary-label {
        color: $color--white;
    }

    &__secondary-icon {
        fill: $color--secondary;
    }

    &__search-btn {

        .intranet & {
            // sass-lint:disable no-important
            background-color: $color--octonary !important;
            // sass-lint:enddisable
        }

    }


}
