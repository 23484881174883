.count {
    position: relative;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color--octonary;
    border-radius: 50%;
    width: ($grid * 1.5);
    height: ($grid * 1.5);
    min-width: ($grid * 1.5);
    margin-right: ($grid);

    &__content {
        font-weight: 700;
        color: $color--white;
    }

}
