/*------------------------------------*\
    $MIXINS
\*------------------------------------*/


/* ============================================
    Site content in the middle 1280px 
*/
@mixin site-content-container {
    max-width: 1100px;
    margin: 0 auto;
}

/* ============================================
    Font sizes
*/
@mixin charity-font-size($keyword) {
    $size: map-get($font-charity-sizes, $keyword);

    @if $size == null {
        @warn 'Font size ‘#{$keyword}’ does not exist';
    } @else {
        @include rem-font-size($size);
    }
}