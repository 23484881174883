/*------------------------------------*\
    $PATTERNLAB 
    Override styles for styleguide
\*------------------------------------*/

// sass-lint:disable-all

// Welcome area
.welcome-demo {
    margin-top: 20px;

    h2 {
        color: $color--primary;
    }

    p {
        margin-top: 0;
    }
}

#viewall-atoms-welcome {
    display: none;
}

#atoms-welcome {
    padding: 30px;
    margin: 0 -0.5em 20px;
    background-color: #f7f7f7;

    .sg-pattern-head {
        display: none;
    }

    .sg-pattern-extra {
        border: 0;
    }
}

#atoms-buttons {
    .sg-pattern-example {
        max-width: 1100px;
    }

    .button {
        margin: 0 4px 12px;
        min-width: 200px;
    }
}

.sg-pattern-example {
    .site-header {
        position: static;
    }
}
