.social-block {
    flex-basis: 100%;
    min-width: 100%;
    order: 3;

    &--td-standard {
        padding-right: 29px;
        padding-left: 29px;

        @include media-query(laptop) {
            padding-right: 15px;
            padding-left: 15px;
        }

        @include media-query(desktop) {
            padding-right: 29px;
            padding-left: 29px;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;

        @include media-query(laptop) {
            flex-direction: row;
        }

        // Talent Dev
        &--td {
            flex-wrap: wrap;
            margin-top: 50px;
        }

    }

}
