.key {
    $root: &;
    margin-bottom: ($grid * 2);

    // When stuck
    div.supporting-info.js-sticky.stuck > div {
        @include media-query(tablet-max) {
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    .supporting-info.stuck & {
        display: flex;
        margin-bottom: 0;

        #{$root}__container {
            flex-direction: row;
            align-items: center;
            @include media-query(tablet-max) {
                flex-direction: column;
                align-items: flex-start;
                gap: 0.25rem;
            }
        }

        #{$root}__header {
            display: none;
        }

        #{$root}__item {
            margin-right: ($grid * 0.5);
            margin-bottom: 0;

            @include media-query(desktop) {
                margin-right: ($grid);
            }
        }

        #{$root}__item-heading {
            color: $color--white;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__heading {
        @include font-size(s);
    }

    &__item {
        display: flex;
        align-items: center;
        margin-bottom: ($grid * 0.25);

        &--low {
            #{$root}__item-icon-container {
                background-color: $color--primary;
            }
        }
    }

    &__item-icon-container {
        width: ($grid);
        height: ($grid);
        min-width: ($grid);
        min-height: ($grid);
        justify-content: center;
        align-items: center;
        display: flex;
    }

    &__item-header {
        padding-left: ($grid * 0.25);
    }

    &__item-heading {
        @include font-size(xxxs);
        font-weight: 500;
        margin-bottom: 0;
    }
}
