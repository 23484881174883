/*------------------------------------*\
    $BUTTONS
\*------------------------------------*/

.btn {
    @include font-size(s);
    display: inline-block;
    padding: ($grid * 0.5) ($grid);
    overflow: hidden;
    font-weight: 500;
    color: $color--black;
    text-align: center;
    vertical-align: top;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid $color--border;
    // sass-lint:disable indentation
    transition: background-color $default-transition-speed ease 0s,
                border-color $default-transition-speed ease 0s;
    // sass-lint:enddisable

    &:focus {
        outline: 0;
    }

    //Colours
    @each $i, $j in $button-colours {
        &--#{$i} {
            color: (map-get($j, 'color'));
            border-color: (map-get($j, 'border'));
            background-color: (map-get($j, 'bg'));

            &:hover {
                background-color: darken((map-get($j, 'bg')), 15%);
                border-color: darken((map-get($j, 'border')), 15%);
                color: (map-get($j, 'hover-color'));
            }

        }

    }

    &--primary {

        &:hover {
            background-color: darken($color--senary, 15%);
            border-color: darken($color--senary, 15%);
        }

    }

    &--transparent {
        @include font-size(xs);
        background-color: transparent;
        color: $color--link;
        border: 0;
        padding: 0;

        &:hover {
            color: $color--link-hover;
        }

    }

    &--icon-only {
        background-color: transparent;
        border: 0;
        padding: 0;
        cursor: pointer;

        &:hover {

            svg {
                fill: $color--black;
            }
        }

        svg {
            fill: $color--octonary;
        }

    }

    &__meta {
        @include font-size(xxs);
        font-weight: 300;
        text-align: left;
        display: block;
    }

}