.profile {

    &__body {
        order: 2;

        @include media-query(laptop) {
            order: 1;
        }
    }

    &__overview {
        order: 1;
        margin-bottom: ($grid);

        @include media-query(laptop) {
            order: 2;
        }

    }

    &__overview-container {
        background-color: $color--secondary;
        overflow: hidden;
    }

    &__overview-item {
        @include font-size(xxs);
        line-height: 1.4;
        padding: 0 ($grid * 0.5);
        color: $color--black;
    }

    &__item-heading {
        @include font-size(xxs);
        color: $color--quinary;
        font-weight: 700;
        margin-bottom: 5px;
    }

    &__picture {
        width: 100%;
        margin-bottom: ($grid);
        max-width: ($grid * 10);
    }

    &__picture-item {
        display: block;
        width: 100%;
        height: auto;
    }

    &__contact-details {
        display: flex;
        align-items: center;
        font-weight: 700;
        margin-bottom: ($grid);
    }

    &__contact-icon {
        fill: $color--quinary;
        margin-right: ($grid * 0.25);
        width: 24px;
        height: 24px;
    }

    &__contact-number {
        margin-bottom: 0;
    }


}
