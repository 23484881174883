.cookie {
    @include font-size(xs);
    display: none; // Doesn't display unless there isn't a cookie
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 5px solid rgba($color--white, 0.2);
    transition: transform $default-transition-speed ease 0s;

    // Applied if cookie just added
    &.inactive {
        display: block;
        transform: translateY(100%);
    }

    &.active {
        display: block;
        transform: translateY(0);
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: ($grid * 0.5) ($grid);
        color: $color--white;
        background-color: rgba($color--quinary, 0.95);
    }

    &__message {

        p {
            margin-bottom: 0;
        }

    }

    &__link {
        color: $color--white;

        &:hover {
            color: $color--black;
        }

    }

    &__btn {
        @include font-size(xs);
        padding: 5px ($grid * 0.5);
    }

}
