.color-strip {

    &--primary {
        background-color: $color--primary;
    }

    &--secondary {
        background-color: $color--secondary;
    }

    &--tertiary {
        background-color: $color--tertiary;
    }

    &--octonary {
        background-color: darken($color--octonary, 5%);
    }

    &--quinary {
        background-color: $color--quinary;
    }

    &--senary {
        background-color: $color--senary;

        .intranet & {
            background-color: $color--septenary;
        }

    }

    &--septenary {
        background-color: $color--septenary;
    }

}
