.listing {
    $root: &;
    margin-bottom: ($grid * 2);

    &--person {

        #{$root}__container {
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: ($grid * 0.5);
            border-bottom: 0;
        }

        #{$root}__item {

            @include media-query(desktop) {
                flex-basis: 50%;
                min-width: 50%;
                // max-width: 438px;
                max-width: 50%; // IE11
                padding-right: ($grid * 0.25);
                padding-left: ($grid * 0.25);

            }

        }

        #{$root}__image-container {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            background-color: $color--white;
            border-radius: 50%;
            margin-right: ($grid * 0.5);
            height: 50px;
            width: 61px;

            @include media-query(desktop) {
                height: 70px;
                width: 90px;
            }
        }

        #{$root}__text-container {
            flex-wrap: wrap;
        }

        #{$root}__meta-item {
            border-color: $color--white;
            margin-right: ($grid * 0.25);
            padding-right: ($grid * 0.25);
        }

    }

    &__container {
        display: flex;
        flex-direction: column;
        max-width: $max-width;
        margin-left: 0;
        margin-right: auto;

        @include media-query(desktop) {
            margin-left: -($grid * 0.25);
            margin-right: -($grid * 0.25);
        }

        .template-searchresults & {
            display: block;
        }

    }

    &__item {
        flex-basis: 100%;
        min-width: 100%;
        max-width: 100%; // IE11
        margin-bottom: ($grid * 0.5);

        @include ie11 {
            min-height: 100px;
        }

        @include media-query(desktop) {
            padding-right: ($grid * 0.25);
            padding-left: ($grid * 0.25);
        }

    }

    &__image-container {
        margin-right: ($grid);
        transition: transform $default-transition-speed ease 0s;

        &--right {
            margin-left: ($grid);
            margin-right: 0;
            transition: transform 300ms ease 0s;
        }

        .template-searchresults & {
            @include media-query(desktop) {
                flex-basis: 25%;
                min-width: 25%;
                max-width: 25%;
            }
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: auto;
    }

    &__author-no-image {
        fill: darken($color--nhs-dark-pink, 0%);

        .intranet & {
            fill: $color--intranet-interact;
        }

    }

    &__text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;

        .latest & {
            flex-basis: 65%;
            min-width: 65%;
            max-width: 65%; // IE11
        }

    }

    &__text,
    &__meta {
        @include font-size(xxs);
        color: $color--default;
        margin-bottom: ($grid * 0.5);
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }

    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: ($grid * 0.25);
    }

    &__heading {
        @include font-size(xs);
        font-weight: 700;
        color: $color--link;
        margin-bottom: 0;
        text-decoration: underline;
        text-decoration-skip: ink;
        transition: color $default-transition-speed ease 0s;

        .latest & {
            font-weight: 500;
        }

    }

    &__meta {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: $color--octonary;
        margin-bottom: ($grid * 0.25);

        @include media-query(desktop) {
            align-items: center;
            flex-direction: row;
        }

        &--featured {
            @include font-size(xxs);
            margin-bottom: 10px;

            #{$root}__meta-label {
                font-weight: 500;
            }

        }

    }

    &__meta-item {
        display: flex;
        align-items: center;
        color: $color--meta;
        margin-right: ($grid * 0.5);
        padding-right: ($grid * 0.5);
        border-right: 1px dotted $color--border-dark;
        margin-bottom: ($grid * 0.25);
        line-height: 1;

        @include media-query(desktop) {
            margin-bottom: 0;
        }

        &:last-child {
            border-right: 0;
        }

    }

    &__meta-label {
        font-weight: 500;
        margin-right: ($grid * 0.25);
    }


    &__meta-description {
        color: $color--default;
    }

    &__icon {
        flex-basis: 28px;
        min-width: 28px;
        fill: $color--link;
        width: ($grid * 0.75);
        height: ($grid * 0.75);
        margin-right: 5px;
        transition: fill $default-transition-speed ease 0s;
    }

    &__link {
        display: flex;
        text-decoration: none;
        align-items: center;
        justify-content: flex-start;
        padding: ($grid) ($grid * 0.75);
        padding-bottom: 24px;
        border: 2px solid transparent;
        border-radius: $default-border-radius;
        background-color: $color--card;
        transition: border-color $default-transition-speed ease 0s;

        &:hover {
            border-color: $color--public-interact;

            #{$root}__heading {
                color: $color--link-hover;
            }

            #{$root}__icon {
                fill: $color--link-hover;
            }

            #{$root}__image-container {
                transform: scale(1.05);
            }

            .intranet & {
                border-color: $color--intranet-interact;
            }

        }

    }

    // Layout options

    &__layout-grid {

        #{$root}__item {

            @include media-query(desktop) {
                flex-basis: 50%;
                min-width: 50%;
                max-width: 438px;
            }

        }

    }

    &__layout-list {

        #{$root}__item {

            @include media-query(desktop) {
                flex-basis: 100%;
                min-width: 100%;
                max-width: 100%;
            }

        }

    }



}
