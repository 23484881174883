.related {
    $root: &;
    margin-top: ($grid * 2);
    margin-bottom: ($grid);

    @include media-query(laptop) {
        margin-bottom: 50px;
    }

    &__header {
        border-top: 6px solid $color--border;
        padding-top: ($grid * 0.5);
    }

    &__heading {
        @include font-size(xs);
        text-transform: none;
        font-weight: bold;
        margin-bottom: ($grid);
    }

    &__link {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: ($grid * 0.5) 0;

        &:hover {

            #{$root}__item-heading {
                color: $color--link-hover;
            }

        }

    }

    &__item {
        margin-bottom: 0;
        border-bottom: 1px dotted $color--border;

        &:last-child {
            margin-bottom: 0;
        }

    }

    &__image-container {
        flex-basis: 50%;
        margin-right: ($grid * 0.5);
    }

    &__image {
        width: 100%;
        height: auto;
    }

    &__item-heading {
        @include font-size(xxs);
        font-weight: 500;
        margin-bottom: 0;
        color: $color--link;
        transition: color $default-transition-speed ease 0s;
    }

}
