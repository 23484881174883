.breadcrumb {
    $root: &;
    display: flex;
    flex-direction: column;
    margin-bottom: ($grid);

    @include media-query(tablet) {
        flex-direction: row;
        margin-bottom: ($grid * 0.5);
    }

    @include media-query(laptop) {
        margin-bottom: ($grid);
    }


    &__item {
        @include font-size(xxs);
        margin-right: ($grid * 0.25);
        color: $color--black;
        // color: hotpink;

        // breadcrumb label
        &:first-child {
            display: none;
            color: $color--default;

            @include media-query(tablet) {
                display: inline;
            }

        }
    }

    &__link {
        display: inline-block;
        text-decoration: none;
        padding-right: 5px;
    }

    &__divide {
        color: $color--border;
    }

    .page-header & {
        margin-bottom: ($grid * 0.25);
        color: $color--black;

        #{$root}__item {
            color: $color--white;

        }

        #{$root}__link {
            color: $color--white;
            text-decoration: underline;

            .theme-children & {
                color: $color--white;

                &:hover {
                    color: $color--white;
                }              
            }

            &:hover {
                color: $color--white;
                text-decoration: none;
            }
            &:focus {
                color: $color--black;
            }

        }

    }

    .hero & {
        margin-bottom: ($grid * 0.5);

        #{$root}__item {
            color: $color--white;
            text-shadow: 0 0 20px $color--black;

            &:last-child {
                display: none;
            }

        }

        #{$root}__link {
            color: $color--white;

            &:hover {
                color: $color--octonary;
            }

        }

    }

    .col__three-quarters & {
        font-weight: 400;
    }

    .theme-children & {
        position: relative;
        z-index: 2;

        #{$root}__item {

            &:last-child {
                display: none;
            }

        }

    }

}
