/*------------------------------------*\
        $LINKS
\*------------------------------------*/

a {
    color: $color--link;
    text-decoration: underline;
    text-decoration-skip-ink: auto;
    transition: color ease $default-transition-speed;

    &:hover {
        color: $color--link-hover;
    }

    &:visited {
        color: $color--nhs-purple;
    }

}
