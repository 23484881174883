@import "../../../node_modules/nhsuk-frontend/packages/components/action-link/action-link";

.nhsuk-action-link__link {
  // Removes transition inherited from _links.scss which can cause
  // flickering when hovering over a visited action-link
  transition: none;

  &:hover {
    .nhsuk-action-link__text {
      color: $color--link-hover;
    }
  }
}

.template-campaign-landing-page .nhsuk-action-link {
  display: block;
  width: 100%;
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
}
