.at-a-glance {
    margin-bottom: ($grid);
    margin-left: -($grid);

    &__container {
        padding: ($grid) ($grid) ($grid * 1.5);
        color: $color--white;

        p:last-of-type {
            margin-bottom: 0;
        }

    }

    &__heading {
        @include font-size(s);
        text-transform: uppercase;
        color: $color--white;
        margin-bottom: ($grid * 0.5);
    }

}
