.quote {
    margin: ($grid * 2) 0;

    .template-campaign-landing-page & {
        max-width: 920px;
        margin-left: auto;
        margin-right: auto;
    }

    &__item {
        @include font-size(m);
        font-weight: 700;
        font-style: normal;
        line-height: 1.4;
        color: $color--black;
        padding-top: $grid;
        margin-bottom: ($grid * 0.5);
        border-top: 7px solid $color--quinary;
    }

    &__author {
        @include font-size(xxs);
        font-weight: 300;
        font-style: normal;
        color: $color--quinary;
        text-transform: uppercase;
    }

}
