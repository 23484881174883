@mixin cross-background {
    content: "";
    background-image: url('../images/charity-pink-cross.svg');
    background-repeat: no-repeat;
    position: absolute;
    width: 85%;
    bottom: 0;
    right: 0;
    z-index: 1;
    opacity: 0.5;

    background-size: 100% 27px;
    background-position-x: 8px;
    height: 27px;

    @include media-query(mobile) {
        width: 444px;
    }

    @include media-query(laptop) {
        width: 85%;
    }

}

.crucible-lottery-results {
    margin-bottom: 30px;

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @include media-query(laptop) {
        flex-direction: row;
    }

    h3 {
        font-size: 1.5rem;
    }

    &__winners {
        background-color: $color--charity-pink-light;

        &.col__halves {
            padding: 30px 30px 40px;

            @include media-query(laptop) {
                padding: 30px 40px 40px;
            }
        }

        p, h3 {
            color: #fff;
        }

        table {
            color: #fff;
            width: auto;
            margin: 0;
            min-width: 100%;

            th, td {
                width: 50%;
                padding-right: 10px;
            }
        }
    }

    &__jackpot {
        background-color: $color--charity-grey-light;
        min-height: 200px;

        &::before {
            @include cross-background;
            background-position-y: 10px;
        }

        &::after {
            @include cross-background;
            bottom: 27px;
        }

        &.col__halves {
            padding: 20px 20px 40px;

            @include media-query(laptop) {
                padding: 30px 40px 40px;
            }
        }

        h3, &__ticket {
            color: $color--charity-pink-light;
            margin: 0;
            text-align: right;
        }

        &__ticket {
            font-weight: 100;
            @include charity-font-size(xxl);
            line-height: 5.5rem;
        }
    }
}