/*------------------------------------*\
        $LINKS
\*------------------------------------*/

.charity {
    a {
        transition: color ease $default-transition-speed;

        &:hover {
            color: $color--charity-link-hover;
            text-decoration: none;
        }
    }

    .quick-links__link {
        color: $color--charity-link;

        &:hover {
            color: $color--charity-link-hover;
            text-decoration: none;

            .quick-links__label {
                color: $color--charity-link-hover;
            }
        }
    }

    .rich-text {
        a {
            color: $color--charity-link;

            &:hover {
                color: $color--charity-link-hover;
                text-decoration: none;
            }
        }
    }
}
