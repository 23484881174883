/*------------------------------------*\
    $SITE HEADER
\*------------------------------------*/

.site-header {
    $root: &;
    background-color: $color--primary;
    position: relative;
    z-index: 4;

    // Children elements
    &__container {
        box-shadow: 0;
        display: flex;
        flex-direction: column;
    }

    &__top-container {
        border-bottom: 1px solid #ffffff33
    }

    &__main {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__logo {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-grow: 1;
        padding-top: ($grid);
        padding-left: ($grid * 0.5);
        text-decoration: none;
        color: #fff;

        @include media-query(mobile) {
            display: flex;
            padding-left: ($grid * 1.5);
            padding-bottom: $grid;
            padding-right: ($grid * 0.5);
        }

        &:visited {
            color: #fff;
        }
        &:focus {
            color: #212b32;
        }
        &:hover {
            color: #fff;
            text-decoration: underline solid #fff;
        }

        span {
            @include hidden;
        }

        .logo-organisation-name {
            margin-top: 4px;
        }
    }

    &.nhsuk-header--white {
        .site-header__logo {
            padding-right: ($grid * 1.5);
            margin-left: 0;
        }
    }

    &__navigation {
        display: flex;
        flex-grow: 1;

        .site-header--formulary & {
            padding-top: calc($grid / 2);
        }
    }

    &__navigation-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;

        .intranet & {
            justify-content: flex-end;
        }
    }

    &__mobile-navigation {
        display: inline-flex;
        justify-content: space-between;
        padding: ($grid * 0.5) $grid;

        @include media-query(tablet) {
            display: none;
        }

        li {
            list-style-type: none;
            padding-right: ($grid * 0.5);
        }

        & .nhsuk-header__menu {
            display: flex;
            float: none;
            white-space: nowrap;
        }

        & .nhsuk-header__mobile-navigation-links {
            display: grid;
            flex-wrap: wrap;
            overflow: hidden;
            white-space: nowrap;
            max-height: 30px;
            max-width: 80%;
        }

        & .nhsuk-header__mobile-navigation-link {
            color: $color--white;
            padding-right: 32px;
            text-decoration: underline;
            &:focus {
                color: #fff;
            }
        }
        
        & .nhsuk-header__mobile-navigation-link-item {
            color: $color--white;
            text-decoration: underline;
            &:focus {
                color: #fff;
            }
        }

        & .nhsuk-header__menu-toggle {
            all: unset;
            color: $color--white;
            text-decoration: underline;
            cursor: pointer;
            &:focus {
                color: #fff;
            }
        }
    }

    &__mobile-navigation-menu {
        position: absolute;
        top: 100%;
        width: 100%;
        background: #fff;
        z-index: 1;

        @include media-query(tablet) {
            display: none;
        }

        & .nhsuk-header__navigation {
            position: relative;

            .secondary-menu {
                position: absolute;
                z-index: 100;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                overflow-y: auto;
                background-color: #ffffff;
                transform: translate3d(100%, 0, 0);
                // sass-lint:disable indentation
                transition: transform $default-transition-speed ease 0s,
                    opacity $default-transition-speed ease 0s;
                // sass-lint:enddisable

                &.active {
                    opacity: 1;
                    transform: translate3d(0, 0%, 0);
                }

                .nhsuk-header__navigation-back {
                    background-color: transparent;
                    border: 0;
                    cursor: pointer;
                    height: 40px;
                    padding: 0;
                    width: 40px;
                    overflow: hidden;
                    position: absolute;
                    left: 8px;
                    top: 8px;
                    white-space: nowrap;
                }

                .nhsuk-header__navigation-title #label-navigation,
                #label-navigation-back {
                    position: relative;
                    left: 30px;
                }
            }
        }
    }

    &__logo-search-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;

        @include media-query(mobile) {
            flex-direction: row;
        }
    }

    &__search {
        width: 100%;
        display: flex;
        justify-content: center;
        @include media-query(mobile) {
            justify-content: flex-end;
        }
        .nhsuk-button {
            margin-bottom: 0;
        }

        .search__icon {
            fill: #fff;
        }

        &.condensed {

            margin-left: ($grid * .5);
            text-align: right;

            @include media-query(mobile) {
                width: 100%;
                margin-left: 0;
            }

            .search__input {
                border-radius: 4px;
                box-shadow: none;
                color: $color--default;
                border: 4px solid #fff;
                @include font-size(xs);

                @include media-query(mobile) {
                    border-radius: 4px 0 0 4px;
                    width: 200px;
                    min-width: auto;
                    position: relative;
                    z-index: 10;
                }

                @include media-query(tablet) {
                    width: 235px;
                }

                &:focus {
                    border: 4px solid #212b32;
                    box-shadow: 0 0 0 4px $color--nhs-light-yellow;
                }
            }

            .search__btn {
                top: ($grid * 0.75);
                background-color: $color--page-background;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border: 1px solid transparent;
                padding: 0 6px 0 8px;

                @include media-query(mobile) {
                    width: 45px;
                    position: relative;
                    top: auto;
                    right: auto;
                    float: right;
                    z-index: 5;
                }

                &:active,
                &:hover {
                    background-color: #003d78;
                    border: 1px solid #fff;
                    .search__icon {
                        fill: #fff;
                    }
                }

                &:focus {
                    background-color: $color--nhs-light-yellow;
                    box-shadow: 0 -2px $color--nhs-light-yellow, 0 4px #212b32;
                   .search__icon {
                        fill: #212b32;
                    }
                }

                &:focus-visible {
                    outline: none;
                }
            }

            .search__icon {
                transform: rotate(0deg);
                fill: $color--nhs-blue;
            }
        }
    }

    &__search-container {
        padding: ($grid * 0.75);
        padding-left: 0;
        position: relative;
        width: 100%;
        border-radius: 25px;
        padding-right: 0;

        @include media-query(mobile) {
            padding: ($grid);
            max-width: ($grid * 15);
            padding-right: 0;
        }

        .condensed & {
            padding-left: 0;
            margin-right: ($grid);

            @include media-query(mobile) {
                margin-right: ($grid * 1.5);
            }
        }
    }

    &__search-suggestions {
        display: none;
        background-color: $color--white;
        box-shadow: 0 0 ($grid * 0.5) 0 rgba($color--black, 0.25);
        border-radius: $default-border-radius;
        margin-top: 2px;
        padding: $grid * 0.5;
        text-align: left;

        @include media-query(tablet) {
            padding: $grid;
        }

        &--show {
            display: block;
            position: absolute;
            width: 100%;
            z-index: 2;
        }

        ul {
            padding-bottom: 12px;
            margin-bottom: ($grid * 0.25);
        }
    }

    &__search-suggestion {
        padding-bottom: 12px;
        margin-bottom: 7px;
        border-bottom: 1px solid #f0f4f5;
    }

    .condensed &__search-suggestion {
        padding: 16px 0;
        margin-bottom: 0;
    }

    &__search-suggestion-link {
        @include font-size(s);
        font-weight: 500;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    .condensed &__search-suggestion-link {
        font-size: 16px;
    }

    &__search-suggestion-sitename {
        @include font-size(xxs);
        font-weight: 500;
        font-size: 1rem;
        color: #4c6272;
        margin-left: 10px;
        text-decoration: none;
    }

    &__search-suggestion-header {
        @include font-size(xs);
        display: inline-block;
        font-weight: 700;
        margin-bottom: $grid * 0.5;
        position: relative;

        @include media-query(tablet) {
            @include font-size(s);
        }
    }

    // intranet specific
    .intranet & {
        background-color: $color--white;
        #{$root}__main {
            position: relative;
            display: flex;
            flex-direction: row;
        }

        #{$root}__logo-search-container {
            width: 200px;
        }

        #{$root}__navigation {
            display: none;
            flex-grow: 1;
    
            @include media-query(tablet) {
                display: flex;
            }
    
            .site-header--formulary & {
                padding-top: calc($grid / 2);
            }
        }

        & .nhsuk-header__menu {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 28px;

            @include media-query(tablet) {
               display: none;
            }

            button {
                right: 0px;
            }
        }

        .search__input {
            border-radius: 0;
            color: $color--default;

            &:focus {
                border: 1px solid $color--nhs-light-yellow;
            }
        }

        .search__btn {
            background-color: $color--nhs-dark-grey;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            top: 21px;

            @include media-query(mobile) {
                top: 28px;
            }

            &:hover {
                background-color: #384854;
            }
        }

        .search__icon {
            fill: #fff;
            transform: rotate(90deg);
        }

        &__container {
            margin: 0 0;
        }


        #{$root}__search {
            background-color: $color--octonary;
            // sass-lint:disable no-misspelled-properties
            backdrop-filter: blur(20px);
            // sass-lint:enddisable
            padding-left: (0.5 * $grid);
            padding-right: (0.5 * $grid);

            @include media-query(desktop) {
                padding-right: ($grid * 1.5);
                padding-left: ($grid * 1.5);
            }


            #{$root}__search-container {
                max-width: 100%;
                @include media-query(desktop) {
                    padding-right: 0;
                }
            }
        }

        &__search-container {
            padding-left: 0;

            @include media-query(mobile) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        #{$root}__logo {
            max-width: 200px;
            padding: 28px;
            @include media-query(desktop) {
                padding: 18px 42px;
            }
        }
    }

    &--formulary {
        background-color: $color--white;

        .search__btn {
            background-color: $color--page-background;
            top: 0;
            right: 0; 
        }

        .search__icon {
            fill: $color--nhs-blue;
        }

        .search__input {
            border-radius: 0;
        }

        #{$root}__search {
            flex-basis: 100%;
            justify-content: center;
            padding: ($grid * 0.75) ($grid * 0.5);
            background-color: $color--primary;

            @include media-query(desktop) {
                padding-right: ($grid * 1.5);
                padding-left: ($grid * 1.5);
            }

            #{$root}__search-container {
                max-width: 100%;
                padding: 0;
            }
        }

        #{$root}__main {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        #{$root}__logo-container {
            display: flex;
            align-items: center;
        }

        #{$root}__logo {
            width: auto;
            height: auto;
            max-width: none;
            min-width: 0;
            text-decoration: none;
            padding-bottom: ($grid);
        }

        #{$root}__navigation-container {
            align-items: flex-end;
        }
    }
}
