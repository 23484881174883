.autocomplete {
    $root: &;
    position: absolute;
    z-index: 2;
    width: 100%;
    max-height: 0;
    border-radius: 0 0 $default-border-radius $default-border-radius;

    &.active {
        height: 600px;
        max-height: 600px;
        width: 100%;
        // sass-lint:disable no-vendor-prefixes
        -webkit-mask-image: linear-gradient(to bottom, rgba($color--black, 1) 0%, rgba($color--black, 1) 100%);
        // sass-lint:enddisable
        mask-image: linear-gradient(to bottom, rgba($color--black, 1) 0%, rgba($color--black, 1) 100%);

        #{$root}__container {
            opacity: 1;
            pointer-events: auto;
            transform: translate3d(0, 0%, 0);
            // sass-lint:disable indentation
            transition: opacity $default-transition-speed ease 0s,
                        transform $default-transition-speed ease 0s;
            // sass-lint:enddisable
        }

    }

    &__container {
        display: flex;
        pointer-events: none;
        flex-direction: column;
        background-color: $color--white;
        overflow: hidden;
        box-shadow: 0 0 10px rgba($color--black, 0.4);
        opacity: 0;
        transform: translate3d(0, -100%, 0);
        // sass-lint:disable indentation
        transition: opacity $default-transition-speed ease 0s,
                    transform $default-transition-speed ease 1s;
        // sass-lint:enddisable
    }

    &__item {
        @include font-size(xs);
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $color--white;
        border-bottom: 1px dotted $color--border;
        padding: ($grid * 0.5);
        text-decoration: none;
        transition: background-color $default-transition-speed ease 0s;

        &:last-child {
            border-bottom: 0;
        }

        &:hover {
            background-color: $color--public-interact;
            color: $color--white;

            .intranet & {
                background-color: $color--intranet-interact;
                color: $color--white;
            }

            #{$root}__heading {
                color: $color--white;
            }

            #{$root}__meta {
                color: $color--off-white;
            }

        }

    }

    &__heading {
        @include font-size(xs);
        color: $color--link;
        font-weight: 500;
        margin-bottom: 0;
        flex-basis: auto;
        transition: color $default-transition-speed ease 0s;
    }

    &__image {
        display: block;
        width: 80px;
        height: 80px;
        margin-right: ($grid * 0.5);
    }

    &__meta {
        @include font-size(xs);
        color: $color--default;
        margin-bottom: 0;
        transition: color $default-transition-speed ease 0s;
    }

}
