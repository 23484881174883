.hero-search {
    $root: &;
    display: flex;
    align-items: flex-end;
    min-height: 350px;
    height: 60vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $color--primary;
    margin-bottom: ($grid);
    margin-top: 0;

    @include media-query(tablet) {
        height: 40vh;
        min-height: ($grid * 17);
        margin-bottom: ($grid * 1.5);
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin: 0;
        padding: ($grid * 0.75) ($grid) ($grid);
        width: 100%;
        background-color: rgba($color--primary, 0.95);

        @include media-query(tablet) {
            padding: $grid;
        }

        @include media-query(laptop) {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: $grid ($grid * 1.5);
        }
    }

    &__heading {
        @include font-size(s);
        font-weight: 300;
        text-align: left;
        margin-bottom: ($grid * 0.75);
        color: $color--white;

        @include media-query(tablet) {
            @include font-size(l);
        }

        @include media-query(laptop) {
            margin-bottom: 0;
            margin-right: ($grid);
        }

    }

    &__search {
        display: flex;
        flex-grow: 2;
        width: 100%;
        max-width: 918px; // Width of teaser block when 100%

        @include media-query(laptop) {
            width: auto;
        }
    }

    .intranet & {
        background-color: $color--octonary;

        #{$root}__container {
            background-color: $color--octonary;
        }

    }

}
