.related-files {
    $root: &;
    margin-bottom: ($grid * 2);

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: ($grid * 0.5);
    }

    &__heading {
        @include font-size(xs);
        text-transform: none;
        font-weight: 700;
        margin-bottom: 0;
    }

    &__item {
        border-bottom: 1px dotted $color--resource-border;
    }

    &__icon {
        fill: $color--senary;
        margin-right: ($grid * 0.25);
        transition: fill $default-transition-speed ease 0s;
    }

    &__link {
        display: block;
        padding: ($grid * 0.5) 0;
        font-weight: 500;
        text-decoration: none;

        &:hover {
            color: $color--octonary;

            #{$root}__label {
                color: $color--link-hover;
            }

        }

    }

    &__label {
        @include font-size(xs);
        text-decoration: underline;
        color: $color--link;
    }

    &__meta {
        @include font-size(xxs);
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $color--senary;
    }

    + .related-files {
        margin-bottom: ($grid);
    }

}
