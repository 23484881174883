.sponsors {

    &__content {
        display: flex;
        flex-direction: row;
    }

    &__item {
        height: 80px;
        padding-right: ($grid);

        img {
            max-height: 80px;
            width: auto;
            display: block;
            height: auto;
        }

    }

}
