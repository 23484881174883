.sticky-action {
    position: fixed;
    z-index: 5;
    right: 0;
    top: ($grid * 10);
    transform: rotate(-90deg) translate3d(0, 100%, 0);
    box-shadow: 0 0 10px rgba($color--black, 0.8);

    &__container {
        padding: ($grid * 0.5);
    }

    &__item {
        @include font-size(m);
        color: $color--white;
    }

}
